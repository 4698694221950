import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Button,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/token";
import { RiArrowDownSLine } from "react-icons/ri";
import { fetchExams } from "../../store/slices/exams/examSlice";
import { fetchGrammar } from "../../store/slices/grammar/grammarSlice";
import { createConversation } from "../../store/slices/interaction/createConversationSlice";
import { fetchConversations } from "../../store/slices/interaction/conversationSlice";
import MessageModal from "./messageModal";
// import { setSubscriptionFromApi } from "../../store/slices/subscription/selectors";
import { GoAlertFill } from "react-icons/go";
import getuserData from "./../../utils/userData";
import Loader from "../../common/loader";
import Cookies from "js-cookie";
import { errorToast } from "../../response/errorToast";
import { compile } from "sass";
import { fetchUserById } from "../../store/slices/users/userByIdSlice";
import { fetchComprehension } from "../../store/slices/comprehension/comprehensionSlice";

const AiSupport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();
  const [type, setType] = useState("");
  const [examId, setExamId] = useState("");
  const [grammarId, setGrammarId] = useState("");
  const [comprehensionId, setComprehensionId] = useState("");
  const [submit, setSubmit] = useState(false);
  const exams = useSelector((state) => state.exams.exams);
  const grammar = useSelector((state) => state.grammar.grammar);
  const comprehension = useSelector(
    (state) => state.comprehension.comprehension
  );
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const [subscription, setSubscription] = useState("");
  const [open, setOpen] = useState(false);
  const conversations = useSelector(
    (state) => state.conversations.conversations
  );
  const userState = useSelector((state) => state.userById.user);
  const [isSubscribed, setisSubscribed] = useState(false);
  const [hasValidPlan, sethasValidPlan] = useState(false);
  // const subscription = useSelector(setSubscriptionFromApi);
  // const userData = Cookies.get("userData");
  // if (userData) {
  //   // Parse the retrieved string back into an object
  //   const parsedUserData = JSON.parse(userData);

  //   // Access the activePlan property
  //   console.log("User Data:", parsedUserData);
  //   console.log(userData, parsedUserData?.activePlan);
  // }

  const isAdmin = getuserData()?.isAdmin;
  const { isLoading } = useSelector((state) => state.loader);

  const listOfPlans = ["tutor_monthly", "tutor_six_monthly", "tutor_yearly"];

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleExamChange = (event) => {
    setExamId(event.target.value);
    if (type === "exam") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  const handleGrammarChange = (event) => {
    setGrammarId(event.target.value);
    if (type === "grammar_rule") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };
  const handleComprehensionChange = (event) => {
    setComprehensionId(event.target.value);
    if (type === "comprehension") {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  useEffect(() => {
    dispatch(fetchExams({}));
    dispatch(fetchGrammar({}));
    dispatch(fetchComprehension({}));
  }, [dispatch]);

  useEffect(() => {
    if (subscription !== undefined) {
      setIsCheckingSubscription(false);
    }
  }, [subscription]);

  useEffect(() => {
    dispatch(fetchConversations({}));
    if (!isCheckingSubscription && subscription && isSubscribed) {
    }
  }, [dispatch, subscription, isSubscribed, isCheckingSubscription]);

  useEffect(() => {
    const userData = Cookies.get("userData");
    if (userData) {
      // Parse the retrieved string back into an object
      const parsedUserData = JSON.parse(userData);

      // Access the activePlan property
      // console.log("User Data:", parsedUserData);
      // console.log("Active Plan:", parsedUserData.activePlan);

      // Update the state with the activePlan
      setSubscription(parsedUserData.activePlan);
    } else {
      console.error("No userData found in cookies");
    }
  }, []);
  // console.log(subscription, isCheckingSubscription, isSubscribed, "------");
  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);
  useEffect(() => {
    if (token && userState) {
      dispatch(fetchUserById(userState?.id, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If no subscription, check comprehension practice
    if (isAdmin) {
      setisSubscribed(true);
      return;
    }
    if (!subscription) {
      if (userState?.ai_interactions_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.ai_interactions_left > 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const validPlan = subscription && listOfPlans?.some((plan) =>
      subscription.includes(plan)
    );
    sethasValidPlan(validPlan)
    if(!validPlan){
      if (userState?.ai_interactions_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.ai_interactions_left > 0);
        return;
      }
    }
    setisSubscribed(!!validPlan);
  }, [subscription, userState, isAdmin]);

  const handleSubmit = () => {
    const exam = {
      type: type,
      exam_id: examId,
    };

    const grammar = {
      type: type,
      grammar_rule_id: grammarId,
    };
    const comprehension = {
      type: type,
      comprehension_id: comprehensionId,
    };

    let payload;
    if (type === "exam") {
      payload = exam;
    } else if (type === "grammar_rule") {
      payload = grammar;
    } else if (type === "comprehension") {
      payload = comprehension;
    }

    dispatch(createConversation(payload, token))
      .then((response) => {
        if (response.success) {
          setType("");
          setExamId("");
          setGrammarId("");
          setComprehensionId("");

          navigate(
            `/chatbot/my-conversation/${response?.data?.conversation?.uuid}`
          );
          setSubmit(false);
        } else {
          console.error("Error creating conversation:", response.error);
          setSubmit(false);
        }
      })
      .catch((error) => {
        setSubmit(false);
        console.error("Error creating conversation:", error.message);
      });
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  if (isLoading || isCheckingSubscription) {
    return <Loader />;
  }

  if (!isLoading && !isCheckingSubscription && !isSubscribed) {
    return (
      <>
      { (!subscription || (subscription && !hasValidPlan))&& (
        <Box className="left-attempt-container">
          <Typography>
            Enjoy <span style={{ color: "black" }}>14</span> Complimentary AI
            Interactions for free. Unlock More with Our Premium Plans.
          </Typography>
          <Typography>
            Free AI Interactions Left :&nbsp;
            <span style={{ color: "black" }}>
              {" "}
              {userState?.ai_interactions_left}&nbsp;/&nbsp;14
            </span>
          </Typography>
        </Box>
      )}
      <Box className="content-layout" marginTop="32px">
        <Box variant="div" component="div" className="tab-wrapper user-listing">
          <Box className="access-denied-found">
            <Box className="page-inner">
              <GoAlertFill fontSize="65px" style={{ marginBottom: "40px" }} />
              <Typography
                variant="h3"
                component="h3"
                style={{ textTransform: "initial" }}
              >
                <Box>
                  You Have No Free Ai Interactions Left. Please Unlock Even More
                  With Our Premium Plans
                </Box>
              </Typography>
              <Typography variant="body1" component="p">
                <Box>
                  Please subscribe to join our vibrant community of learners!
                </Box>
              </Typography>
              <Box style={{ height: "35px" }}>
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn add-btn"
                  disableRipple
                  onClick={() => navigate(`/my-plan`)}
                >
                  Upgrade Plan
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      </>
    );
  }

  return (
    <>
      { (!subscription || (subscription && !hasValidPlan))&& (
        <Box className="left-attempt-container">
          <Typography>
            Enjoy <span style={{ color: "black" }}>14</span> Complimentary AI
            Interactions for free. Unlock More with Our Premium Plans.
          </Typography>
          <Typography>
            Free AI Interactions Left :&nbsp;
            <span style={{ color: "black" }}>
              {" "}
              {userState?.ai_interactions_left}&nbsp;/&nbsp;14
            </span>
          </Typography>
        </Box>
      )}
      {isSubscribed !== null && (
        <>
          <Box className="content-header-title" sx={{ mt: 5 }}>
            <Typography variant="h3" component="h3" sx={{ fontSize: "22px" }}>
              Choose Where You Need Assistance
            </Typography>
          </Box>
          <Box className="content-layout">
            <Box variant="div" component="div" className="createuser-wrap p-24">
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box variant="div" component="div" className="user-detail">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="label"
                            className="label"
                          >
                            Select your Domain
                          </Typography>

                          <FormControl fullWidth>
                            <Select
                              id="demo-simple-select"
                              value={type}
                              onChange={handleTypeChange}
                              size="small"
                              IconComponent={RiArrowDownSLine}
                              displayEmpty
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span style={{ color: "#aaa" }}>
                                      Select Type
                                    </span>
                                  ); // Placeholder styling
                                }
                                return selected === "exam"
                                  ? "Essay Practice"
                                  : selected === "grammar_rule"
                                  ? "Grammar Rules"
                                  : "Comprehension";
                              }}
                            >
                              <MenuItem value={"exam"}>Essay Practice</MenuItem>
                              <MenuItem value={"grammar_rule"}>
                                Grammar Rules
                              </MenuItem>
                              <MenuItem value={"comprehension"}>
                                Comprehension
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      {/* Exam */}
                      {type === "exam" &&
                      
                        (isSubscribed) ? (
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Select Exam
                            </Typography>

                            <FormControl fullWidth>
                              <Select
                                value={examId}
                                onChange={handleExamChange}
                                id="demo-simple-select"
                                size="small"
                                IconComponent={RiArrowDownSLine}
                              >
                                {exams?.exams?.map((designation) => (
                                  <MenuItem
                                    key={designation.id}
                                    value={designation.id}
                                  >
                                    {designation.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      ) 
                      // : type === "exam" &&
                      //   userState?.essay_practice_left === 0 ? (
                      //   <Box
                      //     className="access-denied-found"
                      //     sx={{
                      //       width: "100%",
                      //       height: "50vh",
                      //     }}
                      //   >
                      //     <Box
                      //       className="page-inner"
                      //       sx={{
                      //         height: "50vh !important",
                      //       }}
                      //     >
                      //       <GoAlertFill
                      //         fontSize="60px"
                      //         style={{ marginBottom: "40px" }}
                      //       />
                      //       <Typography
                      //         variant="h3"
                      //         component="h3"
                      //         style={{ textTransform: "initial" }}
                      //       >
                      //         <Box>
                      //         You Have No Free Essay Practices Left. Please Unlock Even More With Our Premium Plans.
                      //         </Box>
                      //       </Typography>
                      //       <Typography variant="body1" component="p">
                      //         <Box>
                      //           Please subscribe to join our vibrant community
                      //           of learners!
                      //         </Box>
                      //       </Typography>

                      //       <Box style={{ height: "35px" }}>
                      //         <Button
                      //           variant="contained"
                      //           size="large"
                      //           className="primary-btn btn add-btn"
                      //           disableRipple
                      //           onClick={() => navigate(`/my-plan`)}
                      //         >
                      //           Upgrade Plan
                      //         </Button>
                      //       </Box>
                      //     </Box>
                      //   </Box>
                      // )
                      
                      : null}

                      {/* grammar */}
                      {type === "grammar_rule" &&
                     
                        ( isSubscribed) ? (
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Select Grammar Rule
                            </Typography>

                            <FormControl
                              fullWidth
                              style={{ maxHeight: "200px", overflowY: "auto" }}
                            >
                              <Select
                                value={grammarId}
                                onChange={handleGrammarChange}
                                id="demo-simple-select"
                                size="small"
                                IconComponent={RiArrowDownSLine}
                              >
                                {grammar?.grammar_rules?.map((designation) => (
                                  <MenuItem
                                    key={designation.id}
                                    value={designation.id}
                                  >
                                    {designation.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      ) 
                      // : type === "grammar_rule" &&
                      //   userState?.grammar_rule_practice_left === 0 ? (
                      //   <Box
                      //     className="access-denied-found"
                      //     sx={{
                      //       width: "100%",
                      //       height: "50vh",
                      //     }}
                      //   >
                      //     <Box
                      //       className="page-inner"
                      //       sx={{
                      //         height: "50vh !important",
                      //       }}
                      //     >
                      //       <GoAlertFill
                      //         fontSize="65px"
                      //         style={{ marginBottom: "40px" }}
                      //       />

                      //       <Typography
                      //         variant="h3"
                      //         component="h3"
                      //         style={{ textTransform: "initial" }}
                      //       >
                      //         <Box>
                      //         You Have No Free Grammar Rule Practices Left. Please Unlock Even More With Our Premium Plans.
                      //         </Box>
                      //       </Typography>
                      //       <Typography variant="body1" component="p">
                      //         <Box>
                      //           Please subscribe to join our vibrant community
                      //           of learners!
                      //         </Box>
                      //       </Typography>
                      //       <Box style={{ height: "35px" }}>
                      //         <Button
                      //           variant="contained"
                      //           size="large"
                      //           className="primary-btn btn add-btn"
                      //           disableRipple
                      //           onClick={() => navigate(`/my-plan`)}
                      //         >
                      //           Upgrade Plan
                      //         </Button>
                      //       </Box>
                      //     </Box>
                      //   </Box>
                      // ) 
                      : null}

                      {/* Comprehension */}
                      {type === "comprehension" &&
                     
                        ( isSubscribed) ? (
                        <Grid item xs={12} md={12}>
                          <Box variant="div" component="div">
                            <Typography
                              variant="body1"
                              component="label"
                              className="label"
                            >
                              Select Comprehension
                            </Typography>

                            <FormControl
                              fullWidth
                              style={{ maxHeight: "200px", overflowY: "auto" }}
                            >
                              <Select
                                value={comprehensionId}
                                onChange={handleComprehensionChange}
                                id="demo-simple-select"
                                size="small"
                                IconComponent={RiArrowDownSLine}
                              >
                                {comprehension?.comprehensions?.map((c) => (
                                  <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      )
                      //  : type === "comprehension" &&
                      //   userState?.comprehension_practice_left === 0 ? (
                      //   <Box
                      //     className="access-denied-found"
                      //     sx={{
                      //       width: "100%",
                      //       height: "50vh",
                      //     }}
                      //   >
                      //     <Box
                      //       className="page-inner"
                      //       sx={{
                      //         height: "50vh !important",
                      //       }}
                      //     >
                      //       <GoAlertFill
                      //         fontSize="65px"
                      //         style={{ marginBottom: "40px" }}
                      //       />

                      //       <Typography
                      //         variant="h3"
                      //         component="h3"
                      //         style={{ textTransform: "initial" }}
                      //       >
                      //         <Box>
                      //         You Have No Free Comprehension Practices Left. Please Unlock Even More With Our Premium Plans.
                      //         </Box>
                      //       </Typography>
                      //       <Typography variant="body1" component="p">
                      //         <Box>
                      //           Please subscribe to join our vibrant community
                      //           of learners!
                      //         </Box>
                      //       </Typography>
                      //       <Box style={{ height: "35px" }}>
                      //         <Button
                      //           variant="contained"
                      //           size="large"
                      //           className="primary-btn btn add-btn"
                      //           disableRipple
                      //           onClick={() => navigate(`/my-plan`)}
                      //         >
                      //           Upgrade Plan
                      //         </Button>
                      //       </Box>
                      //     </Box>
                      //   </Box>
                      // ) 
                      : null}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}

      {!isLoading &&
        isSubscribed &&
        conversations?.conversations?.length > 0 && (
          <Box className="content-layout">
            <TableContainer className="table-listing">
              <Table
                // sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow className="head-row">
                    <TableCell align="left">Previous Chats</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {conversations?.conversations?.map((exam, index) => (
                    <TableRow
                      key={exam.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/chatbot/my-conversation/${exam?.uuid}`,
                          "_blank"
                        )
                      }
                    >
                      <TableCell align="left" key={exam?.id} colSpan={2}>
                        {exam?.chat_name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      <MessageModal
        open={open}
        setOpen={setOpen}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};

export default AiSupport;
