import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Grid,
  Tooltip,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addTofavourites } from "../../store/slices/favourite/favouriteSlice";
import { fetchGrammar } from "../../store/slices/grammar/grammarSlice";
import { fetchComprehension } from "../../store/slices/comprehension/comprehensionSlice";
import { fetchExams } from "../../store/slices/exams/examSlice";
import { fetchUserById } from "../../store/slices/users/userByIdSlice";
import getuserData from "../../utils/userData";
// import { editUser } from "../../store/slices/users/editUserByIdSlice";
import { fetchpromocodes } from "../../store/slices/settings/promocodesSlice";
import axios from "axios";
import { getToken } from "../../utils/token";

const FirstLoginOnboarding = ({ onClose }) => {
  const dispatch = useDispatch();
  const token = getToken();
  const userId = getuserData()?.userId;
  const [step, setStep] = useState(0);
  const [selectedExams, setSelectedExams] = useState([]);
  const [selectedGrammars, setSelectedGrammars] = useState([]);
  const [selectedComprehensions, setSelectedComprehensions] = useState([]);
  const [promocode, setPromocode] = useState("");
  const [message, setMessage] = useState(null);

  const exams = useSelector((state) => state.exams.exams.exams);
  const grammar = useSelector((state) => state.grammar.grammar.grammar_rules);
  const comprehension = useSelector(
    (state) => state.comprehension.comprehension.comprehensions
  );
  const user = useSelector((state) => state.userById.user);
  const promocodes = useSelector((state) => state.promocodes.promocodes);

  const mockExams = exams;
  const mockGrammars = grammar;
  const mockComprehensions = comprehension;

  const handleNext = async () => {
    // Logic for adding favorites in each step
    if (step === 1) {
      dispatch(fetchExams());
      selectedExams.forEach((examId) => {
        dispatch(
          addTofavourites({
            module_name: "essay",
            item_id: [examId],
          })
        );
      });
    }

    if (step === 2) {
      dispatch(fetchGrammar());
      selectedGrammars.forEach((grammarId) => {
        dispatch(
          addTofavourites({
            module_name: "grammar_rule",
            item_id: [grammarId],
          })
        );
      });
    }

    if (step === 3) {
      dispatch(fetchComprehension());
      selectedComprehensions.forEach((compId) => {
        dispatch(
          addTofavourites({
            module_name: "comprehension",
            item_id: [compId],
          })
        );
      });
    }
    if (step === 4) {
      if (promocode) {
        const matchedPromo = promocodes?.data?.find(
          (item) => item.promo_code === promocode
        );

        if (matchedPromo) {
          setMessage("Promocode is verified!");
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/edit-profile/`,
            {
              username: user?.username,
              first_name: user?.first_name,
              last_name: user?.last_name,
              email: user?.email,
              promocode: promocode,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          setMessage("Invalid Promocode.");
          return;
        }
      }
    }

    setStep(step + 1);
  };

  const handleToggleSelection = (id, type) => {
    const maxExamSelections = 2;
    const maxGrammarCompSelections = 6;

    switch (type) {
      case "exam":
        setSelectedExams((prev) =>
          prev?.includes(id)
            ? prev?.filter((item) => item !== id)
            : prev?.length < maxExamSelections
            ? [...prev, id]
            : prev
        );
        break;
      case "grammar":
        setSelectedGrammars((prev) =>
          prev?.includes(id)
            ? prev?.filter((item) => item !== id)
            : prev?.length < maxGrammarCompSelections
            ? [...prev, id]
            : prev
        );
        break;
      case "comprehension":
        setSelectedComprehensions((prev) =>
          prev?.includes(id)
            ? prev?.filter((item) => item !== id)
            : prev?.length < maxGrammarCompSelections
            ? [...prev, id]
            : prev
        );
        break;
      case "promocode":
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchExams());
    dispatch(fetchGrammar());
    dispatch(fetchComprehension());
    dispatch(fetchpromocodes());
    dispatch(fetchUserById(userId));
  }, [dispatch]);

  const renderStepContent = () => {
    const maxExamSelections = 2;
    const maxGrammarCompSelections = 6;
    switch (step) {
      case 0:
        return (
          <Box textAlign="center" sx={{ m: 5 }}>
            <Typography variant="h6">
              Welcome to Your Learning Journey!
            </Typography>
            <Typography variant="body1">
              Let's personalize your experience by selecting your interests.
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ m: 2 }}>
            <Typography variant="h6" gutterBottom>
              Select Exams
            </Typography>
            <Grid container spacing={2}>
              {mockExams?.map((exam) => {
                const isDisabled =
                  !selectedExams?.includes(exam.id) &&
                  selectedExams?.length >= maxExamSelections;

                return (
                  <Grid item xs={12} sm={6} key={exam.id}>
                    <Tooltip
                      title={
                        isDisabled
                          ? `You can only select up to ${maxExamSelections} Exams`
                          : ""
                      }
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedExams.includes(exam.id)}
                            onChange={() =>
                              handleToggleSelection(exam.id, "exam")
                            }
                            disabled={isDisabled}
                          />
                        }
                        label={exam.name}
                      />
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );

      case 2:
        return (
          <Box sx={{ m: 2 }}>
            <Typography variant="h6">Select Grammar Rules</Typography>
            <Grid container spacing={2}>
              {mockGrammars?.map((grammar) => {
                const isDisabled =
                  !selectedGrammars?.includes(grammar.id) &&
                  selectedGrammars?.length >= maxGrammarCompSelections;

                return (
                  <Grid item xs={12} sm={6} key={grammar.id}>
                    <Tooltip
                      key={grammar.id}
                      title={
                        isDisabled
                          ? `You can only select up to ${maxGrammarCompSelections} Grammar Rules`
                          : ""
                      }
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedGrammars.includes(grammar.id)}
                            onChange={() =>
                              handleToggleSelection(grammar.id, "grammar")
                            }
                            disabled={isDisabled}
                          />
                        }
                        label={grammar.name}
                      />
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );

      case 3:
        return (
          <Box sx={{ m: 2 }}>
            <Typography variant="h6">Select Comprehension Texts</Typography>
            <Grid container spacing={2}>
              {mockComprehensions?.map((comp) => {
                const isDisabled =
                  !selectedComprehensions?.includes(comp.id) &&
                  selectedComprehensions?.length >= maxGrammarCompSelections;
                return (
                  <Grid item xs={12} sm={6} key={comp.id}>
                    <Tooltip
                      key={comp.id}
                      title={
                        isDisabled
                          ? `You can only select up to ${maxGrammarCompSelections} Comprehensions`
                          : ""
                      }
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedComprehensions.includes(comp.id)}
                            onChange={() =>
                              handleToggleSelection(comp.id, "comprehension")
                            }
                            disabled={isDisabled}
                          />
                        }
                        label={comp.name}
                      />
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );

      case 4:
        return (
          <Box textAlign="" sx={{ mt: 3 }}>
            <Typography variant="h6">Do you have any Promocode? </Typography>
            <TextField
              className="input-field"
              fullWidth
              variant="outlined"
              size="small"
              placeholder={`Enter Promocode`}
              name="promocode"
              onChange={(e) => {
                setPromocode(e.target.value);
              }}
              style={{ marginTop: "16px" }}
            />
            {message && (
              <Typography
                sx={{
                  color:
                    message === "Invalid Promocode."
                      ? "red !important"
                      : "green !important",
                }}
              >
                {message}
              </Typography>
            )}
          </Box>
        );
      case 5:
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ m: 2, fontWeight: 900 }}>
              Your Selected Items
            </Typography>
            <Grid container spacing={2} sx={{ ml: 1 }}>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: 900 }}>
                  Exams:
                </Typography>
                {selectedExams?.map((id) => (
                  <Typography key={id}>
                    <li>{mockExams?.find((exam) => exam.id === id)?.name}</li>
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: 900 }}>
                  Grammar Rules:
                </Typography>
                {selectedGrammars?.map((id) => (
                  <Typography key={id}>
                    <li>
                      {mockGrammars?.find((grammar) => grammar.id === id)?.name}
                    </li>
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ fontWeight: 900 }}>
                  Comprehensions:
                </Typography>
                {selectedComprehensions?.map((id) => (
                  <Typography key={id}>
                    <li>
                      {mockComprehensions?.find((comp) => comp.id === id)?.name}
                    </li>
                  </Typography>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ ml: 1, mt:1 }}>
              <Grid item xs={4}>
                <Typography variant="subtitle1" >
                  <span style={{ fontWeight: 900 }}>Promocode:</span> {promocode}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
      }}
    >
      <DialogTitle sx={{ background: "#43ACEF", fontWeight: 900 }}>
        Welcome to OpenEssayMe
      </DialogTitle>

      <DialogContent>{renderStepContent()}</DialogContent>

      <DialogActions>
        {step < 5 && (
          <Button
            sx={{ m: 2 }}
            className="primary-btn btn add-btn"
            onClick={handleNext}
            color="primary"
            variant="contained"
            disabled={
              (step === 1 && selectedExams?.length === 0) ||
              (step === 2 && selectedGrammars?.length === 0) ||
              (step === 3 && selectedComprehensions?.length === 0)
            }
          >
            {step === 4 ? (promocode?"Submit":"Skip") : "Next"}
          </Button>
        )}

        {step === 5 && (
          <Button
            onClick={() => onClose()}
            sx={{ m: 2 }}
            color="primary"
            variant="contained"
            className="primary-btn btn add-btn"
          >
            Start Learning
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FirstLoginOnboarding;
