import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { getToken } from "../../../utils/token";
import { successToast } from "../../../response/successToast";

export const deleteWordById = (id) => async (dispatch) => {
  try {
    dispatch(deleteWordStart());
    const token = getToken();
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/dictionary/delete/${id}/word/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(deleteWordSuccess(response.data));
    successToast("Word deleted successfully")
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(deleteWordFailure(error.response.data));
  }
};

const initialState = {
  Words: [],

  status: "idle",
  error: null,
};
const deleteWordSlice = createSlice({
  name: "deleteWords",
  initialState,
  reducers: {
    deleteWordStart: (state) => {
      state.status = "loading";
    },
    deleteWordSuccess: (state, action) => {
      state.status = "succeeded";
      state.Words = action.payload;
    },
    deleteWordFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { deleteWordStart, deleteWordSuccess, deleteWordFailure } =
  deleteWordSlice.actions;

export default deleteWordSlice.reducer;
