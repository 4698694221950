import React from "react";
import { Box } from "@mui/material";
import { keyframes } from "@emotion/react";

const dotAnimation = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const ChatLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        margin:'20px',
      }}
    >
      <Box
        sx={{
          width: "8px",
          height: "8px",
          backgroundColor: "#1976d2",
          borderRadius: "50%",
          animation: `${dotAnimation} 1.5s infinite`,
          animationDelay: "0s",
        }}
      />
      <Box
        sx={{
          width: "8px",
          height: "8px",
          backgroundColor: "#1976d2",
          borderRadius: "50%",
          animation: `${dotAnimation} 1.5s infinite`,
          animationDelay: "0.2s",
        }}
      />
      <Box
        sx={{
          width: "8px",
          height: "8px",
          backgroundColor: "#1976d2",
          borderRadius: "50%",
          animation: `${dotAnimation} 1.5s infinite`,
          animationDelay: "0.4s",
        }}
      />
    </Box>
  );
};

export default ChatLoader;
