import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "suneditor/dist/css/suneditor.min.css";

import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../../utils/token";
import { callApi } from "../../api";
import { successToast } from "../../response/successToast";

// const modules = {
//   toolbar: [
//     ["bold", "italic", "underline", "blockquote"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     [{ header: [1, 2, 3, 4, 5, 6] }],
//     ["link", "image"],
//   ],
// };

const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

const AdminDictionary = () => {
  const [propmts, setpropmts] = useState();

  const [instructionPrompt, setInstructionPrompt] = useState();
  const formik = useFormik({
    initialValues: {
      name: "", // Set initial values from stored credentials
      exam_prompt: "",
      feedback_prompt: "",
      description: "",
      instructions: "",
    },
    onSubmit: async (values) => {
      let payload = {
        explanation_prompt: values.explanation_prompt,
        feedback_prompt: values.feedback_prompt,
        practice_set_prompt: values.practice_set_prompt,
      };

      const token = getToken();
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/settings/prompt/dictionary/edit`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      if (response?.status === 200) {
        successToast(response?.data?.message);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      explanation_prompt: propmts?.filter(
        (p) => p?.prompt_type === "explanation_prompt"
      )[0]?.prompt_text,
      feedback_prompt: propmts?.filter(
        (p) => p?.prompt_type === "feedback_prompt"
      )[0]?.prompt_text,
      practice_set_prompt: propmts?.filter(
        (p) => p?.prompt_type === "practice_set_prompt"
      )[0]?.prompt_text,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propmts]);

  const fetchSetting = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/prompt/list?module=dictionary`,
      });
      setpropmts(response?.data);
    } catch (error) {
      console.error(error);
      // errorToast(error.response.data.errors);
    }
  };
  useEffect(() => {
    fetchSetting();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            {/* Word Prompts Accordion */}
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h6" sx={{ fontSize: "18px" }}>
                    Explanation Prompt
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box variant="div" component="div">
                        {/* <Typography
                    variant="body1"
                    component="label"
                    className="label"
                    sx={{ mt: 1 }}
                  >
                    My Words :
                  </Typography> */}
                        <TextField
                          id="outlined-multiline-static"
                          placeholder="Enter exam propmt to generate details..."
                          multiline
                          rows={8}
                          value={formik.values.explanation_prompt}
                          sx={{ mt: 1 }}
                          fullWidth
                          onChange={(e) => {
                            formik.setFieldValue(
                              "explanation_prompt",
                              e.target.value
                            );
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Practice Prompts Accordion */}
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography variant="h6" sx={{ fontSize: "18px" }}>
                    Practice Set Prompt
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box variant="div" component="div">
                    {/* <Typography
                          variant="body1"
                          component="label"
                          sx={{ mt: 1 }}
                        >
                          Practice Prompt:
                        </Typography> */}
                    <TextField
                      id="practice-prompt"
                      placeholder="Enter practice prompt..."
                      multiline
                      rows={5}
                      value={formik.values.practice_set_prompt}
                      sx={{ mt: 1 }}
                      fullWidth
                      onChange={(e) => {
                        formik.setFieldValue(
                          "practice_set_prompt",
                          e.target.value
                        );
                      }}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {/* Feedback Prompts Accordion */}
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Typography variant="h6" sx={{ fontSize: "18px" }}>
                    Feedback Prompt
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box variant="div" component="div">
                    {/* <Typography
                          variant="body1"
                          component="label"
                          sx={{ mt: 1 }}
                        >
                          Feedback Prompt:
                        </Typography> */}
                    <TextField
                      id="feedback-prompt"
                      placeholder="Enter feedback prompt..."
                      multiline
                      rows={6}
                      value={formik.values.feedback_prompt}
                      sx={{ mt: 1 }}
                      fullWidth
                      onChange={(e) => {
                        formik.setFieldValue("feedback_prompt", e.target.value);
                      }}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
        <Box
          variant="div"
          component="div"
          className="footer-btn"
          style={{ marginTop: "16px", height: "35px" }}
        >
          <Box variant="div" component="div" className="btn-group">
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn"
              type="submit"
              disableRipple
            >
              {" "}
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};
export default AdminDictionary;
