import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { getToken } from "../../../utils/token";
import { successToast } from "../../../response/successToast";

const initialState = {
  isLoading: false,
  isTryAgainWordLoading: false,
  success: false,
  successTryAgainWord: false,
  error: null,
  errorTryAgainWord: null,
};

const attemptWordSlice = createSlice({
  name: "attemptWord",
  initialState,
  reducers: {
    attemptWordStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    attemptWordSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    attemptWordFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    attemptTryAgainWordStart: (state) => {
      state.isTryAgainWordLoading = true;
      state.successTryAgainWord = false;
      state.errorTryAgainWord = null;
    },
    attemptTryAgainWordSuccess: (state) => {
      state.isTryAgainWordLoading = false;
      state.successTryAgainWord = true;
      state.errorTryAgainWord = null;
    },
    attemptTryAgainWordFailure: (state, action) => {
      state.isTryAgainWordLoading = false;
      state.successTryAgainWord = false;
      state.errorTryAgainWord = action.payload;
    },
  },
});

export const {
  attemptWordStart,
  attemptWordSuccess,
  attemptWordFailure,
  attemptTryAgainWordStart,
  attemptTryAgainWordSuccess,
  attemptTryAgainWordFailure,
} = attemptWordSlice.actions;

export const attemptWord = (payload) => async (dispatch) => {
  
  try {
    dispatch(attemptWordStart());
    const token = getToken();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/dictionary/practice-attempt/`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
   

    const data = response.data;
    dispatch(attemptWordSuccess());
    successToast(data?.message);
    
    return { success: true, data: data };
  } catch (error) {
    errorToast(error?.response?.data?.errors);
    dispatch(attemptWordFailure(error?.message));

    return { success: false, error: error?.response?.message };
  }
};

export const attemptTryAgainWord = (_, token, url) => async (dispatch) => {
  try {
    dispatch(attemptTryAgainWordStart());
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = response.data;
    dispatch(attemptTryAgainWordSuccess());
    return { success: true, data: data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(attemptTryAgainWordFailure(error.message));
    return { success: false, error: error.response.message };
  }
};

export default attemptWordSlice.reducer;
