import {
  Box,
  Grid,
  Typography,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Button,
  LinearProgress,
  styled,
  Tooltip,
  CircularProgress,
  Stack,
} from "@mui/material";
import { RxDotFilled } from "react-icons/rx";
import { TfiHandPointRight } from "react-icons/tfi";
import React, { useEffect, useState } from "react";
import { fetchUserDashboard } from "../../store/slices/dashBoardSlice";
import { fetchUserfavourites } from "../../store/slices/favourite/favouriteSlice";
import { useDispatch, useSelector } from "react-redux";
import getuserData from "../../utils/userData";
import { useNavigate } from "react-router-dom";
import FirstLoginOnboarding from "./onBoarding";
import { fetchGrammar } from "../../store/slices/grammar/grammarSlice";
import { fetchComprehension } from "../../store/slices/comprehension/comprehensionSlice";
import Cookies from "js-cookie";
import { getToken } from "../../utils/token";
import { fetchUserById } from "../../store/slices/users/userByIdSlice";
import MainProgressBar from "../../common/progressbar";
import { Progress } from "rsuite";
// const isAdmin = getuserData()?.isAdmin;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state) => state.userDashboard.dashboard);
  const userFavouritesData = useSelector((state) => state.favourite.favourites);
  const state = useSelector((state) => state);

  const [showFirstLoginDialog, setShowFirstLoginDialog] = useState(false);
  const token = getToken();
  const storedUserData = Cookies.get("userData");

  const parsedUserData = storedUserData ? JSON?.parse(storedUserData) : {};
  useEffect(() => {
    dispatch(fetchUserDashboard({}));
    dispatch(fetchUserfavourites());
  }, [dispatch]);

  useEffect(() => {
    setShowFirstLoginDialog(dashboardData.is_first_login);
  }, [dashboardData.is_first_login]);

  const handleCloseFirstLoginDialog = () => {
    dispatch(fetchUserfavourites());
    setShowFirstLoginDialog(false);
  };

  const userState = useSelector((state) => state.userById.user);
  useEffect(() => {
    if (token) {
      dispatch(fetchUserById(parsedUserData?.userId, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGradient = (value) => {
    return `linear-gradient(to right, lightgreen, green)`;
  };
 

  const combinedComprehensionCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box
          variant="div"
          component="div"
          className="tab-wrapper user-listing"
          sx={{ position: "relative", padding: "15px" }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h3" component="h3">
              My Comprehension
            </Typography>
            <Button
              sx={{
                height:'27px !important',
                boxShadow: '0 4px 0 var(--primary-dark), 0 5px 15px !important'
              }}
              onClick={() => navigate("/comprehension")}
              className="primary-btn btn add-btn"
            >
              <Typography sx={{fontSize:'15px'}}>View More</Typography>
            </Button>
          </Box>

          {/* progress */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // height="200px"
            sx={{ padding: "10px" }}
          >
            <div
              style={{ width: 90, display: "inline-block", marginRight: 10 }}
            >
              <Progress.Circle
                percent={userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                  2
                )}
                showInfo={true}
              />
            </div>
          </Box>

          {/* My Comprehension Section */}
          <Box
            sx={{
              marginBottom: "20px",
              padding: "10px",
              border: "1px solid #ddd",
height:'165px',
              borderRadius: "8px",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              mt: 1,
            }}
          >
            <Typography
              sx={{ marginBottom: "10px", fontWeight: "600", fontSize: "15px" }}
            >
              My Favourites :
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                
                maxHeight: "115px",
                overflow: "auto",
              }}
            >
              {!userFavouritesData?.favourites?.comprehension ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    Favourite Comprehension Not Found
                  </Typography>
                </Box>
              ) : (
                userFavouritesData?.favourites?.comprehension
                  // ?.slice(0, 3)
                  ?.map((comp) => (
                    <Box
                      key={comp.item_id}
                      onClick={() =>
                        navigate(`/comprehension/${comp?.item_id}/view`)
                      }
                      sx={{
                        padding: "4px 10px",
                        backgroundColor: "#f0f8ff",
                        borderRadius: "20px",
                        border: "1px solid #dbeeff",
                        height: "fit-content",
                        cursor: "pointer",
                        transition: "0.3s",
                        "&:hover": {
                          backgroundColor: "#dbeeff",
                        },
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                        {comp.item_name}
                      </Typography>
                    </Box>
                  ))
              )}
            </Box>
          </Box>

          {/* Latest Comprehensions Section */}
          <Box
            sx={{
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              height:'165px',
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              sx={{ marginBottom: "10px", fontWeight: "600", fontSize: "15px" }}
            >
              Latest :
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
               
                maxHeight: "115px",
                overflow: "auto",
              }}
            >
              {dashboardData?.latest_comprehensions?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    Latest Comprehension Not Found
                  </Typography>
                </Box>
              ) : (
                dashboardData?.latest_comprehensions?.map((comprehension) => (
                  <Box
                    key={comprehension.id}
                    onClick={() =>
                      navigate(`/comprehension/${comprehension?.id}/view`)
                    }
                    sx={{
                      padding: "4px 10px",
                      border: "1px solid #efefef",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "20px",
                      height: "fit-content",
                      cursor: "pointer",
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "#efefef",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 500, fontSize: "14px" }}
                    >
                      {comprehension.name}
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  const combinedGrammarCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box
          variant="div"
          component="div"
          className="tab-wrapper user-listing"
          sx={{ position: "relative", padding: "15px" }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h3" component="h3">
              My Grammar Rules
            </Typography>
            <Button
             sx={{
              height:'27px !important',
              boxShadow: '0 4px 0 var(--primary-dark), 0 5px 15px !important'
            }}
              onClick={() => navigate("/grammar")}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text" sx={{fontSize:'15px'}}>View More</Typography>
            </Button>
          </Box>

          {/* progress */}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // height="200px"
            sx={{ padding: "10px" }}
          >
            <div
              style={{ width: 90, display: "inline-block", marginRight: 10 }}
            >
              <Progress.Circle
                percent={userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                  2
                )}
                showInfo={true}
              />
            </div>
            {/* <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                value={userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                  2
                )} // Progress value (0-100)
                size={100} // Size of the circular progress
                thickness={5} // Thickness of the circular progress
                sx={{
                  color: "#1976d2", // Custom color for progress
                  backgroundColor: "#e0e0e0", // Background track color
                  borderRadius: "50%", // Ensures it's circular
                }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" component="div" color="textPrimary">
                  {userFavouritesData?.grammar_and_comprehension_individual_progress?.toFixed(
                    1
                  )}
                  %
                </Typography>
              </Box>
            </Box> */}
          </Box>

          {/* My Grammar Rules Section */}
          <Box
            sx={{
              marginBottom: "20px",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              height:'165px',
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              mt: 1,
            }}
          >
            <Typography
              sx={{ marginBottom: "10px", fontWeight: "600", fontSize: "15px" }}
            >
              My Favourites :
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                
                maxHeight: "115px",
                overflow: "auto",
              }}
            >
              {!userFavouritesData?.favourites?.grammar_rule ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    Favourite Grammar Rules Not Found
                  </Typography>
                </Box>
              ) : (
                userFavouritesData?.favourites?.grammar_rule
                  // ?.slice(0, 3)
                  ?.map((grammar) => (
                    <Box
                      key={grammar.item_id}
                      onClick={() =>
                        navigate(`/grammar/${grammar?.item_id}/view`)
                      }
                      sx={{
                        padding: "4px 10px",
                        backgroundColor: "#f0f8ff",
                        height: "fit-content",
                        border: "1px solid #dbeeff",
                        borderRadius: "20px",
                        cursor: "pointer",
                        transition: "0.3s",
                        "&:hover": {
                          backgroundColor: "#dbeeff",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, fontSize: "14px" }}
                      >
                        {grammar.item_name}
                      </Typography>
                    </Box>
                  ))
              )}
            </Box>
          </Box>

          {/* Latest Grammar Rules Section */}
          <Box
            sx={{
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              height:'165px',
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              sx={{ marginBottom: "10px", fontWeight: "600", fontSize: "15px" }}
            >
              Latest :
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
               
                maxHeight: "115px",
                overflow: "auto",
              }}
            >
              {dashboardData?.latest_grammar_rules?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    Grammar Rules Found
                  </Typography>
                </Box>
              ) : (
                dashboardData?.latest_grammar_rules?.map((rule) => (
                  <Box
                    key={rule.id}
                    onClick={() => navigate(`/grammar/${rule?.id}/view`)}
                    sx={{
                      padding: "4px 10px",
                      backgroundColor: "#f9f9f9",
                      height: "fit-content",
                      border: "1px solid #efefef",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "#efefef",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 500, fontSize: "14px" }}
                    >
                      {rule.name}
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  const combinedExamsCard = (
    <>
      <Box className="dashboard-left-content-layout">
        <Box
          variant="div"
          component="div"
          className="tab-wrapper user-listing"
          sx={{ position: "relative", padding: "15px" }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h3" component="h3">
              My Exams
            </Typography>
            <Button
              sx={{
                height:'27px !important',
                boxShadow: '0 4px 0 var(--primary-dark), 0 5px 15px !important'
              }}
              onClick={() => navigate("/exams")}
              className="primary-btn btn add-btn"
            >
              <Typography className="view-more-text" sx={{fontSize:'15px'}}>View More</Typography>
            </Button>
          </Box>

          {/* progress */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // height="200px"
            sx={{ padding: "10px" }}
          >
            <div
              style={{ width: 90, display: "inline-block", marginRight: 10 }}
            >
              <Progress.Circle
                percent={userFavouritesData?.essay_individual_progress?.toFixed(
                  2
                )}
                showInfo={true}
              />
            </div>
          </Box>

          {/* My Exams Section */}
          <Box
            sx={{
              marginBottom: "20px",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              mt: 1,
              height:'165px',
            }}
          >
            <Typography
              sx={{ marginBottom: "10px", fontWeight: "600", fontSize: "15px" }}
            >
              My Favourites :
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
              
                maxHeight: "115px",
                overflow: "auto",
              }}
            >
              {!userFavouritesData?.favourites?.essay ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    Favourite Exams Not Found
                  </Typography>
                </Box>
              ) : (
                userFavouritesData?.favourites?.essay
                  // ?.slice(0, 3)
                  ?.map((exam) => (
                    <Box
                      key={exam.id}
                      onClick={() => navigate(`/exams/${exam?.item_id}/view`)}
                      sx={{
                        padding: "4px 10px",
                        backgroundColor: "#f0f8ff",
                        border: "1px solid #dbeeff",
                        height: "fit-content",
                        borderRadius: "20px",
                        cursor: "pointer",
                        transition: "0.3s",
                        "&:hover": {
                          backgroundColor: "#dbeeff",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, fontSize: "14px" }}
                      >
                        {exam.item_name}
                      </Typography>
                    </Box>
                  ))
              )}
            </Box>
          </Box>

          {/* All Exams Section */}
          <Box
            sx={{
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              height:'165px',
            }}
          >
            <Typography
              sx={{ marginBottom: "10px", fontWeight: "600", fontSize: "15px" }}
            >
              Latest :
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
               
                maxHeight: "115px",
                overflow: "auto",
              }}
            >
              {dashboardData?.latest_exams?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    Exams Not Found
                  </Typography>
                </Box>
              ) : (
                dashboardData?.latest_exams?.map((exam) => (
                  <Box
                    key={exam.id}
                    onClick={() => navigate(`/exams/${exam?.id}/view`)}
                    sx={{
                      padding: "4px 10px",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #efefef",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "0.3s",
                      height: "fit-content",
                      "&:hover": {
                        backgroundColor: "#efefef",
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                      {exam.name}
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  const isAdmin = getuserData()?.isAdmin;
  return (
    <>
      {isAdmin ? (
        <Box className="content-wrapper">
          <Box className="card-wrapper">
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  className="card-title"
                  sx={{ marginBottom: "16px" }}
                >
                  {/* {isAdmin ? "Dashboard" : "User Dashboard"} */}
                  Dashboard
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <>
          {showFirstLoginDialog && (
            <FirstLoginOnboarding onClose={handleCloseFirstLoginDialog} />
          )}

          {/* My */}
          <Box className="content-wrapper">
            {/* main progress bar */}
            <MainProgressBar data={userFavouritesData} />
            <Grid container spacing={2} mt={1}>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {combinedExamsCard}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {combinedGrammarCard}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {combinedComprehensionCard}
                  </Grid>
                </Grid>
              </Grid>

              {/* my exams */}
              {/* <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {myExamsCard}
                  </Grid>
                </Grid>
              </Grid> */}

              {/* exams */}
              {/* <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {examDataCard}
                  </Grid>
                </Grid>
              </Grid> */}

              {/* my grammars */}
              {/* <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {myGrammarRulesCard}
                  </Grid>
                </Grid>
              </Grid> */}

              {/* grammars */}
              {/* <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {grammarDataCard}
                  </Grid>
                </Grid>
              </Grid> */}

              {/* myComps */}
              {/* <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {myComprehensionCard}
                  </Grid>
                </Grid>
              </Grid> */}

              {/* Cmops */}
              {/* <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={3} style={{ flex: "1" }}>
                  <Grid item xs={12}>
                    {ComprehensionDataCard}
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Dashboard;
