import {
  Box,
  Typography,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Button,
  MenuItem,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { Search as SearchIcon } from "@mui/icons-material";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchExams } from "../../../store/slices/exams/examSlice";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import { GoAlertFill } from "react-icons/go";
import Loader from "../../../common/loader";
import {
  addTofavourites,
  removeFromfavourites,
  fetchUserfavourites,
} from "../../../store/slices/favourite/favouriteSlice";
import { errorToast } from "../../../response/errorToast";
import { fetchUserById } from "../../../store/slices/users/userByIdSlice";
import { getToken } from "../../../utils/token";
import RemoveFavModal from "../../../common/removeFavModal";

const UsersExamList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exams = useSelector((state) => state.exams.exams);
  const favourites = useSelector(
    (state) => state.favourite?.favourites?.favourites?.essay
  );
  
  const [localFavorites, setLocalFavorites] = useState(new Set());
  const token = getToken();
  const subscription = useSelector(setSubscriptionFromApi);
  const userState = useSelector((state) => state.userById.user);
  // console.log("userState in exams", userState);
  const [isSubscribed, setisSubscribed] = useState(false);

  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "basic_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "basic_six_monthly",
    "basic_yearly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  const { isLoading } = useSelector((state) => state.loader);
  // const isLoadingFavorite = useSelector((state) => state.loader.isLoading);
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredExams, setFilteredExams] = useState([]);
  const [hasValidPlan, sethasValidPlan] = useState(false);
  const [openRemoveFavModal, setOpenRemoveFavModal] = useState(false);
  const [removableItemFromFav, setremovableItemFromFav] = useState();

  useEffect(() => {
    const favoriteIds = new Set(favourites?.map((fav) => fav.item_id));
    setLocalFavorites(favoriteIds);
  }, [favourites, activeTab]);

  const isFavorite = useCallback(
    (examid) => {
      return localFavorites?.has(examid);
    },
    [localFavorites]
  );

  const toggleFavorite = async (exam) => {
    const payload = {
      module_name: "essay",
      item_id: [exam.id],
    };

    try {
      setIsLoadingFavorite(true);

      // Optimistically update local state
      const newLocalFavorites = new Set(localFavorites);
      if (isFavorite(exam.id)) {
        newLocalFavorites.delete(exam.id);
        await dispatch(removeFromfavourites(payload));
      } else {
        newLocalFavorites.add(exam.id);
        await dispatch(addTofavourites(payload));
      }

      // Update local favorites immediately
      setLocalFavorites(newLocalFavorites);
      dispatch(fetchUserfavourites());
    } catch (error) {
      // Revert local state if API call fails
      console.error("Failed to toggle favorite:", error);
      errorToast(["Failed to update favorites"]);
    } finally {
      setIsLoadingFavorite(false);
    }
  };

  useEffect(() => {
    if (exams?.exams) {
      let filtered = [...exams.exams];

      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        filtered = filtered.filter((rule) =>
          rule.name.toLowerCase().includes(query)
        );
      }

      if (activeTab === 0) {
        filtered = filtered.filter((exam) => isFavorite(exam.id));
      }

      setFilteredExams(filtered);
    }
  }, [exams?.exams, searchQuery, activeTab, isFavorite]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(fetchExams({}));
    
    dispatch(fetchUserfavourites());
    
  }, [dispatch, isSubscribed, isCheckingSubscription, activeTab]);

  useEffect(() => {
    // Simulate fetching subscription status
    if (subscription !== undefined) {
      setIsCheckingSubscription(false);
    }
  }, [subscription]);

  useEffect(() => {
    // If no subscription, check Essay practice
    if (!subscription) {
      if (userState?.essay_practice_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.essay_practice_left > 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const validPlan = listOfPlans?.some((plan) =>
      subscription.includes(plan)
    );
    sethasValidPlan(validPlan)

    setisSubscribed(!!validPlan);
  }, [subscription, userState]);

  useEffect(() => {
    if (token && userState) {
      dispatch(fetchUserById(userState?.id, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading || isCheckingSubscription ? (
        <Loader />
      ) : (
        <>
          {(!subscription || (subscription && !hasValidPlan)) && (
            <Box className="left-attempt-container">
              <Typography>
                Enjoy <span style={{ color: "black" }}>3</span> Complimentary
                Essay Practice Sessions for free. Unlock More with Our Premium
                Plans.
              </Typography>
              <Typography>
                Free Essay Practice Left :&nbsp;
                <span style={{ color: "black" }}>
                  {" "}
                  {userState?.essay_practice_left}&nbsp;/&nbsp;3
                </span>
              </Typography>
            </Box>
          )}
          <Box className="content-layout" marginTop="32px">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              {/* {isSubscribed ? ( */}
                <>
                  {/* Tabs for "My Essay" and "All Essays" */}
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                  >
                    <Tab
                      sx={{
                        borderBottom: activeTab === 0 ? 2 : 0,
                        borderColor: "primary.main",
                        fontWeight: 900,
                        fontSize: "15px",
                        textTransform: "none",
                      }}
                      label="My Essays"
                    />
                    <Tab
                      sx={{
                        borderBottom: activeTab === 1 ? 2 : 0,
                        borderColor: "primary.main",
                        fontWeight: 900,
                        fontSize: "15px",
                        textTransform: "none",
                      }}
                      label="All Essays"
                    />
                  </Tabs>
                  {/* Search and Filter Controls */}
                  <Box sx={{ display: "flex", gap: 2, m: 3 }}>
                    <TextField
                      placeholder="Search essay..."
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      sx={{ flexGrow: 1, mt: 2 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <TableContainer className="table-listing">
                    <Table
                      // sx={{ minWidth: 650 }}
                      aria-label="table"
                      className="listing-table"
                    >
                      <TableHead>
                        <TableRow className="head-row">
                          <TableCell align="left" sx={{ paddingLeft: "30px" }}>
                            Exam Name
                          </TableCell>
                          <TableCell align="center">
                            {activeTab === 0
                              ? "Remove From Favourites"
                              : " Add To Favourites"}
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {filteredExams?.length === 0 ? (
                          <TableRow className="no-msg">
                            <TableCell
                              colSpan={2}
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                              }}
                              className="no-msg"
                            >
                              {activeTab === 0
                                ? "No Essay Exams Availabe In Your Favorites "
                                : "Essay Exams Not Found"}
                            </TableCell>
                          </TableRow>
                        ) : (
                          filteredExams?.map((exam) => {
                            const isButtonDisabled =
                              favourites?.length >= 2 && !isFavorite(exam.id);

                            return (
                              <TableRow
                                key={exam.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell
                                  // align="center"
                                  sx={{ paddingLeft: "30px" }}
                                  onClick={() => {
                                    navigate(`/exams/${exam?.id}/view`);
                                  }}
                                >
                                  {exam?.name}
                                </TableCell>
                                <TableCell
                                  // align="left"
                                  sx={{
                                    display: "flex",
                                    // border: "2px solid red",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  
                                >
                                  <Tooltip
                                    title={
                                      isButtonDisabled
                                        ? "You can have at most 2 essays in favourite list"
                                        : ""
                                    }
                                    placement="bottom"
                                    arrow
                                  >
                                    <span>
                                      <Button
                                       
                                        onClick={() => {
                                          if (isFavorite(exam.id)) {
                                            setremovableItemFromFav(exam)
                                            setOpenRemoveFavModal(true)
                                          }
                                          else {
                                            toggleFavorite(exam)
                                          }
                                        }}
                                        disabled={
                                          isButtonDisabled || isLoadingFavorite
                                        }
                                        style={{
                                          cursor: isButtonDisabled
                                            ? "not-allowed"
                                            : "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                          // border: "2px solid green",
                                        }}
                                      >
                                        {/* Tooltip on MdFavoriteBorder icon */}

                                        {isFavorite(exam.id) ? (
                                          <MdFavorite className="text-red-500 w-6 h-6" />
                                        ) : (
                                          <MdFavoriteBorder className="text-gray-600 w-6 h-6" />
                                        )}
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
             
            </Box>
          </Box>
        </>
      )}

<RemoveFavModal
        openRemoveFavModal={openRemoveFavModal}
        setOpenRemoveFavModal={setOpenRemoveFavModal}
        removableItemFromFav={removableItemFromFav}
        toggleFavorite={toggleFavorite}
      />
    </>
  );
};

export default UsersExamList;
