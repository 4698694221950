import { Box, Fade, Modal, Typography } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';

const RemoveFavModal = ({
    openRemoveFavModal,setOpenRemoveFavModal,removableItemFromFav,toggleFavorite
}) => {

    const handleRemove=()=>{
        toggleFavorite(removableItemFromFav)
        setOpenRemoveFavModal(false)
    }
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openRemoveFavModal}
                onClose={()=>setOpenRemoveFavModal(false)}
                closeAfterTransition
            >
                <Fade in={openRemoveFavModal}>
                    <Box className="modal-wrapper modal-ui" sx={{maxWidth:'600px'}}>
                        <Box className="modal-body">
                            <Box variant="div" component="div" className="title">
                                <Typography variant="h3" component="h3">
                                    {`Are you sure to remove "${removableItemFromFav?.name}" from favourites?`}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    This action can’t be undone.
                                </Typography>
                            </Box>
                            <Box
                                variant="div"
                                component="div"
                                className="body"
                            ></Box>
                            <Box variant="div" component="div" className="footer">
                                <Box
                                    variant="div"
                                    component="div"
                                    className="btn-group"
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className="primary-btn btn"
                                        disableRipple
                                        onClick={() => {
                                           handleRemove()
                                        }}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className="secondary-btn btn"
                                        disableRipple
                                        onClick={() => setOpenRemoveFavModal(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default RemoveFavModal;
