import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authentication/authSlice";
import feedbackReducer from "./slices/feedback/feedbackSlice";
import signupReducer from "./slices/authentication/signupSlice";
import forgetPasswordReducer from "./slices/authentication/forgetPasswordSlice";
import resetPasswordReducer from "./slices/authentication/resetPasswordSlice";
import logoutReducer from "./slices/authentication/logoutSlice";
import examReducer from "./slices/exams/examSlice";
import examByIdReducer from "./slices/exams/examByIdSlice";
import deleteExamReducer from "./slices/exams/deleteExamSlice";
import userReducer from "./slices/users/userSlice";
import userByIdReducer from "./slices/users/userByIdSlice";
// import createExamReducer from "./slices/exams/createExamSlice";
import settingsReducer from "./slices/settings/fetchSettings";
import createGrammarReducer from "./slices/grammar/createGrammarSlice";
import grammarReducer from "./slices/grammar/grammarSlice";
import grammarByIdReducer from "./slices/grammar/grammarByIdSlice";
import editExamByIdReducer from "./slices/exams/editExamByIdSlice";
import editGrammarByIdReducer from "./slices/grammar/editGrammarByIdSlice";
import deleteGrammarReducer from "./slices/grammar/deleteGrammarSlice";
import createConversationReducer from "./slices/interaction/createConversationSlice";
import comprehensionReducer from "./slices/comprehension/comprehensionSlice";
import comprehensionByIdReducer from "./slices/comprehension/comprehensionByIdSlice";
import editComprehensionByIdReducer from "./slices/comprehension/editComprehensionByIdSlice";
import deleteComprehensionReducer from "./slices/comprehension/deleteComprehensionSlice";
import editSettingsReducer from "./slices/settings/editSettingsSlice";
import userRolesReducer from "./slices/role/userRoleSlice";
import attemptExamReducer from "./slices/exams/attemptExamSlice";
import attemptGrammarReducer from "./slices/grammar/attemptGrammarSlice";
import attemptComprehensionReducer from "./slices/comprehension/attemptComprehensionSlice";
import loaderSlice from "./slices/loader/loaderSlice";
import conversationReducer from "./slices/interaction/conversationSlice";
import conversationByIdReducer from "./slices/interaction/conversationByIdSlice";
import startConversationReducer from "./slices/interaction/startConversationSlice";
import userDashboardReducer from "./slices/dashBoardSlice/index";
import deleteConversationReducer from "./slices/interaction/deleteConversationSlice";
import subscriptionReducer from "./slices/subscription/subscriptionSlice";
import subscriptionByIdReducer from "./slices/subscription/subscriptionByIdSlice";
import createSubscriptionReducer from "./slices/subscription/createSubscriptionSlice";
import userSubscriptionReducer from "./slices/subscription/userSubscriptionSlice";
import changeSubscriptionReducer from "./slices/subscription/changeSubscriptionSlice";
import cancelSubscriptionReducer from "./slices/subscription/cancelSubscriptionSlice";
import changePasswordReducer from "./slices/authentication/changePasswordSlice";
import editUserByIdReducer from "./slices/users/editUserByIdSlice";
import contactReducer from "./slices/contact/contactSlice";
import comprehensionCategoryReducer from "./slices/category/categorySlice";
import favouriteReducer from "./slices/favourite/favouriteSlice";
import dictionaryReducer from "./slices/dictionary/getDictionarySlice";
import promocodesReducer from "./slices/settings/promocodesSlice";
import { Favorite } from "@mui/icons-material";

const rootReducer = combineReducers({
  loader: loaderSlice,
  feedback: feedbackReducer,
  auth: authReducer,
  signup: signupReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,
  logout: logoutReducer,
  exams: examReducer,
  examById: examByIdReducer,
  deleteExams: deleteExamReducer,
  users: userReducer,
  userById: userByIdReducer,
  settings: settingsReducer,
  createGrammar: createGrammarReducer,
  createComprehension: createConversationReducer,
  grammar: grammarReducer,
  comprehension: comprehensionReducer,
  grammarById: grammarByIdReducer,
  comprehensionById: comprehensionByIdReducer,
  editExam: editExamByIdReducer,
  editGrammar: editGrammarByIdReducer,
  deleteGrammar: deleteGrammarReducer,
  editComprehension: editComprehensionByIdReducer,
  deleteComprehension: deleteComprehensionReducer,
  editSetting: editSettingsReducer,
  userRoles: userRolesReducer,
  attemptExam: attemptExamReducer,
  attemptGrammar: attemptGrammarReducer,
  attemptComprehension: attemptComprehensionReducer,
  createConversation: createConversationReducer,
  conversations: conversationReducer,
  conversationsById: conversationByIdReducer,
  startConversation: startConversationReducer,
  userDashboard: userDashboardReducer,
  deleteConversation: deleteConversationReducer,
  subscriptions: subscriptionReducer,
  subscriptionById: subscriptionByIdReducer,
  createSubscription: createSubscriptionReducer,
  userSubscription: userSubscriptionReducer,
  changeSubscription: changeSubscriptionReducer,
  cancelSubscription: cancelSubscriptionReducer,
  changePassword: changePasswordReducer,
  editUser: editUserByIdReducer,
  contact: contactReducer,
  comprehensionCategory: comprehensionCategoryReducer,
  favourite: favouriteReducer,
  dictionary:dictionaryReducer,
  promocodes:promocodesReducer
  // createExam: createExamReducer,
});

const store = configureStore({
  reducer: rootReducer,
  // No need to explicitly include thunk here
});

export default store;
