import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { GoAlertFill } from "react-icons/go";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import { setLoading } from "../../../store/slices/loader/loaderSlice";
import Loader from "../../../common/loader";
import { fetchDictionaryById } from "../../../store/slices/dictionary/getDictionarySlice";

const ViewWord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comprehension, setComprehension] = useState("");
  const userState = useSelector((state) => state.userById.user);
  const [isSubscribed, setisSubscribed] = useState(false);
  const [explanationIndex, setexplanationIndex] = useState(0);

  const { id } = useParams();
  const word = useSelector((state) => state.dictionary.wordById);
  const { isLoading } = useSelector((state) => state.loader);

  const formik = useFormik({
    initialValues: {
      name: "",
      question_type: "",
    },
  });

  useEffect(() => {
    // Initial fetch
    dispatch(fetchDictionaryById(id));

    // Interval to refetch if the message indicates it is still being generated
    const interval = setInterval(() => {
      if (
        word?.message ===
        "Word explanation is being generated. Please check back later."
      ) {
        dispatch(fetchDictionaryById(id));
      }
    }, 3000); // 3 seconds interval

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch, word?.message]);

  const generateNewExplanation = () => {
    if (word?.data?.explanations?.length > 0) {
      setexplanationIndex(
        (prevIndex) => (prevIndex + 1) % word?.data?.explanations?.length
      ); // Loop through explanations
    }
    // Optionally, refetch the data if no explanations are available
    dispatch(fetchDictionaryById(id));
  };

  const subscription = useSelector(setSubscriptionFromApi);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  // const isSubscribed =
  //   subscription && listOfPlans.some((plan) => subscription.includes(plan));

  useEffect(() => {
    // If no subscription, check comprehension practice
    if (!subscription) {
      if (userState?.comprehension_practice_left === 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left !== 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const hasValidPlan =
      subscription && listOfPlans?.some((plan) => subscription.includes(plan));
    if (!hasValidPlan) {
      if (userState?.comprehension_practice_left === 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left !== 0);
        return;
      }
    }
    setisSubscribed(!!hasValidPlan);
  }, [subscription, userState]);

  return (
    <>
      {
        isLoading ? (
          <Loader />
        ) : (
          // isSubscribed ? (
          <>
            <Box className="content-header-title">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Button
                  className="action-btn"
                  onClick={() => navigate(`/dictionary`)}
                >
                  <IoMdArrowRoundBack className="icon-font" />
                </Button>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography variant="h2" component="h2">
                    View Word Details
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="content-layout">
              <Box
                variant="div"
                component="div"
                className="p-4"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Box variant="div" component="div" flex={1}>
                    <Typography
                      variant="body1"
                      component="label"
                      className="label"
                    >
                      Word
                    </Typography>

                    <Typography
                      variant="body2"
                      component="span"
                      className="value"
                    >
                      {word?.data?.word}
                    </Typography>
                  </Box>

                  <Box variant="div" component="div" className="btn-group">
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      // type="submit"
                      disableRipple
                      onClick={() => {
                        navigate(
                          `/dictionary/${id}/attempt`
                        );
                      }}
                    >
                      {" "}
                      Quick Practice
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="content-layout">
              <TableContainer className="table-listing">
                <Table
                  // sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow className="head-row">
                      <TableCell align="left">Explanation</TableCell>
                      <TableCell align="right">
                        <Box style={{ height: "35px" }}>
                          <Button
                            variant="contained"
                            size="large"
                            className="primary-btn btn add-btn"
                            disableRipple
                            onClick={generateNewExplanation}
                          >
                            <Typography variant="body1" component="p">
                              View Alternate Explanation
                            </Typography>
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {word?.message ===
                    "Word explanation is being generated. Please check back later." ? (
                      <TableRow className="no-msg">
                        <TableCell
                          colSpan={4}
                          style={{
                            width: "165%",
                            // border:'2px solid red',
                            // textAlign: "center",
                            fontSize: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="no-msg"
                        >
                          Explanations are being generated in background&nbsp;
                          <CircularProgress
                            style={{
                              height: "25px",
                              width: "25px",
                              marginTop: "5px",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      // word?.data?.explanations?.map((w, index) => (
                      <TableRow
                        key={word?.data?.explanations[explanationIndex]?.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          align="left"
                          key={word?.data?.explanations[explanationIndex]?.id}
                          colSpan={2}
                          className="attempt-exam"
                        >
                          <ReactMarkdown>
                            {word?.data?.explanations[explanationIndex]?.value}
                          </ReactMarkdown>
                        </TableCell>
                      </TableRow>
                      // ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box className="content-layout">
              <TableContainer className="table-listing">
                <Table aria-label="table" className="listing-table">
                  <TableHead>
                    <TableRow className="head-row">
                      <TableCell align="left">Previous Attempts</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {word?.data?.attempts?.length === 0 ? (
                      <TableRow className="no-msg">
                        <TableCell
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="no-msg"
                        >
                          No attempts found for this exam{" "}
                        </TableCell>
                      </TableRow>
                    ) : (
                      word?.data?.attempts?.map((w, index) => (
                        <TableRow
                          key={w.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              `/dictionary/${w?.id}/feedback`
                            )
                          }
                        >
                          <TableCell align="left" key={w?.id} colSpan={2}>
                            Attempted on{" "}
                            {moment(w?.created_at).format("MM-DD-YYYY")}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )
        
      }
    </>
  );
};
export default ViewWord;
