import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { getToken } from "../../../utils/token";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const guideOnFeedbackSlice = createSlice({
  name: "createGrammar",
  initialState,
  reducers: {
    guideOnFeedbackStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    guideOnFeedbackSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    guideOnFeedbackFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { guideOnFeedbackStart, guideOnFeedbackSuccess, guideOnFeedbackFailure } =
  guideOnFeedbackSlice.actions;

export default guideOnFeedbackSlice.reducer;

export const generateGuidenceOnFeedback = (payload, module) => async (dispatch) => {
  try {
    
    dispatch(guideOnFeedbackStart());
    const token = getToken();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/openessayme-guide/feedback-page/?module=${module}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(guideOnFeedbackSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data?.data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(guideOnFeedbackFailure(error.message));

    return { success: false, error: error?.response?.message };
  }
};
