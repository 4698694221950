import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { getToken } from "../../../utils/token";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const guideOnViewSlice = createSlice({
  name: "createGrammar",
  initialState,
  reducers: {
    guideOnViewStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    guideOnViewSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    guideOnViewFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { guideOnViewStart, guideOnViewSuccess, guideOnViewFailure } =
  guideOnViewSlice.actions;

export default guideOnViewSlice.reducer;

export const generateGuidenceOnView = (payload, module) => async (dispatch) => {
  try {
    
    dispatch(guideOnViewStart());
    const token = getToken();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/openessayme-guide/view-page/?module=${module}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(guideOnViewSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, data: data?.data };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(guideOnViewFailure(error.message));

    return { success: false, error: error?.response?.message };
  }
};
