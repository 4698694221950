import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { Search as SearchIcon } from "@mui/icons-material";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGrammar } from "../../../store/slices/grammar/grammarSlice";
import { GoAlertFill } from "react-icons/go";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import Loader from "../../../common/loader";
import { fetchCategories } from "../../../store/slices/category/categorySlice";
import { getToken } from "../../../utils/token";
import {
  addTofavourites,
  removeFromfavourites,
  fetchUserfavourites,
} from "../../../store/slices/favourite/favouriteSlice";
import { errorToast } from "../../../response/errorToast";
import { fetchUserById } from "../../../store/slices/users/userByIdSlice";
import RemoveFavModal from "../../../common/removeFavModal";

const UserGrammarList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const grammar = useSelector((state) => state.grammar.grammar);
  const err = useSelector((state) => state.grammar.error);
  const favourites = useSelector(
    (state) => state.favourite.favourites?.favourites.grammar_rule
  );
  const [localFavorites, setLocalFavorites] = useState(new Set());
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [filteredRules, setFilteredRules] = useState([]);
  const [categories, setCategories] = useState([]);
  const token = getToken();
  const [activeTab, setActiveTab] = useState(0);
  const { isLoading } = useSelector((state) => state.loader);
  const subscription = useSelector(setSubscriptionFromApi);
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);
  const userState = useSelector((state) => state.userById.user);
  const [isSubscribed, setisSubscribed] = useState(false);
  const [hasValidPlan, sethasValidPlan] = useState(false);
  const [openRemoveFavModal, setOpenRemoveFavModal] = useState(false);
  const [removableItemFromFav, setremovableItemFromFav] = useState();

  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "tutor_yearly",
    "intermediate_yearly",
  ];

  useEffect(() => {
    const favoriteIds = new Set(favourites?.map((fav) => fav.item_id));
    setLocalFavorites(favoriteIds);
  }, [favourites, activeTab]);

  const isFavorite = useCallback(
    (compId) => {
      return localFavorites?.has(compId);
    },
    [localFavorites]
  );

  const toggleFavorite = async (grammar) => {
    const payload = {
      module_name: "grammar_rule",
      item_id: [grammar.id],
    };

    try {
      setIsLoadingFavorite(true);

      const newLocalFavorites = new Set(localFavorites);
      // Optimistically update local state
      if (isFavorite(grammar.id)) {
        // grammar={...grammar,type:"grammar"}

        newLocalFavorites.delete(grammar.id);
        await dispatch(removeFromfavourites(payload));

      } else {
        newLocalFavorites.add(grammar.id);
        await dispatch(addTofavourites(payload));
      }

      // Update local favorites immediately
      setLocalFavorites(newLocalFavorites);
      dispatch(fetchUserfavourites());
    } catch (error) {
      // Revert local state if API call fails
      console.error("Failed to toggle favorite:", error);
      errorToast(["Failed to update favorites"]);
    } finally {
      setIsLoadingFavorite(false);
    }
  };

  const fetchGrammarCategories = async () => {
    try {
      const response = await dispatch(
        fetchCategories((module = "grammar"), token)
      );

      if (response.success) {
        setCategories(response.data.grammar_rule_categories || []);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Filter and search logic
  useEffect(() => {
    if (grammar?.grammar_rules) {
      let filtered = [...grammar?.grammar_rules];

      // Apply category filter
      if (selectedCategory !== "all") {
        filtered = filtered?.filter(
          (rule) => rule.category_name === selectedCategory
        );
      }

      // Apply search filter
      if (searchQuery) {
        const query = searchQuery?.toLowerCase();
        filtered = filtered?.filter(
          (rule) =>
            rule.name.toLowerCase().includes(query) ||
            (rule.category_name &&
              rule.category_name.toLowerCase().includes(query))
        );
      }

      // filter For My grammars
      if (activeTab === 0) {
        filtered = filtered?.filter((g) => {
          if (Array.isArray(favourites)) {
            const hasFavourite = favourites?.some((fav) => {
              const isMatch = fav.item_id === g.id;
              return isMatch;
            });
            return hasFavourite;
          }
          return false;
        });
      }

      // filter For Suggested grammars
      if (activeTab === 1) {
        filtered = filtered?.filter((g) => {
          if (g?.is_suggested) {
            return g;
          }
          return false;
        });
      }

      // Sort filtered rules by category_name in ascending order
      filtered?.sort((a, b) => a?.category_name?.localeCompare(b?.category_name));

      setFilteredRules(filtered);
    }
  }, [
    grammar?.grammar_rules,
    selectedCategory,
    searchQuery,
    activeTab,
    isFavorite,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    dispatch(fetchGrammar({}));
    fetchGrammarCategories();
    dispatch(fetchUserfavourites());
   
  }, [dispatch, isCheckingSubscription, isSubscribed, activeTab]);

  useEffect(() => {
    if (subscription !== undefined) {
      setIsCheckingSubscription(false);
    }
  }, [subscription]);

  useEffect(() => {
    // If no subscription, check comprehension practice
    if (!subscription) {
      if (userState?.grammar_rule_practice_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.grammar_rule_practice_left > 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const validPlan = subscription && listOfPlans?.some((plan) =>
      subscription.includes(plan)
    );
    sethasValidPlan(validPlan)
    if (!validPlan) {
      if (userState?.grammar_rule_practice_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.grammar_rule_practice_left > 0);
        return;
      }
    }
    setisSubscribed(!!validPlan);
  }, [subscription, userState]);

  useEffect(() => {
    if (token && userState) {
      dispatch(fetchUserById(userState?.id, token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading || isCheckingSubscription ? (
        <Loader />
      ) : (
        <>
          {
            (!subscription || (subscription && !hasValidPlan)) &&
            (
              <Box className="left-attempt-container">
                <Typography>
                  Enjoy <span style={{ color: "black" }}>4</span> Complimentary
                  Grammar Rules Sessions for free. Unlock More with Our Premium
                  Plans.
                </Typography>
                <Typography>
                  Free Grammar Rules Left :&nbsp;
                  <span style={{ color: "black" }}>
                    {" "}
                    {userState?.grammar_rule_practice_left}&nbsp;/&nbsp;4
                  </span>
                </Typography>
              </Box>
            )}
          <Box className="content-layout" marginTop="32px">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              {/* {isSubscribed ? ( */}
              <>
                {/* Tabs for "My Grammar" and "All Grammars" */}
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    sx={{
                      borderBottom: activeTab === 0 ? 2 : 0,
                      borderColor: "primary.main",
                      fontWeight: 900,
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                    label="My Grammars"
                  />
                  <Tab
                    sx={{
                      borderBottom: activeTab === 1 ? 2 : 0,
                      borderColor: "primary.main",
                      fontWeight: 900,
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                    label="Suggested"
                  />
                  <Tab
                    sx={{
                      borderBottom: activeTab === 2 ? 2 : 0,
                      borderColor: "primary.main",
                      fontWeight: 900,
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                    label="All"
                  />
                </Tabs>
                {/* Search and Filter Controls */}
                <Box sx={{ display: "flex", gap: 2, m: 3, flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', gap: '15px' }}>
                    <TextField
                      placeholder="Search grammar rules..."
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      sx={{ flexGrow: 1, mt: 2 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      select
                      size="small"
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      sx={{ minWidth: 200, mt: 2 }}
                    >
                      <MenuItem value="all">All Categories</MenuItem>
                      {categories.map((category) => (
                        <MenuItem key={category?.name} value={category?.name}>
                          {category?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  {activeTab === 1 && <Typography sx={{ fontSize: '13.5px', color: '#1976d2 !important' }}>Based on your recent Essay Practices, OpenEssayMe recommends focusing on the following areas to enhance your writing skills</Typography>}
                </Box>
                <TableContainer className="table-listing" sx={{ marginTop: activeTab === 1 ? '-20px' : 'none' }}>
                  <Table aria-label="table" className="listing-table">
                    <TableHead>
                      <TableRow className="head-row">
                        <TableCell
                          align="left"
                          sx={{ paddingLeft: "30px", width: "50%" }}
                        >
                          Rule Name
                        </TableCell>
                        {selectedCategory === "all" && (
                          <TableCell align="left" sx={{ width: "30%" }}>
                            Category
                          </TableCell>
                        )}
                        <TableCell align="center">
                          {activeTab === 0
                            ? "Remove From Favourites"
                            : " Add To Favourites"}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredRules?.length === 0 ? (
                        <TableRow className="no-msg">
                          <TableCell
                            colSpan={selectedCategory === "all" ? 3 : 2}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                            className="no-msg"
                          >
                            {activeTab === 0
                              ? "No Grammar Rules Availabe In Your Favorites"
                              : "No Grammar Rules Found"}
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredRules?.map((rule) => {
                          const isButtonDisabled =
                            favourites?.length === 6 && !isFavorite(rule.id);
                          return (
                            <TableRow
                              key={rule.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell
                                align="left"
                                sx={{ paddingLeft: "30px" }}
                                onClick={() =>
                                  navigate(`/grammar/${rule?.id}/view`)
                                }
                              >
                                {rule?.name}
                              </TableCell>
                              {selectedCategory === "all" && (
                                <TableCell align="left" onClick={() =>
                                  navigate(`/grammar/${rule?.id}/view`)
                                }>
                                  {rule?.category_name}

                                </TableCell>
                              )}
                              <TableCell
                                align="center"
                                sx={{
                                  display: "flex",
                                  // border: "2px solid red",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Tooltip
                                  title={
                                    isButtonDisabled
                                      ? "You can have at most 6 Grmmar Rules in favourite list"
                                      : ""
                                  }
                                  placement="bottom"
                                  arrow
                                >
                                  <span>
                                    <Button
                                      disabled={
                                        isButtonDisabled || isLoadingFavorite
                                      }
                                      onClick={() => {
                                        if (isFavorite(rule.id)) {
                                          setremovableItemFromFav(rule)
                                          setOpenRemoveFavModal(true)
                                        }
                                        else {
                                          toggleFavorite(rule)
                                        }
                                      }}
                                      style={{
                                        cursor: isButtonDisabled
                                          ? "not-allowed"
                                          : "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {isFavorite(rule.id) ? (
                                        <MdFavorite className="text-red-500 w-6 h-6" />
                                      ) : (
                                        <MdFavoriteBorder className="text-gray-600 w-6 h-6" />
                                      )}
                                    </Button>
                                  </span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>

            </Box>
          </Box>
        </>
      )}


      <RemoveFavModal
        openRemoveFavModal={openRemoveFavModal}
        setOpenRemoveFavModal={setOpenRemoveFavModal}
        removableItemFromFav={removableItemFromFav}
        toggleFavorite={toggleFavorite}
      />
    </>
  );
};

export default UserGrammarList;
