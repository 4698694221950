import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
import { errorToast } from "../../../response/errorToast";
import { successToast } from "../../../response/successToast";

const initialState = {
    promocodes: null,
  status: "idle",
  error: null,
};

const promocodesSlice = createSlice({
  name: "promocodes",
  initialState,
  reducers: {
    fetchpromocodesStart: (state) => {
      state.status = "loading";
    },
    fetchpromocodesSuccess: (state, action) => {
      state.status = "succeeded";
      state.promocodes = action.payload;
      state.error = null; // Reset error when fetching succeeds
    },
    fetchpromocodesFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const {
  fetchpromocodesStart,
  fetchpromocodesSuccess,
  fetchpromocodesFailure,
} = promocodesSlice.actions;

export const fetchpromocodes = () => async (dispatch) => {
  try {
    dispatch(fetchpromocodesStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/settings/promocode/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(fetchpromocodesSuccess(response?.data));
  } catch (error) {
    errorToast(error?.response?.data?.errors);
    dispatch(fetchpromocodesFailure(error?.message));
  }
};


export const addpromocode = (data) => async (dispatch) => {
  try {
    const token = getToken();
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/settings/promocode/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
   successToast("Promocode created successfully")
   
  } catch (error) {
    errorToast(error?.response?.data?.errors);
  }
};


export const editpromocode = (id,data) => async (dispatch) => {
  try {
    const token = getToken();
    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/settings/promocode/${id}/edit`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
   successToast("Promocode updated successfully")
   
  } catch (error) {
    errorToast(error?.response?.data?.errors);
  }
};


export const deletepromocode = (id) => async (dispatch) => {
  try {
    const token = getToken();
    const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/settings/promocode/${id}/delete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
   successToast("Promocode deleted successfully")
   
  } catch (error) {
    errorToast(error?.response?.data?.errors);
  }
};

export default promocodesSlice.reducer;
