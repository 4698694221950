import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete"; //
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { editExam } from "../../../store/slices/exams/editExamByIdSlice";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Uploader } from "rsuite";
import { setLoading } from "../../../store/slices/loader/loaderSlice";

// const modules = {
//   toolbar: [
//     ["bold", "italic", "underline", "blockquote"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     [{ header: [1, 2, 3, 4, 5, 6] }],
//     ["link", "image"],
//   ],
// };

const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

const AddInstructions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [examDataById, setExamDataById] = useState();
  const [image, setImage] = useState(); // State to store image preview URL
  const fileInputRef = useRef();
  const [preview, setPreview] = useState(null); // State to store image preview URL
  const [editableData, setEditableData] = useState({});
  const token = getToken();
  const { id } = useParams();
  const [instructionPrompt, setInstructionPrompt] = useState();
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      name: "", // Set initial values from stored credentials
      exam_prompt: "",
      feedback_prompt: "",
      description: "",
      instructions: "",
    },
    onSubmit: (values) => {
      let updatedJSON = JSON.stringify(editableData);

      // Sanitize the string to remove \r\n and extra spaces
      updatedJSON = updatedJSON
        .replace(/\\r\\n/g, " ")
        .replace(/\s+/g, " ")
        .trim();

      let payload = {
        name: values.name,
        description: values.description,
        exam_prompt: values.exam_prompt,
        feedback_prompt: values.feedback_prompt,
        description: values.description,
        instructions: values.instructions,
        instructions_prompt: instructionPrompt,
        exam_overview_details: updatedJSON,
      };
      if (image) payload = { ...payload, exam_image: image };
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          formData.append(key, payload[key]);
        }
      }

      dispatch(editExam(id, formData, token))
        .then((response) => {
          if (response.success) {
            successToast("Exam updated successfully");
            navigate("/exams");
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exams/view/${id}/`,
      });
      setExamDataById(response);
      setLoading(false);
    } catch (error) {
      errorToast(error?.response?.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const preprocessData = (data) => {
      if (!data || typeof data !== "object") return {};

      return Object.entries(data).reduce((acc, [key, value]) => {
        // If the value is a string, transform it into an object with the same key
        acc[key] = typeof value === "string" ? { [key]: value } : value;
        return acc;
      }, {});
    };

    const transformedData = preprocessData(
      examDataById?.data?.exam_overview_details
    );
    setEditableData(transformedData);
  }, [examDataById]);

  useEffect(() => {
    formik.setValues({
      name: examDataById?.data?.name || "",
      exam_prompt: examDataById?.data?.exam_prompt || "",
      feedback_prompt: examDataById?.data?.feedback_prompt || "",
      description: examDataById?.data?.description || "",
      instructions: examDataById?.data?.instructions || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  const handleInstructionsChange = (value) => {
    formik.setFieldValue("instructions", value);
  };
  const handleInstructionsPromptChange = () => {
    formik.setFieldValue("instruction_propmt", instructionPrompt);
  };

  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      setImage(file);
      setPreview(URL.createObjectURL(file)); // Generate a preview URL
    }
    formik.setFieldValue("exam_image", file);
  };

  const fetchSetting = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/view`,
      });
      if (examDataById?.data?.instructions_prompt) {
        setInstructionPrompt(examDataById?.data?.instructions_prompt);
      } else {
        setInstructionPrompt(
          response?.settings?.EXAM_OVERVIEW_INSTRUCTION_PROMPT
        );
      }
    } catch (error) {
      console.error(error);
      errorToast(error.response.data.errors);
    }
  };
  useEffect(() => {
    fetchSetting();
  }, [examDataById]);

  const handleAddField = (path) => {
    setEditableData((prevData) => {
      const keys = path.split(".");
      const updatedData = { ...prevData };
      let current = updatedData;

      keys.forEach((key) => {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      });

      const newKey = `new_key_${Object.keys(current).length + 1}`;
      current[newKey] = "";
      return updatedData;
    });
  };

  const handleDeleteField = (path, keyToDelete) => {
    setEditableData((prevData) => {
      const keys = path.split(".");
      const updatedData = { ...prevData };
      let current = updatedData;

      keys.forEach((key) => {
        current = current[key];
      });

      delete current[keyToDelete];
      return updatedData;
    });
  };

  const handleChange = (path, value) => {
    setEditableData((prevData) => {
      const keys = path.split(".");
      const updatedData = JSON.parse(JSON.stringify(prevData)); // Deep clone
      let current = updatedData;

      keys.slice(0, -1).forEach((key) => {
        if (!current[key]) current[key] = {}; // Ensure path exists
        current = current[key];
      });

      current[keys[keys.length - 1]] = value; // Update the value
      return updatedData;
    });
  };

  const handleKeyRename = (path, oldKey, newTitle) => {
    // Sanitize the new title

    const sanitizedTitle = newTitle
      .trim()
      .replace(/\s+/g, " ")
      .replace(/[^a-zA-Z0-9\s]/g, "");

    // Exit early if the sanitized title is empty or hasn't changed
    if (!sanitizedTitle || sanitizedTitle === oldKey) return;

    setEditableData((prevData) => {
      const updatedData = JSON.parse(JSON.stringify(prevData)); // Create a deep clone

      const updateTitleOnly = (obj, currentPath = "") => {
        if (typeof obj !== "object" || obj === null) return obj;

        for (const key in obj) {
          const fullPath = currentPath ? `${currentPath}.${key}` : key;

          if (
            (!path && key === oldKey) || // Top-level match
            (path && fullPath === `${path}.${oldKey}`) // Nested match
          ) {
            // Update the title field without modifying the key
            if (typeof obj[key] === "object" && obj[key] !== null) {
              obj[key].title = sanitizedTitle; // Only update the title
            }
          } else if (typeof obj[key] === "object") {
            updateTitleOnly(obj[key], fullPath); // Recursively handle nested objects
          }
        }

        return obj;
      };

      updateTitleOnly(updatedData);
      return updatedData; // Return the modified data
    });
  };

  const handleSubKeyRename = (path, oldKey, newKey) => {
    if (!newKey || newKey.trim() === "") return;

    // Sanitize the new key
    const sanitizedNewKey = newKey.trim().toLowerCase().replace(/\s+/g, "_");

    // If the new key is the same as the old key, do nothing
    if (sanitizedNewKey === oldKey) return;

    setEditableData((prevData) => {
      const updatedData = JSON.parse(JSON.stringify(prevData)); // Deep clone

      // Recursive function to find and modify the correct nested object
      const renameKey = (obj, targetPath, oldK, newK) => {
        // If we've reached the target path
        if (targetPath.length === 0) {
          // If the new key already exists, do nothing
          if (obj.hasOwnProperty(newK)) return obj;

          // Create a new object with renamed key while preserving order
          const entries = Object.entries(obj);
          const newObj = {};

          for (const [key, value] of entries) {
            if (key === oldK) {
              newObj[newK] = value;
            } else {
              newObj[key] = value;
            }
          }

          return newObj;
        }

        // Traverse deeper into nested objects
        const [currentKey, ...remainingPath] = targetPath;
        if (typeof obj[currentKey] === "object" && obj[currentKey] !== null) {
          obj[currentKey] = renameKey(
            obj[currentKey],
            remainingPath,
            oldK,
            newK
          );
        }

        return obj;
      };

      // Split the path into keys
      const pathKeys = path ? path.split(".") : [];

      // Modify the data
      return renameKey(updatedData, pathKeys, oldKey, sanitizedNewKey);
    });
  };

  const renderFields = (data, path = "", isOuterKey = false) => {
    if (!data || typeof data !== "object") return null;

    return Object.entries(data).map(([key, value]) => {
      const currentPath = path ? `${path}.${key}` : key;

      const displayTitle =
        typeof value === "object" && value.title
          ? value.title
          : key
              .toLowerCase()
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

      const valueWithoutTitle =
        typeof value === "object" && value.title
          ? Object.fromEntries(
              Object.entries(value).filter(([k]) => k !== "title")
            )
          : value;

      return typeof value === "object" && !(value instanceof Array) ? (
        <Accordion key={currentPath} sx={{ marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <TextField
              key={`${currentPath}-title`}
              label="Section Title"
              defaultValue={displayTitle}
              onBlur={(e) => {
                const newTitle = e.target.value.trim();
                handleKeyRename(path, key, newTitle);
              }}
              onClick={(e) => e.stopPropagation()}
              sx={{
                marginRight: 2,
                width: "100%",
              }}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ marginLeft: 2 }}>
              {renderFields(valueWithoutTitle, currentPath)}
              <Button
                variant="outlined"
                onClick={() => handleAddField(currentPath)}
                sx={{
                  marginTop: 2,
                  width: "100%",
                  color: "#43acef",
                }}
                startIcon={
                  <AddIcon sx={{ fill: "#43acef", fontSize: "20px" }} />
                }
              >
                Add New Section
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box
          key={`${currentPath}`}
          display="flex"
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            {isOuterKey ? (
              <Typography
                sx={{
                  marginRight: 2,
                  width: "30%",
                }}
              >
                {key}
              </Typography>
            ) : (
              <TextField
                key={`${currentPath}-details`}
                label="Sub-Section Name"
                defaultValue={key
                  .toLowerCase()
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
                onBlur={(e) => {
                  const newKey = e.target.value
                    .trim()
                    .toLowerCase()
                    .replace(/\s+/g, "_");

                  handleSubKeyRename(path, key, newKey);
                }}
                sx={{
                  marginRight: 2,
                  width: "30%",
                }}
              />
            )}
            <TextField
              // defaultValue="Add relavant details..."
              key={currentPath}
              label="Details"
              fullWidth
              multiline
              maxRows={5}
              value={value}
              onChange={(e) => handleChange(currentPath, e.target.value)}
              sx={{ flexGrow: 1 }}
            />
          </Box>
          <Button
            onClick={() => handleDeleteField(path, key)}
            sx={{
              marginLeft: 2,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              minWidth: "unset",
            }}
          >
            <DeleteIcon sx={{ fill: "#fa4a41" }} />
          </Button>
        </Box>
      );
    });
  };

  const addTopLevelSection = () => {
    const newKey = `new_section_${Object.keys(editableData).length + 1}`;
    setEditableData((prevData) => ({
      ...prevData,
      [newKey]: {},
    }));
  };

  return (
    <>
      <Box className="content-header-title">
        <Button className="action-btn" onClick={() => navigate(`/exams`)}>
          <IoMdArrowRoundBack className="icon-font" />
        </Button>
        <Typography variant="h2" component="h2">
          Exam Information
        </Typography>
      </Box>
      <Box className="content-layout">
        <Box variant="div" component="div" className="createuser-wrap p-24">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                          sx={{ mt: 1 }}
                        >
                          Name of Exam
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          className="value"
                        >
                          {formik.values.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                          sx={{ mt: 1 }}
                        >
                          Exam Instruction Prompt
                        </Typography>
                        <TextField
                          id="outlined-multiline-static"
                          placeholder="Enter exam propmt to generate details..."
                          multiline
                          rows={12}
                          value={instructionPrompt}
                          sx={{ mt: 1 }}
                          fullWidth
                          onChange={(e) => {
                            setInstructionPrompt(e.target.value);
                            handleInstructionsPromptChange();
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                          sx={{ mt: 1 }}
                        >
                          Upload an Image
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: 1,
                          }}
                        >
                          <div style={{ marginBottom: "16px" }}>
                            <input
                              id="file-input"
                              type="file"
                              ref={fileInputRef}
                              accept="image/*" // Accept only image files
                              onChange={handleFileChange} // Handle file selection
                              style={{ display: "none" }} // Hide default input
                            />
                            <label
                              htmlFor="file-input"
                              style={{
                                display: "inline-block",
                                backgroundColor: "#43acef",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "8px",
                                cursor: "pointer",
                                fontWeight: "bold",
                                textAlign: "center",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                transition:
                                  "background-color 0.3s ease, transform 0.2s ease",
                              }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#106cc2")
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#43acef")
                              }
                              onMouseDown={(e) =>
                                (e.currentTarget.style.transform =
                                  "scale(0.95)")
                              }
                              onMouseUp={(e) =>
                                (e.currentTarget.style.transform = "scale(1)")
                              }
                            >
                              Upload Image
                            </label>
                          </div>

                          {(preview || examDataById?.data?.exam_image) && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <img
                                src={
                                  preview ||
                                  `${process.env.REACT_APP_AWS_S3_DOMAIN}/media/${examDataById?.data?.exam_image}`
                                }
                                // src={preview || `${process.env.REACT_APP_API_URL}/media/${examDataById?.data?.exam_image}`}
                                alt="Preview"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                  border: "1px solid #ccc",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                <span>
                                  <strong>File Name:</strong>{" "}
                                  {preview
                                    ? image.name
                                    : "Previously Uploaded Image"}
                                </span>
                                {preview && (
                                  <span>
                                    <strong>File Size:</strong>{" "}
                                    {(image.size / 1024).toFixed(2)} KB
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                          sx={{ mb: 1 }}
                        >
                          Exam Details
                        </Typography>
                        <SunEditor

                          setContents={formik.values.instructions}
                          onChange={handleInstructionsChange}
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "subscript",
                                "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              ["align", "list"],
                              ["outdent", "indent"],

                              ["table", "horizontalRule", "link", "image"],
                            ],
                            defaultTag: "div",
                            minHeight: "400px",
                            showPathLabel: false,
                            font: sortedFontOptions,
                          }}
                        />
                      
                      </Box>
                    </Grid> */}
                    {examDataById?.data?.exam_overview_details && (
                      <Grid item xs={12} md={12}>
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                          sx={{ mb: 2, mt: 1 }}
                        >
                          Edit Exam Overview Details
                        </Typography>
                        <Box>{renderFields(editableData, "", true)}</Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box
              variant="div"
              component="div"
              className="footer-btn"
              style={{ marginTop: "16px", height: "35px" }}
            >
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  type="submit"
                  disableRipple
                >
                  {" "}
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default AddInstructions;
