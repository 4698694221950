import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import { IoMdArrowRoundBack } from "react-icons/io";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { MdOutlineDiscount } from "react-icons/md";
import { FaRegFileWord } from "react-icons/fa";
import { RiChatVoiceLine } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { successToast } from "../../response/successToast";
import { getToken } from "../../utils/token";
import * as Yup from "yup";
import { callApi } from "../../api";
import { errorToast } from "../../response/errorToast";
import { editSetting } from "../../store/slices/settings/editSettingsSlice";
import { useNavigate } from "react-router-dom";
import {
  createCategory,
  fetchCategories,
  updateCategory,
  deleteCategory,
} from "../../store/slices/category/categorySlice";
import { fetchSubscriptions } from "../../store/slices/subscription/subscriptionSlice";
import {
  addpromocode,
  deletepromocode,
  editpromocode,
  fetchpromocodes,
} from "../../store/slices/settings/promocodesSlice";
import AdminDictionary from "./dictionaryPrompts";
import VoicePrompts from "./voicePrompts";
import { Divider } from "rsuite";
import { voices } from "../../utils/helper";
import { FaRegCirclePlay, FaRegCircleStop } from "react-icons/fa6";
import { HiSpeakerWave } from "react-icons/hi2";
import { generateVoiceExplanation } from "../../store/slices/guide/generateVoice";

// Unified validation schema for both categories and grammar rules
const itemValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

const settingsValidationSchema = Yup.object().shape({
  OPENAI_API_KEY: Yup.string().required("Please enter Open AI API Key"),
});

// Generic ItemSection component for both categories and grammar rules
const ItemSection = ({
  title,
  items,
  onAdd,
  onEdit,
  onDelete,
  modalOpen,
  setModalOpen,
  selectedItem,
  setSelectedItem,
  handleSubmit,
  formik,
}) => {
  return (
    <Box
      width="50%"
      sx={{ backgroundColor: "#f0f0f052", borderRadius: "10px" }}
    >
      <Box variant="div" component="div" className="createuser-wrap p-4">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="16px"
        >
          <Typography variant="body1" component="label" className="label">
            {title}
          </Typography>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            onClick={onAdd}
            disableRipple
          >
            Add {title}
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 900 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 900 }} align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.length > 0 ? (
                items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => onEdit(item)}>
                        <MdModeEditOutline />
                      </IconButton>
                      <IconButton onClick={() => onDelete(item.id)}>
                        <RiDeleteBin5Fill />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      padding="20px"
                    >
                      <Typography variant="body1">
                        No {title.toLowerCase()} added yet
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Modal */}
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <form onSubmit={handleSubmit}>
            <Box variant="div" component="div" className="createuser-wrap p-24">
              <Typography
                variant="body1"
                component="label"
                className="label"
                marginBottom="16px"
              >
                {selectedItem ? `Edit ${title}` : `Add New ${title}`}
              </Typography>

              <Box variant="div" component="div" marginBottom="16px">
                <Typography variant="body1" component="label" className="label">
                  Name
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={`Enter ${title.toLowerCase()} name`}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name && formik.errors.name ? (
                      <span className="error-message">
                        {formik.errors.name}
                      </span>
                    ) : null
                  }
                />
              </Box>

              <Box
                variant="div"
                component="div"
                className="footer-btn"
                style={{ marginTop: "16px", height: "35px" }}
              >
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="outlined"
                    size="large"
                    className="secondary-btn btn"
                    onClick={() => setModalOpen(false)}
                    disableRipple
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    type="submit"
                    disableRipple
                  >
                    {selectedItem ? "Update" : "Create"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  // States
  const [setting, setSetting] = useState();
  const [categories, setCategories] = useState([]);
  const [grammarRules, setGrammarRules] = useState([]);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [grammarModalOpen, setGrammarModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGrammarRule, setSelectedGrammarRule] = useState(null);
  const [section, setsection] = useState(null);
  const [openPromocode, setopenPromocode] = useState(false);
  const [openVoice, setopenVoice] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null); // State for selected plan
  const [promocode, setPromocode] = useState("");
  const [promocodeAction, setpromocodeAction] = useState("");
  const [promocodeId, setpromocodeId] = useState("");
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [currentVoice, setcurrentVoice] = useState(null);

  const [audioCache, setAudioCache] = useState({}); // Cache for audio URLs
  
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [playingText, setPlayingText] = useState(null); // Track the currently playing text
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isAudioGenerated, setisAudioGenerated] = useState(false);
  console.log("playingText", playingText);
  console.log("currentAudio", currentAudio);

  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  const promocodes = useSelector((state) => state.promocodes.promocodes);

  const options = [
    {
      label: "Yearly Plans",
      options: subscriptions?.plans?.yearly_plans?.map((plan) => ({
        value: plan.price_id,
        label: plan.product.name,
      })),
    },
    {
      label: "Six-Monthly Plans",
      options: subscriptions?.plans?.six_monthly_plans?.map((plan) => ({
        value: plan.price_id,
        label: plan.product.name,
      })),
    },
    {
      label: "Monthly Plans",
      options: subscriptions?.plans?.monthly_plans?.map((plan) => ({
        value: plan.price_id,
        label: plan.product.name,
      })),
    },
  ];
  const voiceOptions = voices.map((voice) => ({
    value: voice.Name,
    label: `${voice.Name} (${voice.Gender}) (${voice.Language})`, // Custom display format
  }));

  const handlePromocode = async () => {
    if (promocodeAction === "add") {
      await dispatch(
        addpromocode({ code: promocode, plan_id: selectedPlan.value })
      );
    }
    if (promocodeAction === "edit") {
      await dispatch(
        editpromocode(promocodeId, {
          code: promocode,
          plan_id: selectedPlan.value,
        })
      );
    }
    if (promocodeAction === "delete") {
      await dispatch(deletepromocode(promocodeId));
    }

    dispatch(fetchpromocodes());
    handleClosePromocodeModal(); // Close the modal after submission
  };
  const handleVoiceChange = async () => {
    dispatch(editSetting({ VOICE_ID: selectedVoice?.value }, token))
      .then((response) => {
        if (response.success) {
          successToast("Voice updated successfully");
          fetchSettingsData();
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
    setopenVoice(false);
  };

  // Settings Formik
  const settingsFormik = useFormik({
    initialValues: {
      OPENAI_API_KEY: "",
    },
    validationSchema: settingsValidationSchema,
    onSubmit: (values) => {
      dispatch(editSetting(values, token))
        .then((response) => {
          if (response.success) {
            successToast("Setting updated successfully");
            fetchSettingsData();
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  // Category Formik
  const categoryFormik = useFormik({
    initialValues: { name: "" },
    validationSchema: itemValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (selectedCategory) {
          await dispatch(
            updateCategory(
              (module = "comprehension"),
              selectedCategory.id,
              values,
              token
            )
          );
          successToast("Category updated successfully");
        } else {
          await dispatch(
            createCategory((module = "comprehension"), values, token)
          );
          successToast("Category created successfully");
        }
        resetForm();
        setCategoryModalOpen(false);
        setSelectedCategory(null);
        fetchCategoriesData();
      } catch (error) {
        errorToast(error.message);
      }
    },
  });

  // Grammar Rule Formik
  const grammarFormik = useFormik({
    initialValues: { name: "" },
    validationSchema: itemValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (selectedGrammarRule) {
          await dispatch(
            updateCategory(
              (module = "grammar"),
              selectedGrammarRule.id,
              values,
              token
            )
          );
          successToast("Grammar rule updated successfully");
        } else {
          await dispatch(createCategory((module = "grammar"), values, token));
          successToast("Grammar rule created successfully");
        }
        resetForm();
        setGrammarModalOpen(false);
        setSelectedGrammarRule(null);
        fetchGrammarRulesData();
      } catch (error) {
        errorToast(error.message);
      }
    },
  });

  // Fetch Functions
  const fetchSettingsData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/view`,
      });
      setSetting(response);
    } catch (error) {
      console.error(error);
      errorToast(error.response.data.errors);
      if (
        error.response?.data?.errors?.includes(
          "You are not authorized to access this resource."
        )
      ) {
        navigate("*");
      }
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const response = await dispatch(
        fetchCategories((module = "comprehension"), token)
      );
      if (response.success) {
        setCategories(response.data.comprehension_categories);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const fetchGrammarRulesData = async () => {
    try {
      const response = await dispatch(
        fetchCategories((module = "grammar"), token)
      );
      if (response.success) {
        setGrammarRules(response.data.grammar_rule_categories);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  // Effects
  useEffect(() => {
    fetchSettingsData();
    fetchCategoriesData();
    fetchGrammarRulesData();
    dispatch(fetchSubscriptions());
    dispatch(fetchpromocodes());
  }, []);

  useEffect(() => {
    if (promocodeAction === "edit") {
      const p = promocodes?.data?.find((p) => p.promo_code_id === promocodeId);
      const planDetails = p.plan_details;

      const matchedPlan =
        subscriptions?.plans.yearly_plans?.find(
          (plan) => plan.price_id === planDetails.id
        ) ||
        subscriptions?.plans.six_monthly_plans?.find(
          (plan) => plan.price_id === planDetails.id
        ) ||
        subscriptions?.plans.monthly_plans?.find(
          (plan) => plan.price_id === planDetails.id
        );

      setPromocode(p?.promo_code);
      setSelectedPlan({
        value: matchedPlan?.price_id,
        label: matchedPlan?.product.name,
      });
    }
  }, [promocodeAction, promocodes]);

  const setDefaultVoice = () => {
    if (setting?.settings?.VOICE_ID) {
      const defaultVoice = voiceOptions.find(
        (option) => option.value === setting?.settings?.VOICE_ID
      );
      setSelectedVoice(defaultVoice || null);
      const currentVoice = voices.find(
        (voice) => voice.Name === setting?.settings?.VOICE_ID
      );
      setcurrentVoice(currentVoice);
    }
  };
  useEffect(() => {
    settingsFormik.setValues({
      OPENAI_API_KEY: setting?.settings?.OPENAI_API_KEY || "",
    });
    setDefaultVoice();
  }, [setting]);

  const handleClosePromocodeModal = () => {
    setopenPromocode(false);
    setpromocodeAction("");
    setPromocode("");
    setpromocodeId("");
    setSelectedPlan();
  };

  const generateVoice = async (text) => {
    if (audioCache[text]) {
      // Play cached audio
      handlePlayAudio(audioCache[text], text);
    } else {
      setIsLoading(true); // Start loading
      try {
        const response = await dispatch(
          generateVoiceExplanation({ voice_id: text }, "preview")
        );
        const blob = response.data; // Assuming the API returns a Blob
        const audioUrl = URL.createObjectURL(blob);

        // Cache the generated audio
        setAudioCache((prev) => ({
          ...prev,
          [text]: audioUrl,
        }));

        // Play the newly generated audio
        handlePlayAudio(audioUrl, text);
      } catch (error) {
        console.error("Error generating voice:", error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    }
  };

  const handlePlayAudio = (audioUrl, text) => {
    if (currentAudio) {
      currentAudio.pause(); // Stop any currently playing audio
      currentAudio.currentTime = 0; // Reset current audio
    }

    const audio = new Audio(audioUrl);
    setCurrentAudio(audio);
    setPlayingText(text);

    audio.play();

    // Reset playing state when audio ends
    audio.onended = () => {
      setPlayingText(null);
      setCurrentAudio(null);
    };
  };

  const handleStopAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset the audio to the start
      setPlayingText(null);
      setCurrentAudio(null);
    }
  };

  useEffect(() => {
    // const isAudioGenerated = Boolean(audioCache[text]);
    setisAudioGenerated(Boolean(audioCache[selectedVoice?.value]));
  }, [selectedVoice, audioCache]);

  return (
    <Box className="content-layout" marginTop="32px">
      {/* Settings Section */}
      <Box className="content-layout" marginTop="32px">
        <Box
          variant="div"
          component="div"
          className="createuser-wrap"
          sx={{ padding: "10px 0" }}
        >
          <form onSubmit={settingsFormik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={12} lg={12}>
                <Box variant="div" component="div" className="user-detail">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          className="label"
                          sx={{ marginRight: 2, alignItems: "center" }}
                        >
                          Open AI API Key
                        </Typography>

                        <TextField
                          className="input-field"
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Enter Open AI API Key"
                          name="OPENAI_API_KEY"
                          value={settingsFormik.values.OPENAI_API_KEY}
                          onChange={settingsFormik.handleChange}
                          onBlur={settingsFormik.handleBlur}
                          error={
                            settingsFormik.touched.OPENAI_API_KEY &&
                            Boolean(settingsFormik.errors.OPENAI_API_KEY)
                          }
                          helperText={
                            settingsFormik.touched.OPENAI_API_KEY &&
                            settingsFormik.errors.OPENAI_API_KEY
                          }
                          sx={{ flex: 1 }}
                        />

                        <Button
                          variant="contained"
                          size="large"
                          className="primary-btn btn"
                          type="submit"
                          disableRipple
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>

      <hr />

      {section === null && (
        <>
          <Box
            sx={{ margin: "20px", paddingBottom: "20px" }}
            className="setting-card"
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12} // Takes full width on small screens
                sm={6} // Two boxes in a row on medium screens
                lg={3} // Three boxes in a row on large screens
              >
                <Box
                  className="section-card"
                  onClick={() => setsection("categories")}
                >
                  <Box className="section-icon" sx={{ marginRight: 2 }}>
                    <PiCirclesThreePlusBold
                      fill="white"
                      style={{ fontSize: "40px", color: "white" }}
                    />
                  </Box>
                  <Box className="section-label">
                    <Typography>Manage Categories</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12} // Takes full width on small screens
                sm={6} // Two boxes in a row on medium screens
                lg={3} // Three boxes in a row on large screens
              >
                <Box
                  className="section-card"
                  onClick={() => setsection("promocodes")}
                >
                  <Box className="section-icon" sx={{ marginRight: 2 }}>
                    <MdOutlineDiscount
                      fill="white"
                      style={{ fontSize: "40px" }}
                    />
                  </Box>
                  <Box className="section-label">
                    <Typography>Promocodes</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box
            sx={{ margin: "20px", paddingBottom: "20px" }}
            className="setting-card"
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              {" "}
              Manage Prompts :
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid
                item
                xs={12} // Takes full width on small screens
                sm={6} // Two boxes in a row on medium screens
                lg={3} // Three boxes in a row on large screens
              >
                <Box
                  className="section-card"
                  onClick={() => setsection("dictionary")}
                >
                  <Box className="section-icon" sx={{ marginRight: 2 }}>
                    <FaRegFileWord fill="white" style={{ fontSize: "40px" }} />
                  </Box>
                  <Box className="section-label">
                    <Typography>Dictionary</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12} // Takes full width on small screens
                sm={6} // Two boxes in a row on medium screens
                lg={3} // Three boxes in a row on large screens
              >
                <Box
                  className="section-card"
                  onClick={() => setsection("guide")}
                >
                  <Box className="section-icon" sx={{ marginRight: 2 }}>
                    <RiChatVoiceLine
                      fill="white"
                      style={{ fontSize: "40px" }}
                    />
                  </Box>
                  <Box className="section-label">
                    <Typography>OpenEssayme Guide</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {section === "categories" && (
        <Box>
          <Typography
            style={{
              display: "flex",
              margin: "20px",
              fontSize: "20px",
              fontWeight: 600,
              alignItems: "center",
            }}
          >
            <Button className="action-btn">
              <IoMdArrowRoundBack
                className="icon-font"
                onClick={() => setsection(null)}
              />
            </Button>
            Manage Categories
          </Typography>

          {/* Comprehension and Grammar Rules Sections */}
          <Box
            display="flex"
            gap={3}
            sx={{ margin: "20px", paddingBottom: "20px" }}
          >
            <ItemSection
              title="Comprehension"
              items={categories}
              onAdd={() => {
                categoryFormik.resetForm();
                setCategoryModalOpen(true);
              }}
              onEdit={(category) => {
                setSelectedCategory(category);
                categoryFormik.setValues({ name: category.name });
                setCategoryModalOpen(true);
              }}
              onDelete={async (id) => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this category?"
                  )
                ) {
                  try {
                    await dispatch(
                      deleteCategory((module = "comprehension"), id, token)
                    );
                    // successToast("Category deleted successfully");
                    fetchCategoriesData();
                  } catch (error) {
                    errorToast(error.message);
                  }
                }
              }}
              modalOpen={categoryModalOpen}
              setModalOpen={setCategoryModalOpen}
              selectedItem={selectedCategory}
              setSelectedItem={setSelectedCategory}
              handleSubmit={categoryFormik.handleSubmit}
              formik={categoryFormik}
            />

            <ItemSection
              title="Grammar Rules"
              items={grammarRules}
              onAdd={() => {
                grammarFormik.resetForm();
                setGrammarModalOpen(true);
              }}
              onEdit={(rule) => {
                setSelectedGrammarRule(rule);
                grammarFormik.setValues({ name: rule.name });
                setGrammarModalOpen(true);
              }}
              onDelete={async (id) => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this grammar rule?"
                  )
                ) {
                  try {
                    await dispatch(
                      deleteCategory((module = "grammar"), id, token)
                    );
                    // successToast("Grammar rule deleted successfully");
                    fetchGrammarRulesData();
                  } catch (error) {
                    errorToast(error.message);
                  }
                }
              }}
              modalOpen={grammarModalOpen}
              setModalOpen={setGrammarModalOpen}
              selectedItem={selectedGrammarRule}
              setSelectedItem={setSelectedGrammarRule}
              handleSubmit={grammarFormik.handleSubmit}
              formik={grammarFormik}
            />
          </Box>
        </Box>
      )}

      {section === "promocodes" && (
        <Box sx={{ margin: "20px", pb: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // marginBottom="16px"
          >
            <Typography
              style={{
                display: "flex",
                fontSize: "20px",
                fontWeight: 600,
                alignItems: "center",
              }}
            >
              <Button className="action-btn">
                <IoMdArrowRoundBack
                  className="icon-font"
                  onClick={() => setsection(null)}
                />
              </Button>
              Manage Promocodes
            </Typography>

            <Button
              variant="contained"
              size="large"
              className="primary-btn btn"
              onClick={() => {
                setPromocode("");
                setopenPromocode(true);
                setpromocodeAction("add");
              }}
              disableRipple
            >
              Add Promocode
            </Button>
          </Box>

          {/* Comprehension and Grammar Rules Sections */}

          <Box
            sx={{ backgroundColor: "#f0f0f052", borderRadius: "10px", mt: 3 }}
          >
            <Box variant="div" component="div" className="createuser-wrap p-4">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 900 }}>Promocode</TableCell>
                      <TableCell sx={{ fontWeight: 900 }}>Plan</TableCell>
                      <TableCell sx={{ fontWeight: 900 }} align="center">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {promocodes && promocodes?.data?.length > 0 ? (
                      promocodes?.data?.map((item) => (
                        <TableRow key={item.promo_code_id}>
                          <TableCell>{item.promo_code}</TableCell>
                          <TableCell>{item.plan_details.name}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={() => {
                                setpromocodeId(item.promo_code_id);
                                setopenPromocode(true);
                                setpromocodeAction("edit");
                              }}
                            >
                              <MdModeEditOutline />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setpromocodeId(item.promo_code_id);
                                setopenPromocode(true);
                                setpromocodeAction("delete");
                              }}
                            >
                              <RiDeleteBin5Fill />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding="20px"
                          >
                            <Typography variant="body1">
                              No Promocodes added yet
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Modal */}

              <Dialog
                open={openPromocode}
                onClose={() => {
                  handleClosePromocodeModal();
                }}
                maxWidth="sm"
                fullWidth={promocodeAction !== "delete"}
              >
                {(promocodeAction === "add" || promocodeAction === "edit") && (
                  <>
                    <DialogTitle>Add Promocode</DialogTitle>
                    <DialogContent>
                      <Box className="createuser-wrap" component="div">
                        <Box>
                          <Select
                            options={options}
                            onChange={(selectedOption) => {
                              setSelectedPlan(selectedOption);
                            }}
                            placeholder="Select a Plan"
                            isSearchable
                            menuPortalTarget={document.body} // Ensures menu renders outside modal
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 1300 }), // Adjusts z-index for proper visibility
                            }}
                            value={selectedPlan}
                          />

                          <TextField
                            className="input-field"
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder={`Enter Promocode`}
                            name="promocode"
                            onChange={(e) => {
                              setPromocode(e.target.value);
                            }}
                            value={promocode}
                            required
                            style={{ marginTop: "16px" }}
                          />
                        </Box>
                      </Box>
                    </DialogContent>
                  </>
                )}
                {promocodeAction === "delete" && (
                  <>
                    <DialogContent>
                      <Box variant="div" component="div" className="title">
                        <Typography
                          variant="h3"
                          component="h3"
                          className="modal-title"
                        >
                          {`Are you sure you want to delete this Promocode?`}
                        </Typography>
                      </Box>
                    </DialogContent>
                  </>
                )}
                <DialogActions sx={{ pb: 4 }}>
                  <Button
                    variant="outlined"
                    className="secondary-btn btn"
                    onClick={() => {
                      handleClosePromocodeModal();
                    }}
                    disableRipple
                  >
                    {promocodeAction === "delete" ? "No" : "Cancel"}
                  </Button>
                  <Button
                    variant="contained"
                    className="primary-btn btn"
                    onClick={handlePromocode}
                    disableRipple
                  >
                    {promocodeAction === "delete"
                      ? "Yes"
                      : promocodeAction === "edit"
                      ? "Update"
                      : "Create"}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        </Box>
      )}

      {section === "dictionary" && (
        <Box sx={{ margin: "20px", pb: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // marginBottom="16px"
          >
            <Typography
              style={{
                display: "flex",
                fontSize: "20px",
                fontWeight: 600,
                alignItems: "center",
              }}
            >
              <Button className="action-btn">
                <IoMdArrowRoundBack
                  className="icon-font"
                  onClick={() => setsection(null)}
                />
              </Button>
              Manage Dictionary Propmts
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Box variant="div" component="div" className="createuser-wrap p-4">
              <AdminDictionary />
            </Box>
          </Box>
        </Box>
      )}

      {section === "guide" && (
        <Box sx={{  pb: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{margin: "20px"}}
            // marginBottom="16px"
          >
            <Typography
              style={{
                display: "flex",
                fontSize: "20px",
                fontWeight: 600,
                alignItems: "center",
              }}
            >
              <Button className="action-btn">
                <IoMdArrowRoundBack
                  className="icon-font"
                  onClick={() => setsection(null)}
                />
              </Button>
              Manage OpenEssayme Guide 
            </Typography>
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn"
              onClick={() => {
                setopenVoice(true);
              }}
              disableRipple
            >
              Change Voice
            </Button>
          </Box>

          <Box sx={{ margin:'10px', mt: 3 }}>
            <Box variant="div" component="div" className="createuser-wrap p-4">
              <VoicePrompts setting={setting}/>
            </Box>
          </Box>
          <Dialog
            open={openVoice}
            onClose={() => {
              handleClosePromocodeModal();
            }}
            maxWidth="sm"
            fullWidth={promocodeAction !== "delete"}
          >
            <DialogTitle>Select Voice</DialogTitle>
            <DialogContent>
              <Typography>
                <span style={{ fontWeight: 600 }}>Current Voice :</span>{" "}
                {currentVoice
                  ? `${currentVoice.Name} (${currentVoice.Gender}, ${currentVoice.Language})`
                  : "Not available"}
              </Typography>
              <Box
                className="createuser-wrap"
                component="div"
                sx={{ margin: "30px 0" }}
              >
                <Box>
                  <Select
                    options={voiceOptions}
                    onChange={(selectedOption) => {
                      setSelectedVoice(selectedOption);
                    }}
                    placeholder="Select a Voice"
                    isSearchable
                    menuPortalTarget={document.body} // Ensures menu renders outside modal
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 1300 }), // Adjusts z-index for proper visibility
                    }}
                    value={selectedVoice}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                 <Typography sx={{fontWeight:600, fontSize:'15px'}}> Preview {selectedVoice?.value} {" "}</Typography>
                  <Box sx={{ ml: 1 }}>
                    {!isAudioGenerated ? (
                      <IconButton
                        sx={{
                          padding: "0 !important",
                          width: "20px",
                          // mt: 2,
                        }}
                        onClick={() => generateVoice(selectedVoice?.value)}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <CircularProgress size={20} sx={{ color: "#fff" }} />
                        ) : (
                          <HiSpeakerWave fill="#1d86ab" />
                        )}
                      </IconButton>
                    ) : (
                      <IconButton
                        sx={{
                          padding: "0 !important",
                          width: "18px",
                          // mt: 2,
                        }}
                        onClick={() =>
                          playingText === selectedVoice?.value
                            ? handleStopAudio()
                            : handlePlayAudio(
                                audioCache[selectedVoice?.value],
                                selectedVoice?.value
                              )
                        }
                        color="primary"
                        // sx={{ m: 2 }}
                      >
                        {playingText === selectedVoice?.value ? (
                          <FaRegCircleStop fill="#2090b7" />
                        ) : (
                          <FaRegCirclePlay fill="#2090a9" />
                        )}
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </DialogContent>

            <DialogActions sx={{ pb: 4 }}>
              <Button
                variant="outlined"
                className="secondary-btn btn"
                onClick={() => {
                  setopenVoice(false);
                  setDefaultVoice();
                }}
                disableRipple
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="primary-btn btn"
                onClick={handleVoiceChange}
                disableRipple
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default Settings;
