import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete as DeleteIcon } from "@mui/icons-material";

import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "suneditor/dist/css/suneditor.min.css";

import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../../utils/token";
import { callApi } from "../../api";
import { successToast } from "../../response/successToast";

const VoicePrompts = ({ setting }) => {
  const [data, setData] = useState();
  const [propmts, setpropmts] = useState();
  const token = getToken();
  // console.log("setting", setting);
  const [modifiedData, setModifiedData] = useState({}); // Track edited sections

  const handleAddQuestion = (key) => {
    const updatedData = {
      ...data,
      [key]: [...data[key], ""], // Add an empty question
    };
    setData(updatedData);
    updateModifiedData(key, updatedData[key]);
  };

  const handleDeleteQuestion = (key, index) => {
    const updatedData = {
      ...data,
      [key]: data[key].filter((_, i) => i !== index),
    };
    setData(updatedData);
    updateModifiedData(key, updatedData[key]);
  };

  const handleUpdateQuestion = (key, index, value) => {
    const updatedData = {
      ...data,
      [key]: data[key].map((item, i) => (i === index ? value : item)),
    };
    setData(updatedData);
    updateModifiedData(key, updatedData[key]);
  };

  const updateModifiedData = (key, updatedSection) => {
    setModifiedData((prev) => ({
      ...prev,
      [key]: updatedSection,
    }));
  };

  const handleSave = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/openessayme-guide/edit-question/`,
      modifiedData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.status === 200) {
      successToast(response?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      essay_view_guide: "", // Set initial values from stored credentials
      essay_practice_set_guide: "",
      essay_feedback_guide: "",
      grammar_comprehension_view_guide: "",
      grammar_comprehension_feedback_guide: "",
    },
    onSubmit: async (values) => {
      let payload = {
        essay_view_guide: values.essay_view_guide,
        essay_practice_set_guide: values.essay_practice_set_guide,
        essay_feedback_guide: values.essay_feedback_guide,
        grammar_comprehension_view_guide:
          values.grammar_comprehension_view_guide,
        grammar_comprehension_feedback_guide:
          values.grammar_comprehension_feedback_guide,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/settings/prompt/openessayme_guide/edit`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        successToast(response?.data?.message);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      essay_view_guide: propmts?.filter(
        (p) => p?.prompt_type === "essay_view_guide"
      )[0]?.prompt_text,

      essay_practice_set_guide: propmts?.filter(
        (p) => p?.prompt_type === "essay_practice_set_guide"
      )[0]?.prompt_text,

      essay_feedback_guide: propmts?.filter(
        (p) => p?.prompt_type === "essay_feedback_guide"
      )[0]?.prompt_text,

      grammar_comprehension_view_guide: propmts?.filter(
        (p) => p?.prompt_type === "grammar_comprehension_view_guide"
      )[0]?.prompt_text,

      grammar_comprehension_feedback_guide: propmts?.filter(
        (p) => p?.prompt_type === "grammar_comprehension_feedback_guide"
      )[0]?.prompt_text,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propmts]);

  const fetchSetting = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/settings/prompt/list?module=openessayme_guide`,
      });
      setpropmts(response?.data);
    } catch (error) {
      console.error(error);
      // errorToast(error.response.data.errors);
    }
  };
  useEffect(() => {
    fetchSetting();
    if (setting) {
      setData(setting?.settings?.predefined_questions);
    }
  }, [setting]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f0f0f052",
          borderRadius: "10px",
          padding: "15px 25px",
        }}
      >
        <Typography
          style={{
            display: "flex",
            fontSize: "20px",
            fontWeight: 600,
            alignItems: "center",
          }}
        >
          Prompts :
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ width: "100%", mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h6" sx={{ fontSize: "17px" }}>
                      Essay View Page Guide
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box variant="div" component="div">
                          <TextField
                            id="outlined-multiline-static"
                            placeholder="Enter exam propmt to generate details..."
                            multiline
                            rows={8}
                            value={formik.values.essay_view_guide}
                            sx={{ mt: 1 }}
                            fullWidth
                            onChange={(e) => {
                              formik.setFieldValue(
                                "essay_view_guide",
                                e.target.value
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography variant="h6" sx={{ fontSize: "17px" }}>
                      Essay Practice Set Guide
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box variant="div" component="div">
                      {/* <Typography
                          variant="body1"
                          component="label"
                          sx={{ mt: 1 }}
                        >
                          Practice Prompt:
                        </Typography> */}
                      <TextField
                        id="practice-prompt"
                        placeholder="Enter practice prompt..."
                        multiline
                        rows={5}
                        value={formik.values.essay_practice_set_guide}
                        sx={{ mt: 1 }}
                        fullWidth
                        onChange={(e) => {
                          formik.setFieldValue(
                            "essay_practice_set_guide",
                            e.target.value
                          );
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography variant="h6" sx={{ fontSize: "17px" }}>
                      Essay Feedback Guide
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box variant="div" component="div">
                      {/* <Typography
                          variant="body1"
                          component="label"
                          sx={{ mt: 1 }}
                        >
                          Feedback Prompt:
                        </Typography> */}
                      <TextField
                        id="feedback-prompt"
                        placeholder="Enter Essay feedback prompt..."
                        multiline
                        rows={6}
                        value={formik.values.essay_feedback_guide}
                        sx={{ mt: 1 }}
                        fullWidth
                        onChange={(e) => {
                          formik.setFieldValue(
                            "essay_feedback_guide",
                            e.target.value
                          );
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography variant="h6" sx={{ fontSize: "17px" }}>
                      Grammar/Comprehension View Page Guide
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box variant="div" component="div">
                      {/* <Typography
                          variant="body1"
                          component="label"
                          sx={{ mt: 1 }}
                        >
                          Feedback Prompt:
                        </Typography> */}
                      <TextField
                        id="feedback-prompt"
                        placeholder="Enter Essay feedback prompt..."
                        multiline
                        rows={6}
                        value={formik.values.grammar_comprehension_view_guide}
                        sx={{ mt: 1 }}
                        fullWidth
                        onChange={(e) => {
                          formik.setFieldValue(
                            "grammar_comprehension_view_guide",
                            e.target.value
                          );
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography variant="h6" sx={{ fontSize: "17px" }}>
                      Grammar/Comprehension Feedback Guide
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box variant="div" component="div">
                      {/* <Typography
                          variant="body1"
                          component="label"
                          sx={{ mt: 1 }}
                        >
                          Feedback Prompt:
                        </Typography> */}
                      <TextField
                        id="feedback-prompt"
                        placeholder="Enter Essay feedback prompt..."
                        multiline
                        rows={6}
                        value={
                          formik.values.grammar_comprehension_feedback_guide
                        }
                        sx={{ mt: 1 }}
                        fullWidth
                        onChange={(e) => {
                          formik.setFieldValue(
                            "grammar_comprehension_feedback_guide",
                            e.target.value
                          );
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
          <Box
            variant="div"
            component="div"
            className="footer-btn"
            style={{ marginTop: "16px", height: "45px" }}
          >
            <Box variant="div" component="div" className="btn-group">
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                type="submit"
                disableRipple
              >
                {" "}
                Save Prompts
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

      <Box
        sx={{
          mt: 3,
          backgroundColor: "#f0f0f052",
          borderRadius: "10px",
          padding: "15px 25px",
        }}
      >
        <Typography
          style={{
            display: "flex",
            fontSize: "20px",
            fontWeight: 600,
            alignItems: "center",
          }}
        >
          Predefined Questions :
        </Typography>
        <Box sx={{ mt: 2 }}>
          {data &&
            Object.entries(data).map(([key, questions]) => (
              <Grid container sx={{ margin: "15px 0" }}>
                <Grid item xs={12}>
                  <Accordion key={key}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${key}-content`}
                      id={`${key}-header`}
                    >
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {key.replace(/_/g, " ")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {questions.map((question, index) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          mb={2}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={question}
                            onChange={(e) =>
                              handleUpdateQuestion(key, index, e.target.value)
                            }
                            label={`Question ${index + 1}`}
                          />
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteQuestion(key, index)}
                            sx={{ marginLeft: "10px" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ))}
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddQuestion(key)}
                          sx={{ mt: 0 , textTransform:'none'}}
                        >
                          Add 
                        </Button>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            ))}

          <Box
            variant="div"
            component="div"
            className="footer-btn"
            style={{ marginTop: "16px", height: "35px" }}
          >
            <Box variant="div" component="div" className="btn-group">
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                type="submit"
                disableRipple
                onClick={handleSave}
              >
                {" "}
                Save Questions
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default VoicePrompts;
