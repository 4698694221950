import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Tabs,
  Tab,
  Tooltip,
  Menu,
  Modal,
  Fade,
} from "@mui/material";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { Search as SearchIcon } from "@mui/icons-material";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchComprehension } from "../../../store/slices/comprehension/comprehensionSlice";
import { GoAlertFill } from "react-icons/go";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import Loader from "../../../common/loader";
import { fetchCategories } from "../../../store/slices/category/categorySlice";
import { getToken } from "../../../utils/token";
import {
  addTofavourites,
  removeFromfavourites,
  fetchUserfavourites,
} from "../../../store/slices/favourite/favouriteSlice";
import { errorToast } from "../../../response/errorToast";
import { fetchUserById } from "../../../store/slices/users/userByIdSlice";
import getuserData from "../../../utils/userData";
import RemoveFavModal from "../../../common/removeFavModal";
import { addToDictionary } from "../../../store/slices/dictionary/addToDictionarySlice";
import { fetchDictionary } from "../../../store/slices/dictionary/getDictionarySlice";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { deleteWordById } from "../../../store/slices/dictionary/deleteWordSlice";

const UserDictionary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const comprehension = useSelector(
    (state) => state.comprehension.comprehension
  );
  const words = useSelector((state) => state.dictionary.words);

  const favourites = useSelector(
    (state) => state.favourite.favourites?.favourites?.essay
  );

  const [localFavorites, setLocalFavorites] = useState(new Set());
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const [open, setOpen] = useState(false);
  const [wid, setwid] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [filteredWords, setFilteredWords] = useState([]);

  const [categories, setCategories] = useState([]);
  const token = getToken();
  const subscription = useSelector(setSubscriptionFromApi);
  const [activeTab, setActiveTab] = useState(0);
  const userState = useSelector((state) => state.userById.user);
  const err = useSelector((state) => state.comprehension.error);
  const [hasValidPlan, sethasValidPlan] = useState(false);
  const [isSubscribed, setisSubscribed] = useState(false);
  const [openRemoveFavModal, setOpenRemoveFavModal] = useState(false);
  const [removableItemFromFav, setremovableItemFromFav] = useState();
  const [anchorEl, setAnchorEl] = useState(null); // State to control the menu
  const [newWord, setNewWord] = useState("");
  const [favExams, setfavExams] = useState("");
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  const { isLoading } = useSelector((state) => state.loader);
  // const isLoadingFavorite = useSelector((state) => state.loader.isLoading);
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false);

  useEffect(() => {
    const favoriteIds = new Set(favourites?.map((fav) => fav.item_id));
    setLocalFavorites(favoriteIds);
  }, [favourites, activeTab]);

  const isFavorite = useCallback(
    (compId) => {
      return localFavorites?.has(compId);
    },
    [localFavorites]
  );

  const handleAddWordMenu = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu when the button is clicked
  };

  const closeAddWordMenu = () => {
    setAnchorEl(null); // Close the menu
    setNewWord("");
  };

  const saveWord = () => {
    if (newWord.trim()) {
      dispatch(addToDictionary(newWord.trim())); // Dispatch the action to add the word
      closeAddWordMenu(); // Close the menu
    }
    dispatch(fetchDictionary());
  };

  // Filter and search logic
  useEffect(() => {
    if (words?.words) {
      let filtered = [...words?.words];

      //   // Apply category filter
      //   if (selectedCategory !== "all") {
      //     filtered = filtered?.filter(
      //       (comprehension) => comprehension.category_name === selectedCategory
      //     );
      //   }

      // Apply search filter
      if (searchQuery) {
        const query = searchQuery?.toLowerCase();
        filtered = filtered?.filter((w) =>
          w?.word?.toLowerCase().includes(query)
        );
      }

      // Active Tab filter
      //   if (activeTab === 0) {
      //     filtered = filtered?.filter((comp) => {
      //       if (Array.isArray(favourites)) {
      //         const hasFavourite = favourites?.some((fav) => {
      //           const isMatch = fav.item_id === comp.id;
      //           return isMatch;
      //         });
      //         return hasFavourite;
      //       }
      //       return false;
      //     });
      //   }
      filtered.sort((a, b) => a.word.localeCompare(b.word));
      setFilteredWords(filtered);
    }
  }, [words?.words, searchQuery, activeTab]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    // Simulate fetching subscription status
    if (subscription !== undefined) {
      setIsCheckingSubscription(false);
    }
  }, [subscription]);

  useEffect(() => {
    dispatch(fetchDictionary(activeTab));
  }, [dispatch, activeTab]);

  useEffect(() => {
    // If no subscription, check comprehension practice
    if (!subscription) {
      if (userState?.comprehension_practice_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left > 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const validPlan =
      subscription && listOfPlans?.some((plan) => subscription.includes(plan));
    sethasValidPlan(validPlan);
    if (!validPlan) {
      if (userState?.comprehension_practice_left <= 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.comprehension_practice_left > 0);
        return;
      }
    }

    setisSubscribed(validPlan);
  }, [subscription, userState]);

  useEffect(() => {
    if (token && userState) {
      dispatch(fetchUserById(userState?.id, token));
    }
    dispatch(fetchUserfavourites());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatExamNames = (essays) => {
    
    const names = essays?.map((e) => `<strong>${e.item_name}</strong>`);

    if (names?.length === 1) {
      setfavExams(names[0]);
    } else if (names?.length === 2) {
      setfavExams(`${names[0]} and ${names[1]}`);
    } else {
      setfavExams(
        `${names?.slice(0, -1).join(", ")} and ${names[names?.length - 1]}`
      );
    }
  };

  useEffect(() => {
    
    if (favourites !== undefined) formatExamNames(favourites);
  }, [favourites]);

  return (
    <>
      {isLoading || isCheckingSubscription ? (
        <Loader />
      ) : (
        <>
          {/* {(!subscription || (subscription && !hasValidPlan)) && (
              <Box className="left-attempt-container">
                <Typography>
                  Enjoy <span style={{ color: "black" }}>4</span> Complimentary
                  Comprehensions Sessions for free. Unlock More with Our Premium
                  Plans.
                </Typography>
                <Typography>
                  Free Comprehensions Left :&nbsp;
                  <span style={{ color: "black" }}>
                    {" "}
                    {userState?.comprehension_practice_left}&nbsp;/&nbsp;4
                  </span>
                </Typography>
              </Box>
            )} */}
          <Box className="content-layout" marginTop="32px">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              {/* {isSubscribed ? ( */}
              <>
                {/* Tabs for "My Comprehension" and "All Comprehensions" */}
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    sx={{
                      borderBottom: activeTab === 0 ? 2 : 0,
                      borderColor: "primary.main",
                      fontWeight: 900,
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                    label="My Words"
                  />
                  <Tab
                    sx={{
                      borderBottom: activeTab === 1 ? 2 : 0,
                      borderColor: "primary.main",
                      fontWeight: 900,
                      fontSize: "15px",
                      textTransform: "none",
                    }}
                    label="Other Words"
                  />
                </Tabs>
                {/* Search and Filter Controls */}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    m: 3,
                  }}
                >
                  <TextField
                    placeholder="Search word..."
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{ flexGrow: 1, mt: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box>
                    <Button
                      onClick={handleAddWordMenu}
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      disableRipple
                    >
                      Add Word
                    </Button>

                    {/* Menu-like Section */}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={closeAddWordMenu}
                      PaperProps={{
                        sx: {
                          width: 400,
                          marginTop: "10px",
                          padding: "15px",
                          borderRadius: "8px",
                          boxShadow: 3,
                        },
                      }}
                    >
                      <Typography sx={{ marginBottom: "15px" }}>
                        Enter a word to add in your Dictionary
                      </Typography>
                      <TextField
                        label="Word"
                        size="small"
                        value={newWord}
                        onChange={(e) => setNewWord(e.target.value)}
                        fullWidth
                        sx={{ marginBottom: "15px" }}
                      />

                      <Box display="flex" justifyContent="space-between">
                        <Button
                          onClick={saveWord}
                          variant="contained"
                          size="large"
                          className="primary-btn btn"
                          disableRipple
                        >
                          Save
                        </Button>
                        <Button
                          onClick={closeAddWordMenu}
                          variant="contained"
                          size="large"
                          className="secondary-btn btn"
                          disableRipple
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Menu>
                  </Box>
                </Box>
                {activeTab === 1 && (
                  <Typography
                    sx={{
                      ml: "30px",
                      backgroundColor: "#67c7e833",
                      width: "fit-content",
                      padding: "1px 15px",
                      borderRadius: "8px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: favExams
                        ? `Useful words related to your favorite exam(s) : ${favExams}`
                        : "You can explore some useful words by adding an Essay Exam in your favorites.",
                    }}
                  ></Typography>
                )}
                <TableContainer className="table-listing">
                  <Table aria-label="table" className="listing-table">
                    <TableHead>
                      <TableRow className="head-row">
                        <TableCell
                          align="left"
                          sx={{ paddingLeft: "30px", width: "50%" }}
                        >
                          Word Name
                        </TableCell>
                        {/* {selectedCategory === "all" && (
                            <TableCell align="left" sx={{ width: "30%" }}>
                              Category
                            </TableCell>
                          )} */}
                        {activeTab===0 &&<TableCell align="center">Actions</TableCell>}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredWords?.length === 0 ? (
                        <TableRow className="no-msg">
                          <TableCell
                            colSpan={selectedCategory === "all" ? 3 : 2}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "16px",
                            }}
                            className="no-msg"
                          >
                            {activeTab === 0
                              ? "No Words Added In Your Dictionary"
                              : "No Words Found"}
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredWords?.map((w) => {
                          //   const isButtonDisabled =
                          //     favourites?.length >= 6 &&
                          //     !isFavorite(comprehension.id);
                          return (
                            <TableRow
                              key={w.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell
                                align="left"
                                sx={{ paddingLeft: "30px" }}
                                onClick={() =>
                                  navigate(`/dictionary/${w?.id}/view`)
                                }
                              >
                                {w?.word}
                              </TableCell>
                              {/* {selectedCategory === "all" && (
                                  <TableCell align="left" onClick={() =>
                                    navigate(
                                      `/comprehension/${comprehension?.id}/view`
                                    )
                                  }>
                                    {comprehension?.category_name}
  
                                  </TableCell>
                                )} */}
                              {activeTab===0 &&<TableCell
                                align="left"
                                sx={{
                                  display: "flex",
                                  // border: "2px solid red",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  className="listing-action-btn-user"
                                  onClick={() => {
                                    setOpen(true);
                                    setwid(w?.id);
                                  }}
                                  title="Delete Word"
                                >
                                  <RiDeleteBin5Fill className="icon-font" />
                                </Button>
                              </TableCell>}
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </Box>
          </Box>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
          >
            <Fade in={open}>
              <Box className="modal-wrapper">
                <Box variant="div" component="div" className="title">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="modal-title"
                  >
                    {`Are you sure you want to delete this word?`}
                  </Typography>
                  {/* <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography> */}
                </Box>
                <Box variant="div" component="div" className="body"></Box>
                <Box
                  variant="div"
                  component="div"
                  className="footer"
                  // style={{ height: "35px" }}
                >
                  <Box
                    variant="div"
                    component="div"
                    className="modal-btn-group"
                    style={{ height: "35px" }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      disableRipple
                      onClick={async() => {
                        await dispatch(deleteWordById(wid));
                        setOpen(false)
                        dispatch(fetchDictionary());
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="secondary-btn btn"
                      disableRipple
                      onClick={() => setOpen(false)}
                    >
                      No
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </>
  );
};

export default UserDictionary;
