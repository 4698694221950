import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { successToast } from "../../../response/successToast";
import { toast } from "react-toastify";
import { getToken } from "../../../utils/token";
// import { setLoading } from "../loader/loaderSlice";

const initialState = {
  selectedWord: null,
  success: false,
  error: null,
};

const addToDictionarySlice = createSlice({
  name: "addToDictionary",
  initialState,
  reducers: {
    createExamStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createExamSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createExamFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { createExamStart, createExamSuccess, createExamFailure } =
  addToDictionarySlice.actions;

export default addToDictionarySlice.reducer;

export const addToDictionary = (word) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const token = getToken();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/dictionary/add-word/`,
      { word: word },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // const data = response.data;
    toast.success(
      <div
        dangerouslySetInnerHTML={{
          __html: `word <b>${word}</b> added to your dictionary!`,
        }}
      />
    );
    // return { success: true, message: data?.message, exam_id:data?.exam_id };
  } catch (error) {
    errorToast(error?.response?.data?.word);
    // return { success: false, error: error.response.message };
  }
};
