import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "../../../api";
import { setLoading } from "../loader/loaderSlice";
import { errorToast } from "../../../response/errorToast";
import axios from "axios";
import { getToken } from "../../../utils/token";

const initialState = {
  users: [],
  status: "idle",
  error: null,
};

const editUserSlice = createSlice({
  name: "editUser",
  initialState,
  reducers: {
    updateUsersStart: (state) => {
      state.status = "loading";
    },
    updateUsersSuccess: (state, action) => {
      state.status = "succeeded";
      state.onEditBoardings = action.payload;
    },
    updateUsersFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { updateUsersStart, updateUsersSuccess, updateUsersFailure } =
  editUserSlice.actions;

export const editUser = (userData) => async (dispatch) => {
  
  try {
    dispatch(setLoading(true));
    dispatch(updateUsersStart());
    const token = getToken()
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/edit-profile/`,
      userData,
      {
        headers: {
          "Content-Type": "multipart/formdata",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // const response = await callApi({
    //   method: "post",
    //   url: `${process.env.REACT_APP_API_URL}/users/edit-profile/`,
    //   data: userData,
    // });
    dispatch(updateUsersSuccess(response));
    return { success: true, message: response?.message };
  } catch (error) {
    errorToast(error.response.data.errors);
    dispatch(updateUsersFailure(error.response.data));
  } finally {
    dispatch(setLoading(false));
  }
};

export default editUserSlice.reducer;
