import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../../utils/token";
// import { errorToast } from "../../../response/errorToast";
import { setLoading } from "../loader/loaderSlice";

const initialState = {
  words: [],
  wordById: null,
  status: "idle",
  error: null,
};

const dictionarySlice = createSlice({
  name: "exams",
  initialState,
  reducers: {
    fetchWordsStart: (state) => {
      state.status = "loading";
    },
    fetchWordsSuccess: (state, action) => {
      state.status = "succeeded";
      state.words = action.payload;
    },
    fetchWordByIdSuccess: (state, action) => {
      state.status = "succeeded";
      state.wordById = action.payload;
    },
    fetchWordsFailure: (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.error;
    },
  },
});

export const {
  fetchWordsStart,
  fetchWordsSuccess,
  fetchWordByIdSuccess,
  fetchWordsFailure,
} = dictionarySlice.actions;

export const fetchDictionary = (activeTab) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(fetchWordsStart());
    const token = getToken();
    const url =
      activeTab === 1
        ? `${process.env.REACT_APP_API_URL}/dictionary/list-words?others=True`
        : `${process.env.REACT_APP_API_URL}/dictionary/list-words`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(fetchWordsSuccess(response?.data));
  } catch (error) {
    // errorToast(error?.response?.data?.errors);
    dispatch(fetchWordsFailure(error?.response?.data));
  } finally {
    dispatch(setLoading(false));
  }
};
export const fetchDictionaryById = (id) => async (dispatch) => {
  try {

    // dispatch(setLoading(true));
    dispatch(fetchWordsStart());
    const token = getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dictionary/view/${id}/word`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(fetchWordByIdSuccess(response?.data));
  } catch (error) {
    // errorToast(error?.response?.data?.errors);
    dispatch(fetchWordsFailure(error?.response?.data));
  } finally {
    // dispatch(setLoading(false));
  }
};

export default dictionarySlice.reducer;
