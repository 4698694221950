import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../../response/errorToast";
import { getToken } from "../../../utils/token";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const generateVoiveSlice = createSlice({
  name: "voice",
  initialState,
  reducers: {
    generateVoiveStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    generateVoiveSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    generateVoiveFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  generateVoiveStart,
  generateVoiveSuccess,
  generateVoiveFailure,
} = generateVoiveSlice.actions;

export default generateVoiveSlice.reducer;

export const generateVoiceExplanation = (payload, type) => async (dispatch) => {
  try {
    dispatch(generateVoiveStart());
    const token = getToken();
    const url =
      type === "preview"
        ? "openessayme-guide/text-to-speech/?preview=True"
        : "openessayme-guide/text-to-speech/";
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${url}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Ensure the response is treated as binary data
      }
    );

    // const blob = response.data;

    // Create an audio URL from the blob
    // const audioUrl = URL.createObjectURL(blob);
    // console.log("audioUrl", audioUrl);

    // // Play the audio
    // const audio = new Audio(audioUrl);
    // audio.play();

    // Optional: Clean up the URL object after playing
    // audio.onended = () => {
    //   URL.revokeObjectURL(audioUrl);
    // };

    // Optionally dispatch a success action if needed
    dispatch(generateVoiveSuccess());
    return { data: response?.data };
  } catch (error) {
    console.error("Error generating voice:", error);
    errorToast(error?.response?.data?.errors || "An error occurred.");
    dispatch(generateVoiveFailure(error?.message));
  }
};
