import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { Progress } from "rsuite";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Progress } from "reactstrap";

const ProgressBar = ({ data }) => {
  const {
    total_progress,
    my_essay_progress,
    my_grammar_and_comprehension_progress,
    ai_tutor_progress,
  } = data;

  // Calculate percentages for each section relative to total_progress

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex ",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{fontSize:'18px'}}>
          <b>Overall Progress :</b>
        </Typography>
        <Typography
          variant="caption"
          sx={{ mt: 1, display: "block", fontSize: "14px" }}
        >
          <b>Essay Exams :</b> {my_essay_progress?my_essay_progress?.toFixed(1):0}%{" "}
          <b>&nbsp; | &nbsp;Grammar Rules And Comprehensions : </b>
          {my_grammar_and_comprehension_progress?my_grammar_and_comprehension_progress?.toFixed(1):0}%{" "}
          <b>&nbsp;|&nbsp; AI Tutor :</b> {ai_tutor_progress?ai_tutor_progress?.toFixed(1):0}%
        </Typography>
      </Box>
      <Progress.Line
        percent={total_progress?.toFixed(2)}
        status="active"
        className="custom-progress"
      />

      {/* <Progress multi>
        <Progress bar color="success" value={my_essay_progress} />
        <Progress
          bar
          color="danger"
          value={my_grammar_and_comprehension_progress}
        />
        <Progress bar color="warning" value={ai_tutor_progress} />
      </Progress> */}

      
    </Box>
  );
};

export default function MainProgressBar({ data }) {
  return (
    <Box sx={{backgroundColor:'white', padding:'10px 15px', borderRadius:'10px'}}>
      <ProgressBar data={data} />
    </Box>
  );
}
