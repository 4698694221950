import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { callApi } from "../../api";
import { errorToast } from "../../response/errorToast";
import { useDispatch, useSelector } from "react-redux";
import { startConversation } from "../../store/slices/interaction/startConversationSlice";
import { getToken } from "../../utils/token";
import { RiArrowUpLine } from "react-icons/ri";
import {
  FaCirclePause,
  FaRegCirclePlay,
  FaRegCircleStop,
} from "react-icons/fa6";
import CreateConversationModal from "./createConversationModal";
import { fetchExams } from "../../store/slices/exams/examSlice";
import { fetchGrammar } from "../../store/slices/grammar/grammarSlice";
import { HiSpeakerWave } from "react-icons/hi2";
import { generateVoiceExplanation } from "../../store/slices/guide/generateVoice";

const Chatbot = () => {
  const [message, setMessage] = useState("");
  const [audioCache, setAudioCache] = useState({}); // Cache for audio URLs
  const [isVoiceLoading, setIsVoiceLoading] = useState(false); // Track loading state
  const [playingText, setPlayingText] = useState(null); // Track the currently playing text
  const [currentAudio, setCurrentAudio] = useState(null);
  const dispatch = useDispatch();
  const token = getToken();
  const [chat, setChat] = useState([]);
  const { id } = useParams();
  const [conversation, setConversation] = useState();
  const [disable, setDisable] = useState(false);
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [initial, setInitial] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { isLoading } = useSelector((state) => state.loader);
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    if (event.target.value.trim() === "") {
      setInitial(true);
    }
  };

  const handleSendMessage = async () => {
    const payload = {
      conversation_uuid: conversation?.conversation?.uuid,
      text: message,
    };

    try {
      // Dispatch the action to start the conversation
      setDisable(true);
      const response = await dispatch(startConversation(payload, token));

      // Check if the action was successful
      if (response.success) {
        // Clear the message input
        setMessage("");
        // Fetch updated conversation data
        fetchData(null);
      } else {
        console.error("Error starting conversation:", response.error);
        // Handle error scenario here
      }
    } catch (error) {
      console.error("Error starting conversation:", error.message);
      // Handle error scenario here
    } finally {
      setDisable(false);
    }
  };

  const fetchData = async (reason) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/chatbot/view-my-conversation/${id}/?call_reason=${reason}`,
      });
      setConversation(response);
      if (response && response.conversation && response.conversation.messages) {
        const chatMessages = response.conversation.messages.map((message) => ({
          sender: message.sender,
          message: message.message_text,
        }));
        setChat(chatMessages);
      }
    } catch (error) {
      // console.log(error);
      errorToast(error.response.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.some((err) =>
          err.includes("unable to load conversation")
        )
      ) {
        navigate("*");
      }
    }
  };

  useEffect(() => {
    fetchData("rendering");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // Scroll to the bottom of the chat box when chat updates
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chat]);

  useEffect(() => {
    // Disable button initially if message is empty
    if (isInitialRender) {
      setInitial(message.trim() === "");
      setIsInitialRender(false);
    } else {
      setInitial(message.trim() === "");
    }
  }, [message, isInitialRender]);

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const handleButonClick = () => {
    setOpen(true);
    dispatch(fetchExams({}));
    dispatch(fetchGrammar({}));
  };
  const [dotsCount, setDotsCount] = useState(1);

  useEffect(() => {
    if (disable) {
      const interval = setInterval(() => {
        setDotsCount((prevCount) => (prevCount < 5 ? prevCount + 1 : 1));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [disable]);

  const generateVoice = async (text) => {
    if (audioCache[text]) {
      // Play cached audio
      handlePlayAudio(audioCache[text], text);
    } else {
      setIsVoiceLoading(true); // Start loading
      try {
        const response = await dispatch(generateVoiceExplanation({ text }));
        const blob = response.data; // Assuming the API returns a Blob
        const audioUrl = URL.createObjectURL(blob);

        // Cache the generated audio
        setAudioCache((prev) => ({ ...prev, [text]: audioUrl }));

        // Play the newly generated audio
        handlePlayAudio(audioUrl, text);
      } catch (error) {
        console.error("Error generating voice:", error);
      } finally {
        setIsVoiceLoading(false); // Stop loading
      }
    }
  };

  const handlePlayAudio = (audioUrl, text) => {
    if (currentAudio) {
      currentAudio.pause(); // Stop any currently playing audio
      currentAudio.currentTime = 0; // Reset current audio
    }

    const audio = new Audio(audioUrl);
    setCurrentAudio(audio);
    setPlayingText(text);

    audio.play();

    // Reset playing state when audio ends
    audio.onended = () => {
      setPlayingText(null);
      setCurrentAudio(null);
    };
  };

  const handleStopAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset the audio to the start
      setPlayingText(null);
      setCurrentAudio(null);
    }
  };

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <Container className="chat-box">
        <Paper style={{ padding: 20, marginTop: 20, minHeight: "85vh" }}>
          <Grid
            container
            spacing={2}
            direction="column"
            style={{ height: "100%" }}
          >
            <Grid
              item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2>
                Tutor for{" "}
                {conversation?.conversation?.chat_type === "grammar_rule"
                  ? conversation?.conversation?.grammar_rule?.name
                  : conversation?.conversation?.chat_type === "comprehension"
                  ? conversation?.conversation?.comprehension?.name
                  : conversation?.conversation?.exam?.name}
              </h2>

              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={() => handleButonClick()}
              >
                <Typography variant="body1" component="p">
                  New Chat
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              style={{
                flexGrow: 1,
                overflowY: "auto",
              }}
            >
              <Box
                ref={chatContainerRef}
                style={{
                  overflowY: "scroll",
                  // minHeight: 470,
                  // maxHeight: 470,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "10px",
                  scrollBehavior: "smooth",
                  maxHeight: chat.length !== 0 ? "calc(100vh - 270px)" : "auto",
                  width: "100%",
                }}
                className="chat-container"
              >
                <table style={{ width: "100%" }}>
                  <tbody>
                    {chat.map((chatItem, index) => {
                      const isAudioGenerated = Boolean(
                        audioCache[chatItem.message]
                      );
                      return (
                        <tr
                          key={index}
                          className={chatItem.sender === "ai" ? "ai" : "user"}
                        >
                          <td
                            style={{
                              backgroundColor:
                                chatItem.sender === "ai"
                                  ? "rgb(0, 132, 255)"
                                  : "rgb(224, 221, 221)",
                              padding: "8px",
                              maxWidth: "650px",
                              borderRadius: "12px",
                              textAlign:
                                chatItem.sender === "ai" ? "left" : "right",
                              color:
                                chatItem.sender === "ai" ? "white" : "black",
                              borderBottomRightRadius:
                                chatItem.sender === "user" ? "0px" : "12px",
                              borderBottomLeftRadius:
                                chatItem.sender === "ai" ? "0px" : "12px",
                              display: "inline-block",
                              marginBottom: "10px",
                              whiteSpace: "pre-wrap",
                            }}
                            className={`${
                              chatItem.sender === "ai" ? "ai-color" : "my-color"
                            }`}
                          >
                            {chatItem.message}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              {chatItem.sender === "ai" && (
                                <>
                                  {!isAudioGenerated ? (
                                    <Tooltip title="Read aloud" arrow>
                                      <IconButton
                                        sx={{
                                          padding: "0 !important",
                                          width: "20px",
                                          mt: 2,
                                        }}
                                        onClick={() =>
                                          generateVoice(chatItem.message)
                                        }
                                        disabled={isVoiceLoading}
                                      >
                                        {isVoiceLoading ? (
                                          <CircularProgress
                                            size={20}
                                            sx={{ color: "#fff" }}
                                          />
                                        ) : (
                                          <HiSpeakerWave fill="white" />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Read aloud" arrow>
                                      <IconButton
                                        sx={{
                                          padding: "0 !important",
                                          width: "18px",
                                          mt: 2,
                                        }}
                                        onClick={() =>
                                          playingText === chatItem.message
                                            ? handleStopAudio()
                                            : handlePlayAudio(
                                                audioCache[chatItem.message],
                                                chatItem.message
                                              )
                                        }
                                        color="primary"
                                        // sx={{ m: 2 }}
                                      >
                                        {playingText === chatItem.message ? (
                                          <FaRegCircleStop fill="white" />
                                        ) : (
                                          <FaRegCirclePlay fill="white" />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Box>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            </Grid>
            <Box
              display="flex"
              sx={{ gap: "15px", paddingLeft: "16px", paddingTop: "8px" }}
            >
              <TextField
                multiline
                rows={1}
                maxRows={2}
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Message Openessayme Tutor"
                value={disable ? `Loading${".".repeat(dotsCount)}` : message}
                onChange={handleMessageChange}
                onKeyDown={(event) => {
                  if (
                    event.target.value.trim() !== "" && // Ensures the message is not empty
                    !event.target.value.includes("Loading") && // Ensures the message is not loading
                    event.key === "Enter" &&
                    !event.shiftKey
                  ) {
                    handleSendMessage();
                  }
                }}
              />
              <Button
                variant="contained"
                size="large"
                type="submit"
                className="primary-btn btn arrow-btn"
                disableRipple
                onClick={handleSendMessage}
                startIcon={
                  disable ? (
                    <FaCirclePause />
                  ) : (
                    <RiArrowUpLine
                      className="submit-arrow"
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px",
                        stroke: "var(--white)",
                      }}
                    />
                  )
                }
                disabled={disable || initial}
              ></Button>
            </Box>
          </Grid>
        </Paper>
      </Container>
      <CreateConversationModal
        open={open}
        setOpen={setOpen}
        handleDeleteClose={handleDeleteClose}
        id={id}
      />
    </>
  );
};

export default Chatbot;
