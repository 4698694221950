import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  TextField,
  IconButton,
  Button,
  Box,
  Typography,
  Paper,
  Avatar,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import logo from "../assets/images/logo.png";
import ReactMarkdown from "react-markdown";
import CloseIcon from "@mui/icons-material/Close";
import { RxCross1, RxCross2 } from "react-icons/rx";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSpring, animated } from "react-spring";
import { generateGuidenceOnView } from "../store/slices/guide/guideOnViewSlice";
import { useDispatch, useSelector } from "react-redux";
import ChatLoader from "./chatLoader";
import getuserData from "../utils/userData";
import { generateGuidenceOnFeedback } from "../store/slices/guide/guideOnFeedback";
import { generateVoiceExplanation } from "../store/slices/guide/generateVoice";
import { FaRegCirclePlay, FaRegCircleStop } from "react-icons/fa6";
import { HiSpeakerWave } from "react-icons/hi2";
import { RiCloseFill } from "react-icons/ri";
import { FaChevronUp } from "react-icons/fa";
import { fetchSettings } from "../store/slices/settings/fetchSettings";

const ChatFeedbackPageDrawer = ({
  open,
  setOpen,
  data,
  module,
  questionType,
}) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [audioCache, setAudioCache] = useState({}); // Cache for audio URLs
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [playingText, setPlayingText] = useState(null); // Track the currently playing text
  const [currentAudio, setCurrentAudio] = useState(null);
  const settings = useSelector((state) => state.settings.settings);
  const [load, setLoad] = useState(false);
  const user = getuserData();
  const [userInput, setUserInput] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(
    module === "Essay" ? true : false
  ); // To store the selected question

  const [predefinedQuestions, setpredefinedQuestions] = useState([]);

     const setDefaultQuestions = () => {
       if (module === "Essay") {
         setpredefinedQuestions(
           settings?.settings?.predefined_questions?.essay_feedback_guide
         );
       } else {
         setpredefinedQuestions(
           settings?.settings?.predefined_questions
             ?.grammar_comprehension_feedback_guide
         );
       }
     };
     useEffect(() => {
       setDefaultQuestions();
     }, [settings]);
     useEffect(() => {
       dispatch(fetchSettings());
     }, []);

  const [animationProps, setanimationProps] = useState({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 200, friction: 20 },
  });
  const handleSend = async (question) => {
    const userMessage = question || userInput;

    if (module === "Essay") {
      // Add the user's message to the chat with animation triggered for a new message
      if (!userMessage.trim()) return;
      setMessages((prev) => [
        ...prev,
        { type: "user", text: userMessage?.trim() },
      ]);
    } else {
      if (!userMessage.trim()) return;
      setMessages((prev) => [
        ...prev,
        {
          type: "user",
          text: (
            <>
              <div>
                <strong>
                  Q{selectedQuestion.id}: {selectedQuestion.question}
                </strong>
              </div>
              <br />
              <div>
                <strong>You :</strong> {userMessage.trim()}
              </div>
            </>
          ),
        },
      ]);
      // setSelectedQuestion(null); // Reset selected question after sending
    }

    // Mark the message as new and trigger animation
    // setIsNewMessage(true);
    setUserInput(""); // Clear input after sending

    // Call API and add the response
    try {
      setLoad(true);
      let response;
      if (module === "Essay") {
        response = await dispatch(
          generateGuidenceOnFeedback(
            {
              id: data?.attempt?.exam_id, // Replace with actual id
              user_instruction: userMessage,
              user_answer: data?.attempt?.response,
              practice_set_question: data?.attempt?.question,
              specific_section: data?.feedback,
            },
            module?.toLowerCase()
          )
        );
      } else {
        if (questionType === "mcq") {
          const selectedAnswerText = selectedQuestion?.options.find(
            (option) => option.id === selectedQuestion.selected_option
          )?.text;

          const correctAnswerText = selectedQuestion?.options.find(
            (option) => option.id === selectedQuestion.correct_option
          )?.text;
          response = await dispatch(
            generateGuidenceOnFeedback(
              {
                id:
                  module === "Comprehension"
                    ? data?.attempt?.comprehension_id
                    : data?.attempt?.grammar_rule_id, // Replace with actual id
                user_instruction: userMessage,
                practice_set_question:
                  data?.paragraph + "\n\n\n\n" + selectedQuestion?.question,
                user_answer: selectedAnswerText,
                correct_answer: correctAnswerText,
                feedback: selectedQuestion?.feedback,
                // specific_section:selectedQuestion?.
              },
              module?.toLowerCase()
            )
          );
        } else {
          response = await dispatch(
            generateGuidenceOnFeedback(
              {
                exam_id: data?.attempt?.comprehension_id, // Replace with actual id
                user_question: userMessage,
                practice_set_question: selectedQuestion?.question,
                user_answer: selectedQuestion?.entered_answer,
                // specific_section:selectedQuestion?.
              },
              module?.toLowerCase()
            )
          );
        }
      }
      setpredefinedQuestions(response?.data?.potential_questions);
      setMessages((prev) => [
        ...prev,
        { type: "bot", text: response?.data || "No response generated" },
      ]);
      setLoad(false);
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        { type: "bot", text: "Error fetching response. Try again." },
      ]);
      setLoad(false);
    }

    // Reset the animation trigger flag after message is sent
    // setIsNewMessage(false);
  };

  const generateVoice = async (text) => {
    if (audioCache[text]) {
      // Play cached audio
      handlePlayAudio(audioCache[text], text);
    } else {
      setIsLoading(true); // Start loading
      try {
        const response = await dispatch(generateVoiceExplanation({ text }));
        const blob = response.data; // Assuming the API returns a Blob
        const audioUrl = URL.createObjectURL(blob);

        // Cache the generated audio
        setAudioCache((prev) => ({ ...prev, [text]: audioUrl }));

        // Play the newly generated audio
        handlePlayAudio(audioUrl, text);
      } catch (error) {
        console.error("Error generating voice:", error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    }
  };

  const handlePlayAudio = (audioUrl, text) => {
    if (currentAudio) {
      currentAudio.pause(); // Stop any currently playing audio
      currentAudio.currentTime = 0; // Reset current audio
    }

    const audio = new Audio(audioUrl);
    setCurrentAudio(audio);
    setPlayingText(text);

    audio.play();

    // Reset playing state when audio ends
    audio.onended = () => {
      setPlayingText(null);
      setCurrentAudio(null);
    };
  };

  const handleStopAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset the audio to the start
      setPlayingText(null);
      setCurrentAudio(null);
    }
  };

  const clearChat = () => {
    setMessages([]);
    setSelectedQuestion(null); // Reset selected question when clearing chat
    setDefaultQuestions();
  };

  const Message = ({ type, text }) => {
    const [previewText, setPreviewText] = useState(
      text?.split(" ")?.slice(0, 100)?.join(" ") +
        (text?.split(" ")?.length > 100 ? "..." : "")
    );
    const [textHeight, setTextHeight] = useState(0);
    const textRef = useRef(null);
    useEffect(() => {
      if (textRef.current) {
        setTextHeight(textRef.current.scrollHeight); // Get the actual content height
      }
    }, [previewText]);
    const isAudioGenerated = Boolean(audioCache[text]);

    return (
      <animated.div style={animationProps}>
        <Paper
          elevation={3}
          sx={{
            padding: "10px 15px",
            margin: "10px 0",
            maxWidth:
              module === "Essay" && type === "user"
                ? "fit-content"
                : "calc(700px - 89px) !important",
            alignSelf: type === "user" ? "end" : "start",
            backgroundColor: type === "user" ? "#fff0dc" : "#eef7fe",
            borderRadius:
              type === "user" ? "20px 20px 20px 0" : "0 20px 20px 20px",
          }}
        >
          {type === "user" ? (
            text
          ) : (
            <div
              ref={textRef}
              style={{
                overflow: "hidden",
                maxHeight:
                  type === "bot"
                    ? previewText === text
                      ? textHeight
                      : "300px"
                    : "none", // Apply dynamic height when expanded
                paddingBottom:
                  type === "bot"
                    ? previewText === text
                      ? "20px"
                      : "0"
                    : "none", // Smooth collapse padding
                transition: "max-height 0.7s ease, padding-bottom 0.7s ease", // Transition for both max-height and padding
              }}
            >
              <ReactMarkdown>{previewText}</ReactMarkdown>
            </div>
          )}
          <Box
            sx={{
              display: "flex",
              // justifyContent: "end",
              alignItems: "center",
            }}
          >
            {type === "bot" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                {!isAudioGenerated ? (
                  <Tooltip title="Read aloud" arrow>
                    <IconButton
                      sx={{
                        padding: "0 !important",
                        width: "20px",
                        mt: 2,
                      }}
                      onClick={() => generateVoice(text)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      ) : (
                        <HiSpeakerWave fill="#1d86ab" />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Read aloud" arrow>
                    <IconButton
                      sx={{
                        padding: "0 !important",
                        width: "18px",
                        mt: 2,
                      }}
                      onClick={() =>
                        playingText === text
                          ? handleStopAudio()
                          : handlePlayAudio(audioCache[text], text)
                      }
                      color="primary"
                      // sx={{ m: 2 }}
                    >
                      {playingText === text ? (
                        <FaRegCircleStop fill="#2090b7" />
                      ) : (
                        <FaRegCirclePlay fill="#2090a9" />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {text?.split(" ")?.length > 100 && (
                  <Box sx={{ textAlign: "end" }}>
                    <Button
                      size="small"
                      onClick={() => {
                        setPreviewText((prev) =>
                          prev === text
                            ? text?.split(" ")?.slice(0, 100)?.join(" ") +
                              (text?.split(" ")?.length > 100 ? "..." : "")
                            : text
                        );
                      }}
                      sx={{
                        textTransform: "none",
                        color: "#1d86ab",
                        fontWeight: "bold",
                        fontSize: "0.80rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          transform:
                            previewText === text
                              ? "rotate(0deg)"
                              : "rotate(180deg)", // Rotate the icon based on preview state
                          transition: "transform 0.3s ease", // Smooth icon rotation
                        }}
                      >
                        <FaChevronUp fill="#1d86ab" />
                      </span>
                      {previewText === text ? "Show Less" : "Show More"}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </animated.div>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        className="chat-drawer"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              padding: "16px",
              backgroundColor: "#2090b7",
              color: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">OpenEssayMe {module} Guide</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                size="small"
                variant="outlined"
                sx={{ color: "#fff", borderColor: "#fff" }}
                onClick={clearChat}
              >
                Clear Chat
              </Button>

              <IconButton onClick={() => setOpen(false)}>
                <RiCloseFill fill="white" />
              </IconButton>
            </Box>
          </Box>

          {/* Chat Messages */}
          <Box
            sx={{
              flexGrow: 1,
              padding: "16px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {messages?.map((msg, index) => (
              <Box sx={{ display: "flex", gap: "10px" }} key={index}>
                <Avatar
                  src={msg.type === "user" ? user?.profileImg : logo}
                  sx={{
                    backgroundColor: msg.type === "user" ? "none" : "#1c2536",
                    marginTop: "10px",
                  }}
                />
                <Message
                  type={msg.type}
                  text={msg.type === "user" ? msg.text : msg.text.text_response}
                />
              </Box>
            ))}
            {load && <ChatLoader />}
            {/* {module === "Essay" && ( */}
            <Box sx={{ mt: 3 }}>
              {messages?.length > 1 &&
                !load &&
                predefinedQuestions?.map((question, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      color: "#2090b7",
                      borderRadius: "15px",
                      fontWeight: 600,
                      borderColor: "#2090b7",
                      margin: "5px",
                      "&:hover": {
                        backgroundColor: "#2090b7",
                        color: "white",
                      },
                      textTransform: "none",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                    size="small"
                    onClick={() => handleSend(question)}
                  >
                    {question}
                  </Button>
                ))}
            </Box>
            {/* )} */}
          </Box>

          {/* Select Question */}

          <Box>
            {module === "Essay" ? (
              <>
                {/* Predefined Questions */}
                {messages?.length === 0 && (
                  <Box sx={{ padding: "16px", borderTop: "1px solid #ddd" }}>
                    <Typography variant="subtitle1">
                      Quick Questions:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                        marginTop: "8px",
                      }}
                    >
                      {predefinedQuestions?.map((question, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          sx={{
                            backgroundColor: "#2090b7",
                            "&:hover": { backgroundColor: "#2090b7" },
                            textTransform: "none",
                            fontSize: "14px",
                          }}
                          size="small"
                          onClick={() => handleSend(question)}
                        >
                          {question}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  padding: "16px",
                  borderTop: !load ? "1px solid #ddd" : "none",
                }}
              >
                {messages?.length === 0 && selectedQuestion && (
                  <Box sx={{}}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Quick Questions:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                        marginTop: "8px",
                      }}
                    >
                      {predefinedQuestions?.map((question, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          sx={{
                            backgroundColor: "#2090b7",
                            "&:hover": { backgroundColor: "#2090b7" },
                            textTransform: "none",
                            fontSize: "14px",
                          }}
                          size="small"
                          onClick={() => handleSend(question)}
                        >
                          {question}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                )}{" "}
                {!load && (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        mt: messages?.length === 0 ? 1 : 0,
                      }}
                    >
                      Select a Question:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                        marginTop: "8px",
                      }}
                    >
                      {data?.feedback?.map((item) => (
                        <Tooltip
                          key={item.id}
                          title={item.question}
                          placement="top"
                          arrow
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor:
                                selectedQuestion?.id === item.id
                                  ? "#003a74"
                                  : "#2090b7",
                              fontWeight:
                                selectedQuestion?.id === item.id ? 600 : 500,
                              "&:hover": { backgroundColor: "#2090b7" },
                            }}
                            size="small"
                            onClick={() => setSelectedQuestion(item)}
                          >
                            {item.id} {/* Display only the ID */}
                          </Button>
                        </Tooltip>
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
          {/* )} */}

          {/* Input Area */}
          {(module === "Essay" || selectedQuestion) && !load && (
            <Box
              sx={{
                padding: "16px",
                // borderTop: "1px solid #ddd",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                size="small"
                placeholder="Ask the Guide..."
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setanimationProps({
                      from: { opacity: 0, transform: "translateY(20px)" },
                      to: { opacity: 1, transform: "translateY(0px)" },
                      config: { tension: 200, friction: 20 },
                    });
                    handleSend();
                  }
                }}
              />
              <IconButton
                onClick={() => {
                  setanimationProps({
                    from: { opacity: 0, transform: "translateY(20px)" },
                    to: { opacity: 1, transform: "translateY(0px)" },
                    config: { tension: 200, friction: 20 },
                  });
                  handleSend();
                }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default ChatFeedbackPageDrawer;
