import { Drawer } from "@mui/material";
import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import getuserData from "../utils/userData";

const MenuOptions = [
  {
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    label: "Essay Practice",
    path: "/exams",
  },
  {
    label: "Grammar Rules",
    path: "/grammar",
  },
  {
    label: "Comprehension",
    path: "/comprehension",
  },
  {
    label: "Dictionary",
    path: "/dictionary",
  },
  {
    label: "OpenEssayMe Tutor",
    path: "/chatbot-support",
  },
  {
    label: "Users",
    path: "/users",
  },
  {
    label: "Settings",
    path: "/settings",
  },
  {
    label: "Available Plans",
    path: "/my-plan",
  },
];

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const isMenuActive = (itemPath) => {
    // Check if the current location pathname exactly matches the item path
    if (itemPath === "/") {
      return location.pathname === itemPath;
    }

    // For other menu items, check if the current location pathname starts with the item path
    return location.pathname.startsWith(itemPath);
  };

  const isAdmin = getuserData()?.isAdmin;

  const filteredMenuOptions = isAdmin
  ? MenuOptions.filter((item) => item.label !== "Dictionary") 
  : MenuOptions.filter((item) =>
      [
        "Dashboard",
        "Essay Practice",
        "Grammar Rules",
        "Comprehension",
        "Dictionary",
        "OpenEssayMe Tutor",
      ].includes(item.label)
    );


  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      className="sidebar-wrapper sidebar-menu-open"
      classes={{
        paper: "custom-paper-class",
      }}
      style={{ visibility: "unset", transform: "none" }}
    >
      <Box className="list-wrap">
        <List className="sidebar">
          {filteredMenuOptions.map((item, index) => (
            <ListItem
              key={index} // Add a unique key prop for each item
              disablePadding
              onClick={() => handleNavigate(item.path)}
              className={isMenuActive(item.path) ? "activemenu" : ""}
            >
              <ListItemButton>
                <ListItemText primary={item.label} className="menu-text-wrap" />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List className="sidebar">
          <ListItem
            style={{ height: "20px" }}
            onClick={() => {
              openInNewTab("/privacy-policy");
            }}
          >
            <ListItemButton>
              <ListItemText
                primary={"Privacy Policy"}
                className="menu-text-wrap s-14"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            style={{ height: "20px" }}
            onClick={() => {
              openInNewTab("/terms-of-service");
            }}
          >
            <ListItemButton>
              <ListItemText
                primary={"Terms & Conditions"}
                className="menu-text-wrap s-14"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default SideMenu;
