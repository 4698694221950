import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import { attemptTryAgainExam } from "../../../store/slices/exams/attemptExamSlice";
import { getToken } from "../../../utils/token";
import { setFeedbackReady } from "../../../store/slices/feedback/feedbackSlice";
import { Divider } from "rsuite";
import AddToDictionaryMenu from "../../../common/addToDictionaryMenu";
import ChatFeedbackPageDrawer from "../../../common/chatFeedbackPageDrawer";
import { AiOutlineWechatWork } from "react-icons/ai";
// import { Editor } from "reactjs-editor";

const ResponseFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const examDataById = useSelector((state) => state.examById.exam);
  const [examDataById, setExamDataById] = useState();
  // console.log("examDataById", examDataById);

  const userState = useSelector((state) => state.userById.user);
  // eslint-disable-next-line no-unused-vars
  const [feedbackDone, setFeedbackDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      question: "", // Set initial values from stored credentials
      response: "",
      feedback: "",
      marks: "",
    },
  });

  const fetchData = async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exam-attempt/view/${id}/`,
      });
      setExamDataById(response);
      if (response?.attempt?.feedback_status === "done") {
        setFeedbackDone(true);
        setIsLoading(false);
        dispatch(
          setFeedbackReady({
            url: `/exams/${response?.attempt?.id}/exam-feedback`,
            message: "Essay Exam Feedback is Generated",
          })
        );
      } else {
        setTimeout(fetchData, 3000); // Call fetchData again after 3 seconds
      }
    } catch (error) {
      // console.log(error);
      errorToast(error?.response?.data.errors);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.includes("Exam with given id does not exist")
      ) {
        navigate("*");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    // Safely parse feedback and handle errors
    let parsedFeedback = {};
    try {
      if (examDataById?.feedback) {
        parsedFeedback = JSON.parse(examDataById.feedback);
      }
    } catch (error) {
      console.error("Error parsing feedback JSON:", error.message);
    }

    // Update Formik values
    formik.setValues({
      question: examDataById?.attempt?.question || "",
      response: examDataById?.attempt?.response || "",
      feedback: parsedFeedback,
      marks: examDataById?.marks || "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examDataById]);

  const checkHasExamAccess = () => {
    if (userState?.essay_practice_left === 0) {
      navigate("/exams");
    }
  };

  const [selectedWord, setSelectedWord] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = useState(false);

  const [content, setContent] = useState(formik.values.question);
  const handleTextSelection = (e) => {
    const selection = window.getSelection().toString().trim();

    // Check if only one word is selected
    if (selection && selection.split(/\s+/).length === 1) {
      setSelectedWord(selection); // Store the selected text
      setShowMenu(true); // Show the menu

      const { clientX, clientY } = e;
      setMenuPosition({ x: clientX - 10, y: clientY + 10 }); // Position the menu
    } else {
      setShowMenu(false); // Hide the menu if no or multiple words are selected
    }
  };

  return (
    <>
      <Box className="content-header-title">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Button
            className="action-btn"
            onClick={() => {
              checkHasExamAccess();
              navigate(`/exams/${examDataById?.attempt?.exam_id}/view`);
            }}
            startIcon={<IoMdArrowRoundBack className="icon-font" />}
          ></Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h2" component="h2">
              View Practice Attempted&nbsp;(
              {moment(examDataById?.attempt?.created_at).format("MM-DD-YYYY")})
            </Typography>
            {formik.values.marks && (
              <Typography variant="h2" component="h2" mar>
                Marks: {formik.values.marks}
              </Typography>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {examDataById?.marks === 0 && (
                <Typography variant="h2" component="h2" mar>
                  Marks: 0
                </Typography>
              )}
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={() => {
                  checkHasExamAccess();
                  navigate(`/exams/${examDataById?.attempt?.exam_id}/attempt`);
                }}
              >
                <Typography variant="body1" component="p">
                  Try Again
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="content-layout">
        {!isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px 20px",
              borderBottom: "1px solid #e5e3e3bd",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Essay Score :{" "}
              <span style={{ color: "#2090b7" }}>
                {examDataById ? examDataById["Essay Score"] : "-"}
              </span>
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              OpenEssayMe Equivalent Percentage :{" "}
              <span style={{ color: "#2090b7" }}>
                {examDataById
                  ? examDataById["OpenEssayMe Equivalent Percentage"] + "%"
                  : "-"}
              </span>
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            maxHeight: "calc(100vh - 245px)",
            overflow: "auto",
            padding: "20px",
          }}
          onScroll={() => setShowMenu(false)}
        >
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    variant="body2"
                    component="span"
                    className="value"
                    style={{ fontWeight: "600" }}
                  >
                    Give Openessayme a moment to review, will provide you a
                    feedback shortly.....
                  </Typography>
                  <CircularProgress style={{ marginTop: "10px" }} />
                  <Typography
                    variant="body2"
                    component="span"
                    className="value"
                    style={{ fontWeight: "600" }}
                  >
                    You will be notified when the Exam feedback is generated
                    meanwhile you can practice with other modules.
                  </Typography>
                </Box>
              ) : (
                <Box
                  variant="div"
                  component="div"
                  // className="user-detail attempt-exam"
                >
                  <Grid container>
                    {/* Feedback */}
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          Feedback
                        </Typography>
                        <div
                          style={{
                            marginTop: "10px",
                          }}
                          onMouseUp={handleTextSelection}
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            className="value"
                            sx={{ marginTop: "10px !important" }}
                          >
                            {Object.entries(formik.values.feedback).map(
                              ([key, value], index) => {
                                if (
                                  key
                                    ?.toLowerCase()
                                    ?.includes("essay score".toLowerCase()) ||
                                  key?.toLowerCase() ===
                                    "openessayme equivalent percentage"
                                ) {
                                  // Skip rendering for these keys
                                  return null;
                                }

                                return (
                                  <div
                                    key={index}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <strong>{key} :</strong>
                                    {!key
                                      ?.toLowerCase()
                                      ?.includes("Essay Score".toLowerCase()) &&
                                    key?.toLowerCase() !==
                                      "openessayme equivalent percentage" ? (
                                      <br />
                                    ) : (
                                      " "
                                    )}

                                    {typeof value === "object" &&
                                    value !== null ? (
                                      Array.isArray(value) ? (
                                        value.join(", ")
                                      ) : (
                                        // Recursively render nested objects
                                        <div
                                          style={{
                                            marginLeft: "1em",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {Object.entries(value).map(
                                            (
                                              [nestedKey, nestedValue],
                                              nestedIndex
                                            ) => (
                                              <li
                                                key={nestedIndex}
                                                style={{
                                                  marginBottom: "5px",
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <strong
                                                  style={{
                                                    marginRight: "8px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {nestedKey}:
                                                </strong>
                                                {Array.isArray(nestedValue) ? (
                                                  nestedValue.join(", ")
                                                ) : (
                                                  <span
                                                    style={{
                                                      flex: 1,
                                                      wordBreak: "break-word",
                                                    }}
                                                  >
                                                    {typeof nestedValue ===
                                                      "object" &&
                                                    nestedValue !== null
                                                      ? JSON.stringify(
                                                          nestedValue
                                                        )
                                                      : nestedValue}
                                                  </span>
                                                )}
                                              </li>
                                            )
                                          )}
                                        </div>
                                      )
                                    ) : (
                                      value +
                                      (key ===
                                      "OpenEssayMe Equivalent Percentage"
                                        ? "%"
                                        : "")
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>

                    <Divider />
                    {/* Question */}
                    <Grid item xs={12}>
                      <Box variant="div" component="div">
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          Question
                        </Typography>
                        <div onMouseUp={handleTextSelection}>
                          <Typography
                            variant="body2"
                            component="span"
                            className="value"
                          >
                            <ReactMarkdown onMouseUp={handleTextSelection}>
                              {formik.values.question}
                            </ReactMarkdown>
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Divider />
                    {/* Response entered by you */}
                    <Grid item xs={12} md={12}>
                      <Box variant="div" component="div">
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          Response entered by you
                        </Typography>
                        <div onMouseUp={handleTextSelection}>
                          <Typography
                            variant="body2"
                            component="span"
                            className="value"
                            sx={{ marginTop: "10px" }}
                          >
                            <ReactMarkdown>
                              {formik.values.response}
                            </ReactMarkdown>
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {showMenu && selectedWord && (
                <AddToDictionaryMenu
                  selectedWord={selectedWord}
                  menuPosition={menuPosition}
                  setShowMenu={setShowMenu}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ position: "fixed", bottom: 50, right: 50 }}>
        <Tooltip title="OpenessayMe Essay Support" arrow placement="left">
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              backgroundColor: "#43acef",
              "&:hover": { backgroundColor: "#43acef" },
              boxShadow:
                "0px 5px 9px  rgba(1, 1, 1, 0.5), 0px 1px 3px rgba(0, 0, 0, 0.06)",
              animation: "bounce 2s infinite ease-in-out",
            }}
          >
            <AiOutlineWechatWork
              fill="white"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
              }}
            />
          </IconButton>

          <style>
            {`
    @keyframes bounce {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  `}
          </style>
        </Tooltip>
      </Box>
      <ChatFeedbackPageDrawer
        open={open}
        setOpen={setOpen}
        data={examDataById}
        module="Essay"
      />
    </>
  );
};
export default ResponseFeedback;
