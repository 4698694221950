import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { successToast } from "../../../response/successToast";
import { getToken } from "../../../utils/token";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup";
import { callApi } from "../../../api";
import { errorToast } from "../../../response/errorToast";
import ReactMarkdown from "react-markdown";
import { attemptExam } from "../../../store/slices/exams/attemptExamSlice";
import { capitalizeMessage } from "../../../utils/helper";
import { setSubscriptionFromApi } from "../../../store/slices/subscription/selectors";
import { GoAlertFill } from "react-icons/go";
import Loader from "../../../common/loader";
import { setLoading } from "../../../store/slices/loader/loaderSlice";
import { fetchUserById } from "../../../store/slices/users/userByIdSlice";
import { fetchDictionaryById } from "../../../store/slices/dictionary/getDictionarySlice";
import { attemptWord } from "../../../store/slices/dictionary/attemptWordSlice";

const validationSchema = Yup.object().shape({
  response: Yup.string().required("Please provide your response"),
});

const AttemptWord = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();
  const { isLoading } = useSelector((state) => state.loader);
  const word = useSelector((state) => state.dictionary.wordById);
 

  const [examDataById, setExamDataById] = useState();
  const [isAttemptLoading, setIsAttemptLoading] = useState(true);
  const { id } = useParams();
  const tryAgainData = location.state?.tryAgainData;
  const userState = useSelector((state) => state.userById.user);
  const [isSubscribed, setisSubscribed] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const subscription = useSelector(setSubscriptionFromApi);
  const listOfPlans = [
    "tutor_monthly",
    "intermediate_monthly",
    "basic_monthly",
    "tutor_six_monthly",
    "intermediate_six_monthly",
    "basic_six_monthly",
    "basic_yearly",
    "tutor_yearly",
    "intermediate_yearly",
  ];
  const hasValidPlan =
    subscription && listOfPlans?.some((plan) => subscription.includes(plan));
  const formik = useFormik({
    initialValues: {
      word_id: "",
      question: "",
      response: "",
      question_id: "",
    },
    validationSchema,
    onSubmit: async(values) => {
      const res = await dispatch(attemptWord(values))
      
      if(res?.success===true){
        navigate(`/dictionary/${res?.data?.id}/feedback`);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      word_id: word?.data?.id,
      question: word?.data?.practice_sets[0]?.value,
      response: tryAgainData?.try_again ? tryAgainData.previous_response : "",
      question_id: word?.data?.practice_sets[0]?.id,
    });
  }, [word, tryAgainData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return event.returnValue;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (word?.data?.practice_sets === null) {
      setTimeout(() => {
        dispatch(fetchDictionaryById(id));
      }, 5000);
    } else {
      setIsAttemptLoading(false);
    }
  }, []);

  useEffect(() => {
    // Initial fetch
    dispatch(fetchDictionaryById(id));
  }, []);



  useEffect(() => {
    // If no subscription, check comprehension practice
    if (!subscription) {
      if (userState?.essay_practice_left === 0) {
        setisSubscribed(false);

        return;
      } else {
        setisSubscribed(userState?.essay_practice_left !== 0);
        return;
      }
    }
    // Check if the subscription includes any valid plans
    const hasValidPlan = listOfPlans?.some((plan) =>
      subscription.includes(plan)
    );
    setisSubscribed(!!hasValidPlan);
  }, [subscription, userState]);

  useEffect(() => {
    const checkHasAccess = () => {
      if (subscription && hasValidPlan) {
        return;
      } else if (subscription && !hasValidPlan) {
        // navigate("/exams");
        setisSubscribed(false);
        return;
      } else if (!subscription && userState?.essay_practice_left !== 0) {
        return;
      } else if (!subscription && userState?.essay_practice_left === 0) {
        // navigate("/exams");
        setisSubscribed(false);
        return;
      }
    };

    checkHasAccess();

    if (token && userState?.id && !hasFetched) {
      dispatch(fetchUserById(userState.id, token));
      setHasFetched(true); // Mark as fetched
    }
  }, [
    userState?.id,
    userState?.essay_practice_left,
    token,
    navigate,
    dispatch,
    hasFetched,
  ]);

  return (
    <>
      {
        isLoading ? (
          <Loader />
        ) : (
          // isSubscribed ?
          <>
            <Box
              className="content-header-title"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Button
                  className="action-btn"
                  onClick={() => navigate(`/dictionary`)}
                >
                  <IoMdArrowRoundBack className="icon-font" />
                </Button>
                <Typography
                  variant="h2"
                  component="h2"
                  style={{ marginLeft: "8px" }}
                >
                  {tryAgainData?.try_again
                    ? "Retry the word "
                    : "Practice the word "}
                  "{capitalizeMessage(word?.data?.word)}"&nbsp;
                </Typography>
              </Box>
            </Box>
            <Box className="content-layout">
              <Box
                variant="div"
                component="div"
                className="createuser-wrap p-24"
              >
                {isAttemptLoading ? (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={formik.handleSubmit}>
                 
                        <Box
                          variant="div"
                          component="div"
                          className="user-detail attempt-exam"
                        >
                              <Box variant="div" component="div">
                                <Typography
                                  variant="body2"
                                  component="span"
                                  className="value"
                                >
                                  <ReactMarkdown>
                                    {formik.values.question}
                                  </ReactMarkdown>
                                </Typography>
                                {tryAgainData?.try_again && (
                                  <Box mb={2}>
                                    <Typography
                                      variant="body1"
                                      component="p"
                                      color="primary"
                                      style={{ marginBottom: "8px" }}
                                    >
                                      Previous Response:
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      style={{
                                        padding: "16px",
                                        backgroundColor: "#f5f5f5",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {tryAgainData.previous_response}
                                    </Typography>
                                  </Box>
                                )}
                                <TextField
                                  multiline
                                  rows={20}
                                  className="input-field"
                                  placeholder={
                                    tryAgainData?.try_again
                                      ? "Enter your improved response"
                                      : "Enter Response"
                                  }
                                  name="response"
                                  value={formik.values.response}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  sx={{ width: "100%" }}
                                  error={
                                    formik.touched.response &&
                                    Boolean(formik.errors.response)
                                  }
                                  helperText={
                                    formik.touched.response &&
                                    formik.errors.response ? (
                                      <span className="error-message">
                                        {formik.errors.response}
                                      </span>
                                    ) : null
                                  }
                                />
                              </Box>
                        </Box>
                    
                    <Box
                      variant="div"
                      component="div"
                      className="footer-btn"
                      style={{ marginTop: "16px",display:'flex' }}
                    >
                      <Box variant="div" component="div" className="btn-group">
                        <Button
                          variant="contained"
                          size="large"
                          className="primary-btn btn"
                          type="submit"
                          disableRipple
                        >
                          Submit{" "}
                          {tryAgainData?.try_again ? "Retry" : "Response"}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Box>
            </Box>
          </>
        )
        // :
        // (
        //   <Box className="content-layout" marginTop="32px">
        //     <Box
        //       variant="div"
        //       component="div"
        //       className="tab-wrapper user-listing"
        //     >
        //       <Box className="access-denied-found">
        //         <Box className="page-inner">
        //           <GoAlertFill fontSize="65px" style={{ marginBottom: "40px" }} />
        //           <Typography
        //             variant="h3"
        //             component="h3"
        //             style={{ textTransform: "initial" }}
        //           >
        //             <Box>
        //               You have No free Essay Exam Left. Please Upgrade to the
        //               atleast Basic plan.
        //             </Box>
        //           </Typography>
        //           <Typography variant="body1" component="p">
        //             <Box>
        //               Please subscribe to join our vibrant community of learners!
        //             </Box>
        //           </Typography>
        //           <Box style={{ height: "35px" }}>
        //             <Button
        //               variant="contained"
        //               size="large"
        //               className="primary-btn btn add-btn"
        //               disableRipple
        //               onClick={() => navigate(`/my-plan`)}
        //             >
        //               Upgrade Plan
        //             </Button>
        //           </Box>
        //         </Box>
        //       </Box>
        //     </Box>
        //   </Box>
        // )
      }
    </>
  );
};

export default AttemptWord;
