import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import Login from "../pages/auth/login";
import SignUp from "../pages/auth/signup";
import ForgetPassword from "../pages/auth/forgetpassword";
import ResetPassword from "../pages/auth/resetpassword";
import Dashboard from "../pages/dashboard";
import Layout from "./../layout/index";
import ChatLayout from "../chatlayout";
import ProtectedRoute from "./ProtectedRoute";
import SocialAuth from "../pages/socialAuth";
import ExamList from "../pages/exams/admin/index";
import AddExam from "../pages/exams/admin/addExams";
import EditExam from "../pages/exams/admin/editExams";
import PageNotFound from "../common/pagenotfound";
import ViewExam from "../pages/exams/admin/viewExamPage";
import UserList from "../pages/users";
import GrammarList from "../pages/grammar/admin";
import AddGrammar from "../pages/grammar/admin/addGrammar";
import EditGrammar from "../pages/grammar/admin/editGrammar";
import ViewGrammar from "../pages/grammar/admin/viewGrammar";
import Settings from "../pages/settings";
import UsersExamList from "../pages/exams/users";
import ViewUserExam from "../pages/exams/users/viewUserExam";
import UserGrammarList from "../pages/grammar/user";
import UserViewGrammar from "../pages/grammar/user/viewUserGrammar";
import AttemptExam from "../pages/exams/users/attemptExam";
import ResponseFeedback from "../pages/exams/users/feedback";
import AddInstructions from "../pages/exams/admin/addInstructions";
import AttemptGrammar from "../pages/grammar/user/attemptGrammar";
import GrammarResponseFeedback from "../pages/grammar/user/grammarFeedback";
import { setRoleFromApi } from "../store/slices/role/selectors";
import { useSelector } from "react-redux";
import ViewGramRuleQues from "../pages/grammar/admin/viewGramRuleQues";
import PublicRouteWrapper from "./PublicRoute";
import Chatbot from "../pages/chatbot/conversation";
import AiSupport from "../pages/chatbot";
import Subscription from "../pages/subscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import PaymentSuccess from "../pages/subscription/paymentSuccess";
import NoAccess from "../common/noAccessPage";
import Profile from "../pages/profile";
// import getuserData from "../utils/userData";
import PrivacyPolicy from "../pages/policy/privacyPolicy";
import TermsCondition from "../pages/policy/termsCondition";
import AdminAiSupport from "../pages/chatbot/adminIndex";
import HomePage from "../pages/home";
import FreeTrialPaymentSuccess from "../pages/subscription/freeTrialSuccess";
import ComprehensionList from "../pages/comprehension/admin";
import UserComprehensionList from "../pages/comprehension/user";
import AddComprehension from "../pages/comprehension/admin/addComprehension";
import DeleteComprehensionModal from "../pages/comprehension/admin/deleteComprehensionModal";
import EditComprehension from "../pages/comprehension/admin/editComprehension";
import ViewComprehension from "../pages/comprehension/admin/viewComprehension";
import ViewComprehensionRuleQues from "../pages/comprehension/admin/viewComprehensionQues";
import AttemptComprehension from "../pages/comprehension/user/attemptComprehension";
import ComprehensionResponseFeedback from "../pages/comprehension/user/comprehensionFeedback";
import UserViewComprehension from "../pages/comprehension/user/viewUserComprehension";
import ExamOverview from "../common/examOverview";
import UserDictionary from "../pages/dictionary/user";
import ViewWord from "../pages/dictionary/user/viewWord";
import AttemptWord from "../pages/dictionary/user/attemptWord";
import WordPracticeFeedback from "../pages/dictionary/user/feedback";

const Routes = () => {
  const isAdmin = useSelector(setRoleFromApi);
  // const userData = getuserData();
  // const isAdmin = userData?.isAdmin;
  const stripe_key = process.env.REACT_APP_STRIPE_KEY;
  const stripePromise = loadStripe(stripe_key);
  // console.log("User Data:", userData);
  // console.log("isAdmin:", isAdmin);

  return (
    <Elements stripe={stripePromise}>
      <ReactRoutes>
        <Route exact path="/google" element={<SocialAuth />} />
        <Route
          path="/login"
          element={
            <PublicRouteWrapper>
              <Login />
            </PublicRouteWrapper>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRouteWrapper>
              <SignUp />
            </PublicRouteWrapper>
          }
        />
        <Route
          path="/forget-password"
          element={
            <PublicRouteWrapper>
              <ForgetPassword />
            </PublicRouteWrapper>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRouteWrapper>
              <ResetPassword />
            </PublicRouteWrapper>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <PublicRouteWrapper>
              <HomePage />
            </PublicRouteWrapper>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout>
                <Profile />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams"
          element={
            <ProtectedRoute>
              {isAdmin === undefined ? (
                <div>Loading...</div>
              ) : isAdmin ? (
                <Layout>
                  <ExamList />
                </Layout>
              ) : (
                <Layout>
                  <UsersExamList />
                </Layout>
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams/create"
          element={
            <ProtectedRoute>
              <Layout>
                <AddExam />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams/:id/edit"
          element={
            <ProtectedRoute>
              {isAdmin ? (
                <Layout>
                  {" "}
                  <EditExam />
                </Layout>
              ) : (
                <PageNotFound />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams/:id/instructions"
          element={
            <ProtectedRoute>
              <Layout>
                <AddInstructions />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams/:id/view"
          element={
            <ProtectedRoute>
              <Layout>{isAdmin ? <ViewExam /> : <ViewUserExam />}</Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams/:id/attempt"
          element={
            <ProtectedRoute>
              <Layout>{isAdmin ? <NoAccess /> : <AttemptExam />}</Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams/:id/exam-feedback"
          element={
            <ProtectedRoute>
              <Layout>
                <ResponseFeedback />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <Layout>
                <UserList />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/grammar"
          element={
            <ProtectedRoute>
              <Layout>{isAdmin ? <GrammarList /> : <UserGrammarList />}</Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/grammar/create"
          element={
            <ProtectedRoute>
              <Layout>
                <AddGrammar />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/grammar/:id/edit"
          element={
            <ProtectedRoute>
              {isAdmin ? (
                <Layout>
                  {" "}
                  <EditGrammar />
                </Layout>
              ) : (
                <PageNotFound />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/grammar/:id/view"
          element={
            <ProtectedRoute>
              <Layout>{isAdmin ? <ViewGrammar /> : <UserViewGrammar />}</Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/grammar/:id/attempt"
          element={
            <ProtectedRoute>
              <Layout>
                <AttemptGrammar />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/grammar/:id/grammar-feedback"
          element={
            <ProtectedRoute>
              <Layout>
                <GrammarResponseFeedback />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/grammar/:id/practice-set"
          element={
            <ProtectedRoute>
              <Layout>
                <ViewGramRuleQues />
              </Layout>
            </ProtectedRoute>
          }
        />
        {/* Comprehension Route */}
        <Route
          path="/comprehension"
          element={
            <ProtectedRoute>
              <Layout>
                {isAdmin ? <ComprehensionList /> : <UserComprehensionList />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/comprehension/create"
          element={
            <ProtectedRoute>
              <Layout>
                <AddComprehension />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/comprehension/:id/edit"
          element={
            <ProtectedRoute>
              {isAdmin ? (
                <Layout>
                  {" "}
                  <EditComprehension />
                </Layout>
              ) : (
                <PageNotFound />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/comprehension/:id/view"
          element={
            <ProtectedRoute>
              <Layout>
                {isAdmin ? <ViewComprehension /> : <UserViewComprehension />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/comprehension/:id/attempt"
          element={
            <ProtectedRoute>
              <Layout>
                <AttemptComprehension />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/comprehension/:id/comprehension-feedback"
          element={
            <ProtectedRoute>
              <Layout>
                <ComprehensionResponseFeedback />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/comprehension/:id/practice-set"
          element={
            <ProtectedRoute>
              <Layout>
                <ViewComprehensionRuleQues />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dictionary"
          element={
            <ProtectedRoute>
              <Layout>
                {isAdmin ? <NoAccess /> : <UserDictionary />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dictionary/:id/view"
          element={
            <ProtectedRoute>
              <Layout>{isAdmin ? <NoAccess /> : <ViewWord />}</Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dictionary/:id/attempt"
          element={
            <ProtectedRoute>
              <Layout>{isAdmin ? <NoAccess /> : <AttemptWord />}</Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dictionary/:id/feedback"
          element={
            <ProtectedRoute>
              <Layout>
                {isAdmin ? <NoAccess /> : <WordPracticeFeedback />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Layout>
                <Settings />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/chatbot-support"
          element={
            <ProtectedRoute>
              <Layout>{isAdmin ? <AdminAiSupport /> : <AiSupport />}</Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/chatbot/my-conversation/:id"
          element={
            <ProtectedRoute>
              <ChatLayout>
                <Chatbot />
              </ChatLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-plan"
          element={
            <ProtectedRoute>
              <Layout>
                <Subscription />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-success"
          element={
            <ProtectedRoute>
              <Layout>
                <PaymentSuccess />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/free-trial-success"
          element={
            <ProtectedRoute>
              <Layout>
                <FreeTrialPaymentSuccess />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route path="/exam-overview/:id" element={<ExamOverview />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsCondition />} />
        <Route path="*" element={<PageNotFound key="not-found" />} />
      </ReactRoutes>
    </Elements>
  );
};

export default Routes;
