export function capitalizeMessage(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function formatString(str) {
  if (str == null) {
    // Check for null or undefined
    return "UNSUBSCRIBED";
  }
  return str?.replace(/_/g, " ")?.toUpperCase();
}

const planMapping = {
  tutor_monthly: "WritePro Premium Tutor Monthly",
  intermediate_monthly: "WritePlus Intermediate Monthly",
  basic_monthly: "WriteWay Basic Monthly",
  tutor_six_monthly: "WritePro Premium Tutor Half Yearly",
  intermediate_six_monthly: "WritePlus Intermediate Half Yearly",
  basic_six_monthly: "WriteWay Basic Half Yearly",
  tutor_yearly: "WritePro Premium Tutor Yearly",
  intermediate_yearly: "WritePro Premium Tutor Yearly",
  basic_yearly: "WriteWay Basic Yearly",
};
export const getPlanName = (subscription) => {
  if (subscription && planMapping[subscription]) {
    return planMapping[subscription];
  } else {
    return "No Active Plan";
  }
};

// convert \\n to \n
export const convertDoubleBackslashToNewline = (str) => {
  return str.replace(/\\n/g, "\n");
};

export const voices = [
  { "Name": "Joanna", "Gender": "Female", "Language": "US English" },
  { "Name": "Lupe", "Gender": "Female", "Language": "US Spanish" },
  { "Name": "Filiz", "Gender": "Female", "Language": "Turkish" },
  { "Name": "Astrid", "Gender": "Female", "Language": "Swedish" },
  { "Name": "Tatyana", "Gender": "Female", "Language": "Russian" },
  { "Name": "Maxim", "Gender": "Male", "Language": "Russian" },
  { "Name": "Carmen", "Gender": "Female", "Language": "Romanian" },
  { "Name": "Inês", "Gender": "Female", "Language": "Portuguese" },
  { "Name": "Cristiano", "Gender": "Male", "Language": "Portuguese" },
  { "Name": "Vitória", "Gender": "Female", "Language": "Brazilian Portuguese" },
  { "Name": "Ricardo", "Gender": "Male", "Language": "Brazilian Portuguese" },
  { "Name": "Camila", "Gender": "Female", "Language": "Brazilian Portuguese" },
  { "Name": "Maja", "Gender": "Female", "Language": "Polish" },
  { "Name": "Jan", "Gender": "Male", "Language": "Polish" },
  { "Name": "Jacek", "Gender": "Male", "Language": "Polish" },
  { "Name": "Ewa", "Gender": "Female", "Language": "Polish" },
  { "Name": "Ruben", "Gender": "Male", "Language": "Dutch" },
  { "Name": "Lotte", "Gender": "Female", "Language": "Dutch" },
  { "Name": "Liv", "Gender": "Female", "Language": "Norwegian" },
  { "Name": "Seoyeon", "Gender": "Female", "Language": "Korean" },
  { "Name": "Takumi", "Gender": "Male", "Language": "Japanese" },
  { "Name": "Mizuki", "Gender": "Female", "Language": "Japanese" },
  { "Name": "Bianca", "Gender": "Female", "Language": "Italian" },
  { "Name": "Giorgio", "Gender": "Male", "Language": "Italian" },
  { "Name": "Carla", "Gender": "Female", "Language": "Italian" },
  { "Name": "Karl", "Gender": "Male", "Language": "Icelandic" },
  { "Name": "Dóra", "Gender": "Female", "Language": "Icelandic" },
  { "Name": "Mathieu", "Gender": "Male", "Language": "French" },
  { "Name": "Léa", "Gender": "Female", "Language": "French" },
  { "Name": "Céline", "Gender": "Female", "Language": "French" },
  { "Name": "Chantal", "Gender": "Female", "Language": "Canadian French" },
  { "Name": "Penélope", "Gender": "Female", "Language": "US Spanish" },
  { "Name": "Miguel", "Gender": "Male", "Language": "US Spanish" },
  { "Name": "Mia", "Gender": "Female", "Language": "Mexican Spanish" },
  { "Name": "Lucia", "Gender": "Female", "Language": "Castilian Spanish" },
  { "Name": "Enrique", "Gender": "Male", "Language": "Castilian Spanish" },
  { "Name": "Conchita", "Gender": "Female", "Language": "Castilian Spanish" },
  { "Name": "Geraint", "Gender": "Male", "Language": "Welsh English" },
  { "Name": "Salli", "Gender": "Female", "Language": "US English" },
  { "Name": "Matthew", "Gender": "Male", "Language": "US English" },
  { "Name": "Kimberly", "Gender": "Female", "Language": "US English" },
  { "Name": "Kendra", "Gender": "Female", "Language": "US English" },
  { "Name": "Justin", "Gender": "Male", "Language": "US English" },
  { "Name": "Joey", "Gender": "Male", "Language": "US English" },
  { "Name": "Ivy", "Gender": "Female", "Language": "US English" },
  { "Name": "Raveena", "Gender": "Female", "Language": "Indian English" },
  { "Name": "Aditi", "Gender": "Female", "Language": "Indian English" },
  { "Name": "Emma", "Gender": "Female", "Language": "British English" },
  { "Name": "Brian", "Gender": "Male", "Language": "British English" },
  { "Name": "Amy", "Gender": "Female", "Language": "British English" },
  { "Name": "Russell", "Gender": "Male", "Language": "Australian English" },
  { "Name": "Nicole", "Gender": "Female", "Language": "Australian English" },
  { "Name": "Vicki", "Gender": "Female", "Language": "German" },
  { "Name": "Marlene", "Gender": "Female", "Language": "German" },
  { "Name": "Hans", "Gender": "Male", "Language": "German" },
  { "Name": "Naja", "Gender": "Female", "Language": "Danish" },
  { "Name": "Mads", "Gender": "Male", "Language": "Danish" },
  { "Name": "Gwyneth", "Gender": "Female", "Language": "Welsh" },
  { "Name": "Zhiyu", "Gender": "Female", "Language": "Chinese Mandarin" },
  { "Name": "Zeina", "Gender": "Female", "Language": "Arabic" }
]
