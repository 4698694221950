import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Box, Divider, Typography } from "@mui/material";
import { addToDictionary } from "../store/slices/dictionary/addToDictionarySlice";
import { useDispatch } from "react-redux";

function AddToDictionaryMenu({
  selectedWord,
  menuPosition,
  setShowMenu,
  content,
  setContent,
}) {
  const dispatch = useDispatch();

  const isSingleWord = !selectedWord.includes(" ");
  const handleAddToDictionary = () => {
    if (selectedWord) {
      dispatch(addToDictionary(selectedWord));
    }
    setShowMenu(false); // Hide the menu after adding
  };

  const handleHighlightWord = () => {
    if (selectedWord) {
      // Escape special regex characters for safety
      const escapedWord = selectedWord.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  
      // Replace occurrences of the selected word with highlighted versions
      const highlightedContent = content.replace(
        new RegExp(`(${escapedWord})`, "gi"),
        `<span style="background-color: yellow;">$1</span>` // Add highlight span
      );
  
      setContent(highlightedContent); // Update the state with new content
      setShowMenu(false); // Hide the menu after highlighting
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowMenu(false); // Hide menu when page is scrolled
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up on unmount
    };
  }, [setShowMenu]);

  return (
    <>
      {/* <Box
        sx={{
          position: "absolute",
          top: menuPosition.y,
          left: menuPosition.x,
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: 6,
          zIndex: 999,
          padding: "2px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "270px",
        }}
      >
        {isSingleWord && (
          <>
            <Typography
              variant="body2"
              sx={{
                padding: "7px 10px",
                cursor: "pointer",
                color: "#333",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
                width: "100%",
              }}
              onClick={handleAddToDictionary}
            >
              Add "{selectedWord}" to Dictionary
            </Typography>
            <Divider sx={{ width: "100%" }} />
          </>
        )}
        <Typography
          variant="body2"
          sx={{
            padding: "7px 10px",
            cursor: "pointer",
            color: "#333",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
            width: "100%",
          }}
          onClick={handleHighlightWord}
        >
          Highlight the Text
        </Typography>
      </Box> */}
      <Box
        sx={{
          position: "absolute",
          top: menuPosition.y + 10, // Small offset for better positioning
          left: menuPosition.x,
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: 3,
          zIndex: 999,
          padding: "5px 10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginBottom: "10px",
            color: "#333",
            fontWeight: "bold",
          }}
        >
          Add "{selectedWord}" to Dictionary
        </Typography>
        <Button
          onClick={handleAddToDictionary}
          sx={{
            padding: "0px 5px",
            borderRadius: "4px",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            backgroundColor: "#43acef",
            color: "white",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#5bb6f0",
            },
          }}
        >
          Add
        </Button>
      </Box>
    </>
  );
}

export default AddToDictionaryMenu;
